import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Row, Col } from 'react-bootstrap'
import injectSheet from 'react-jss'
import routes from 'src/routing/routes'
import { categoriesOptions, categoriesLinkValues } from 'src/config/dataConf'
import { ClickioResetConsent } from '../../config/dataConf'

const styles = theme => ({
  grid: {
    backgroundColor: theme.footer.backgroundColor,
    borderTop: theme.footer.borderTop,
    minHeight: 230
  },
  texte: {
    fontWeight: theme.extraBold,
    color: theme.footer.texte,
    fontSize: theme.tailleMoyenTexte,
    margin: '15px 0 10px'
  },
  liensVersVille: {
    color: theme.footer.texte,
    '& a': {
      color: theme.footer.texte,
      fontWeight: theme.regular,
      fontSize: theme.tailleMoyenTexte,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  colTexte: {
    textAlign: 'center'
  },
  colLiens: {
    color: theme.footer.texte,
    textAlign: 'center',
    '& a': {
      color: theme.footer.texte,
      fontWeight: theme.regular,
      fontSize: theme.tailleMoyenTexte,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  divider: {
    '&:before': {
      content: "'-'"
    }
  },
  taupe: {
    textAlign: 'center'
  },
  credits: {
    textAlign: 'center',
    marginTop: '20px',
    '& p': {
      color: theme.footer.texte,
      fontWeight: theme.regular,
      fontSize: theme.tailleMoyenTexte
    }
  },
  site: {
    color: 'white',
    fontWeight: theme.bold,
    '&:hover, &:focus': {
      textDecoration: 'underline',
      color: 'white'
    }
  }
})

const Footer = ({ classes, cityNear }) => (
  <Grid fluid className={classes.grid}>
    {cityNear && (
      <Fragment>
        <Row>
          <Col xs={12} className={classes.colTexte}>
            <div className={classes.texte}>
              LES PRODUCTEURS PROCHES DE CHEZ VOUS
            </div>
          </Col>
        </Row>
        <Row className={classes.liensVersVille}>
          <Col xs={12} smOffset={2} sm={4}>
            <div>
              <Link
                to={routes.mapVille
                  .replace(':category', 'all')
                  .replace(':idVille', cityNear.id)}
              >
                Producteurs locaux proches de {cityNear.name}
              </Link>
            </div>
            {categoriesLinkValues
              .filter((c, i) => i < categoriesLinkValues.length / 2)
              .map((category, i) => (
                <div key={i}>
                  <Link
                    to={routes.mapVille
                      .replace(':category', category.urlValue)
                      .replace(':idVille', cityNear.id)}
                  >
                    Producteurs de {category.value.toLowerCase()} proches de{' '}
                    {cityNear.name}
                  </Link>
                </div>
              ))}
          </Col>
          <Col xs={12} sm={4}>
            {categoriesLinkValues
              .filter((c, i) => i >= categoriesLinkValues.length / 2)
              .map((category, i) => (
                <div key={i}>
                  <Link
                    to={routes.mapVille
                      .replace(':category', category.urlValue)
                      .replace(':idVille', cityNear.id)}
                  >
                    Producteurs de {category.value.toLowerCase()} proches de{' '}
                    {cityNear.name}
                  </Link>
                </div>
              ))}
          </Col>
        </Row>
      </Fragment>
    )}
    <Row>
      <Col xs={12} className={classes.colTexte}>
        <div className={classes.texte}>ET POUR CE QUI NE SE MANGE PAS...</div>
      </Col>
    </Row>
    <Row>
      <Col xs={12} className={classes.colLiens}>
        <ul className='list-inline'>
          <li>
            <Link to={routes.cgu}>CGU</Link>
          </li>
          <li className={classes.divider} />
          <li>
            <Link to={routes.mentionsLegales}>Mention légales</Link>
          </li>
          <li className={classes.divider} />
          <li>
            <Link to={routes.about}>À propos</Link>
          </li>
          <li className={classes.divider} />
          <li>
            <Link to={routes.faq}>FAQ</Link>
          </li>
          <li className={classes.divider} />
          <li>
            <Link to={routes.contact}>Contacter Acheter à la Source</Link>
          </li>
          <li className={classes.divider} />
          <li>
            <Link
              to={routes.siteMap.replace(
                ':category',
                categoriesOptions[0].value
              )}
            >
              Producteurs par régions
            </Link>
          </li>
          <li className={classes.divider} />
          <li>
            <Link to={routes.annuaireVilles}>Producteurs par villes</Link>
          </li>
        </ul>
      </Col>
    </Row>

    <div className={classes.colLiens}>
      <ClickioResetConsent />
    </div>

    <Row>
      <Col xs={12} className={classes.taupe}>
        <img src='/img/taupe.png' alt='taupe' />
      </Col>
    </Row>
    <Row>
      <Col xs={12} className={classes.credits}>
        <p>
          Sur une idée originale de{' '}
          <a
            href='https://www.webalternatif.com/'
            target='_blank'
            className={classes.site}
          >
            Web|Alternatif
          </a>{' '}
          et avec le design de{' '}
          <a
            href='http://www.kitchenette-graphisme.com/'
            target='_blank'
            className={classes.site}
          >
            Kitchenette
          </a>
        </p>
      </Col>
    </Row>
  </Grid>
)

export default injectSheet(styles)(Footer)
