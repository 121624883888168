import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import routes from '../../../routing/routes'
import FormInscription from './scenes/FormInscription'
import Inscription from './Inscription'
import { withCookies } from 'react-cookie'
import ErrorPage from '../ErrorPage/ErrorPage'

class InscriptionRouteur extends Component {
  render() {
    const { cookies } = this.props

    return cookies.get('token') ? (
      <Redirect to={routes.accueil} />
    ) : (
      <Switch>
        <Route
          exact
          path={routes.formInscription}
          component={FormInscription}
        />
        <Route exact path={routes.inscription} render={() => <Inscription />} />
        <Route component={ErrorPage} />
      </Switch>
    )
  }
}

export default withCookies(InscriptionRouteur)
