import React from 'react'

const CompteInactif = ({ taille, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='-1448.339 2876.516 14.339 20.484'
  >
    <g transform='translate(-1743 2842.5)'>
      <path
        fill='#fff'
        d='M14.339,26H0V24.57A7.169,7.169,0,0,1,7.17,17.4h0a7.169,7.169,0,0,1,7.17,7.17Z'
        transform='translate(294.661 28.497)'
      />
      <circle
        fill='#fff'
        cx='5.326'
        cy='5.326'
        r='5.326'
        transform='translate(296.505 34.016)'
      />
    </g>
  </svg>
)

export default CompteInactif
