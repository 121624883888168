import React, { Component } from 'react'
import Contact from './Contact'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

const mutation = gql`
  mutation($threadInput: NewThreadInput) {
    contactDev(newThreadInput: $threadInput) {
      error
    }
  }
`

class ContactContainer extends Component {
  sendMessage = values => {
    const { message, civility, ...otherValues } = values
    const threadInput = {
      ...otherValues,
      htmlContent: message,
      textContent: message,
      civility: civility && civility.value
    }

    return this.props
      .mutate({
        variables: {
          threadInput
        }
      })
      .then(({ data: { contactDev } }) => {
        if (contactDev.error) {
          return "Erreur lors de l'envoi du message."
        } else {
          return null
        }
      })
  }

  render() {
    return <Contact send={this.sendMessage} />
  }
}

export default graphql(mutation)(ContactContainer)
