/// Pages front office ///
import { API_ENDPOINT, URL_TILES } from '../config/env'

const accueil = '/'
const cgu = '/conditions-generales-utilisation'
const mentionsLegales = '/mentions-legales'
const about = '/a-propos'
const faq = '/foire-aux-questions'
const contact = '/contact'
const ficheProducteur = '/producteur/:id(\\d+)'
const ficheProducteurWithName = '/producteur/:nomProducteur'
const ficheProduit = '/produit/:idProduct(\\d+)'
const recherche = '/recherche'

// inscription
const inscription = '/inscription'
const formInscription = inscription + '/formulaire'

// Sécurité et redirection
const error = '/error/:number'
const connexion = '/connexion'

// Site mapping
const siteMap = '/producteurs-en-france/:category'

const mapRegion = siteMap + '/region/:inseeRegion/page/:page'
const mapDepartment = siteMap + '/departement/:inseeDepartment/page/:page'
const mapVille = siteMap + '/ville/:idVille'

const mapProduit = '/produits-en-france/:category'

/// Pages back office ///

const PREFIX_BACK_OFFICE = '/mon-compte/'

const account = PREFIX_BACK_OFFICE + ':tab'
const dashboard = PREFIX_BACK_OFFICE + 'tableau-de-bord'
const myCompany = PREFIX_BACK_OFFICE + 'mon-entreprise'

// Mot de passe oublié
const forgottenPassword = '/mot-de-passe-oublie'
const newPassword = forgottenPassword + '/:id/:token'

// Produits
const myProducts = PREFIX_BACK_OFFICE + 'mes-produits'
const addProduct = myProducts + '/ajouter-produit'

// Mon profil
const myProfile = PREFIX_BACK_OFFICE + 'mon-profil'
const changePassword = myProfile + '/mot-de-passe'

// Messagerie
const myMessages = PREFIX_BACK_OFFICE + 'mes-messages'
const thread = myMessages + '/:idThread'
const threadResponse = thread + '/repondre'

// Annuaire
const annuaire = '/annuaire'
const annuaireVilles = annuaire + '-villes'

// Administration
const admin = PREFIX_BACK_OFFICE + 'admin'
const validationProducteurs = admin + '/validation-producteurs'
const ficheValidationProducteurs = validationProducteurs + '/:id'
const messagerieAdmin = admin + '/messagerie-administrateur'
const threadAdmin = messagerieAdmin + '/:idThread'
const threadAdminResponse = threadAdmin + '/repondre'

// APIs externes
const apiAddress = 'https://api-adresse.data.gouv.fr/search/?q='
const urlTiles = URL_TILES
const pieceJointe = API_ENDPOINT + '/piece-jointe/'

export default {
  accueil,
  cgu,
  about,
  faq,
  contact,
  inscription,
  recherche,
  error,
  connexion,
  thread,
  mentionsLegales,
  annuaire,
  annuaireVilles,
  ficheProducteur,
  ficheProducteurWithName,
  ficheProduit,
  formInscription,
  dashboard,
  myCompany,
  myProducts,
  addProduct,
  myProfile,
  myMessages,
  admin,
  threadResponse,
  apiAddress,
  urlTiles,
  PREFIX_BACK_OFFICE,
  account,
  changePassword,
  forgottenPassword,
  newPassword,
  validationProducteurs,
  ficheValidationProducteurs,
  messagerieAdmin,
  threadAdmin,
  threadAdminResponse,
  siteMap,
  mapRegion,
  mapDepartment,
  mapVille,
  mapProduit,
  pieceJointe
}
