import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  }
}

const Fruits = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-3563.851 1075.543 97.745 97.745'
  >
    <g transform='translate(-3914.5 1075.543)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(351.149 0.5)'
      />
      <g transform='translate(377.091 15.478)'>
        <path
          fill='#a5b200'
          d='M545.264,41.537S538.958,28.566,534.3,23.693c6.665,4.586,10.391,7.453,14.118,12.971-.287-4.3,2.006-8.671,4.3-15.264,1.147,8.026.573,12.111,2.867,14.978,1.433-2.867,3.153-4.013,8.958-9.818-3.44,8.313-6.593,13.258-4.3,19.564S545.264,41.537,545.264,41.537Z'
          transform='translate(-528.495 -21.4)'
        />
        <path
          fill='#ef2022'
          d='M571.061,60c0,12.4-10.033,35.975-22.43,35.975S526.2,72.4,526.2,60c0-17.987,10.033-15.551,22.431-15.551S571.061,41.945,571.061,60Z'
          transform='translate(-526.2 -27.899)'
        />
        <circle
          fill='#fff'
          cx='1.433'
          cy='1.433'
          r='1.433'
          transform='translate(8.743 25.297)'
        />
        <circle
          fill='#fff'
          cx='1.433'
          cy='1.433'
          r='1.433'
          transform='translate(18.776 38.268)'
        />
        <circle
          fill='#fff'
          cx='1.433'
          cy='1.433'
          r='1.433'
          transform='translate(36.046 25.297)'
        />
        <circle
          fill='#fff'
          cx='1.433'
          cy='1.433'
          r='1.433'
          transform='translate(23.434 26.157)'
        />
        <circle
          fill='#fff'
          cx='1.433'
          cy='1.433'
          r='1.433'
          transform='matrix(0.598, -0.802, 0.802, 0.598, 28.912, 52.658)'
        />
        <circle
          fill='#fff'
          cx='1.433'
          cy='1.433'
          r='1.433'
          transform='translate(30.313 37.981)'
        />
        <circle
          fill='#fff'
          cx='1.433'
          cy='1.433'
          r='1.433'
          transform='translate(22.86 59.552)'
        />
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(Fruits)
