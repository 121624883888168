import { useEffect } from 'react'

const useScript = (
  url,
  ref,
  async,
  children,
  className,
  type = 'text/javascript'
) => {
  useEffect(() => {
    const script = document.createElement('script')

    if (url) {
      script.src = url
    }

    script.async = async || false
    script.type = type

    if (className) {
      script.className = className
    }

    if (children) {
      script.innerHTML = children
    }

    if (ref) {
      ref.current.appendChild(script)
    }
    return () => {
      if (ref) {
        ref.current.removeChild(script)
      }
    }
  }, [url, children, ref, async])
}

export default useScript
