import React, { Component, Fragment } from 'react'
import injectSheet from 'react-jss'
import TopBar from '../../../components/top_bar/TopBar'
import Titre1 from '../../../components/font/Titre1'
import Titre2 from '../../../components/font/Titre2'
import P from '../../../components/font/P'
import { Col, Grid, Row } from 'react-bootstrap'
import ButtonLink from '../../../components/button/ButtonLink'
import routes from '../../../routing/routes'
import ButtonStyle from '../../../components/button/ButtonStyle'
import Footer from '../../../components/footer/Footer'
import gql from 'graphql-tag'
import { withRouter } from 'react-router'
import { withApollo } from 'react-apollo'
import { Field, Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { colors } from '../../../config/themes'
import { withCookies } from 'react-cookie'
import { Helmet } from 'react-helmet'
import { NODE_ENV } from '../../../config/env'

const styles = theme => ({
  wrapper: {
    minHeight: theme.minHeight
  },
  buttonInscription: {
    textAlign: 'center',
    marginTop: 30,
    marginBottom: 15
  },
  space: {
    marginBottom: 10
  },
  dejaInscrit: {
    marginTop: 15
  },
  divButton: {
    margin: '15px 0 30px',
    textAlign: 'center'
  },
  input: {
    width: '100%',
    padding: 8,
    border: theme.borderInput
  },
  error: {
    color: 'red'
  },
  disabled: {
    backgroundColor: colors.gris,
    borderColor: colors.gris,
    '&:hover': {
      borderColor: colors.gris
    }
  }
})

class Inscription extends Component {
  handleSubmit = values => {
    return this.props.client
      .query({
        query: gql`
      { 
        login(mail: "${values.email}", password: "${values.password}") {
          token
          error
        } 
          
      }`
      })
      .then(data => {
        if (!data.data.login.error) {
          const date = new Date()
          date.setDate(date.getDate() + 365)
          this.props.cookies.set('token', data.data.login.token, {
            path: '/',
            expires: date,
            secure: NODE_ENV !== 'dev',
            sameSite: 'lax'
          })
          this.props.history.push(routes.accueil)
          return null
        }

        return { [FORM_ERROR]: data.data.login.error }
      })
  }

  render() {
    const { classes } = this.props
    return (
      <Fragment>
        <Helmet>
          <title>
            Producteurs : vendez vos produits en direct au consommateur
          </title>
        </Helmet>
        <div className={classes.wrapper}>
          <TopBar />
          <Grid fluid>
            <Row>
              <Col xs={12} smOffset={3} sm={6}>
                <Titre1>POURQUOI S'INSCRIRE ?</Titre1>
                <Titre2>Augmentez votre visibilité sur internet</Titre2>
                <P>
                  En réponse au contrôle de la grande distribution sur les prix,
                  la vente directe connaît un essor sans précédent.
                  Parallèlement, les consommateurs recherchent de plus en plus
                  les produits et les producteurs à travers Internet.
                </P>

                <Titre2>Une vitrine gratuite pour votre entreprise</Titre2>
                <P>
                  Présentez les produits de votre entreprise de manière
                  totalement gratuite sur votre page producteur. Celle-ci sera
                  visible par tous les internautes qui utilisent
                  acheteralasource.
                </P>

                <Titre2>Recevez les commandes des internautes</Titre2>
                <P>
                  acheteralasource offre aux producteurs une interface de
                  gestion simple et intuitive pour recevoir les demandes
                  d’informations des internautes.
                </P>
                <div className={classes.buttonInscription}>
                  <ButtonLink to={routes.formInscription}>
                    Demande d'inscription
                  </ButtonLink>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} smOffset={3} sm={6}>
                <Titre1 className={classes.dejaInscrit} component='p'>
                  DÉJÀ INSCRIT ?
                </Titre1>
                <Form
                  onSubmit={this.handleSubmit}
                  render={({ handleSubmit, submitting, submitError }) => (
                    <form onSubmit={handleSubmit}>
                      <Field name='email'>
                        {({ input, meta }) => (
                          <div className={classes.space}>
                            <input
                              className={classes.input}
                              type='email'
                              {...input}
                              placeholder='Email'
                              required
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>

                      <Field name='password'>
                        {({ input, meta }) => (
                          <div className={classes.space}>
                            <input
                              className={classes.input}
                              type='password'
                              {...input}
                              placeholder='Mot de passe'
                              required
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>

                      {submitError && (
                        <p className={classes.error}>{submitError}</p>
                      )}

                      <div className={classes.divButton}>
                        {submitting ? (
                          <ButtonStyle
                            className={classes.disabled}
                            disabled={submitting}
                            type='submit'
                          >
                            Connexion
                          </ButtonStyle>
                        ) : (
                          <ButtonStyle type='submit'>Connexion</ButtonStyle>
                        )}
                      </div>
                    </form>
                  )}
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <Footer />
      </Fragment>
    )
  }
}

const styleSheet = injectSheet(styles)(Inscription)
const withApo = withApollo(styleSheet)
const withRout = withRouter(withApo)

export default withCookies(withRout)
