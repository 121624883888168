import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import withStyles from 'react-jss'

const styles = {
  modal: {
    '& .modal-content': {
      borderRadius: 0
    }
  },
  send: {
    marginRight: 15
  }
}

class ModalConfirmation extends Component {
  sendMessage = () => {
    const { onHide, send } = this.props
    onHide()
    send()
  }

  render() {
    const { show, onHide, classes } = this.props

    return (
      <Modal show={show} onHide={onHide} className={classes.modal}>
        <Modal.Header closeButton>
          <Modal.Title className={classes.title}>
            Confirmation d'envoi de message
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Ce formulaire de contact sert à contacter{' '}
            <strong>les développeurs</strong> d'Acheter à la Source et{' '}
            <strong>non les producteurs d'une page</strong>. Souhaitez vous tout
            de même envoyer votre message ?
          </p>
          <div>
            <Button className={classes.send} onClick={this.sendMessage}>
              Envoyer
            </Button>
            <Button onClick={onHide} bsStyle='danger'>
              Annuler
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default withStyles(styles)(ModalConfirmation)
