import React from 'react'

const MapIcon = ({ taille, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    x='0px'
    y='0px'
    viewBox='0 0 42 42'
    style={{ enableBackground: 'new 0 0 42 42' }}
  >
    <g transform='translate(-1775 2178)'>
      <circle fill='#ABE0E0' cx='1796' cy='-2157' r='21' />
      <g transform='translate(324 616)'>
        <g>
          <path
            fill='#FFFFFF'
            d='M1467-2765.1l-8.8,3.6v-20.6l8.8-3.6l8.9,3.6l8.9-3.6v20.6l-8.9,3.6L1467-2765.1z'
          />
          <path
            fill='#95989A'
            d='M1467-2785.5l8.8,3.5l0.1,0.1l0.2-0.1l8.5-3.5v20.2l-8.7,3.5l-8.8-3.6l-0.1,0.1l-0.1,0.1
          l-8.5,3.5v-20.3L1467-2785.5 M1485-2786l-9.1,3.7l-8.9-3.6l-9,3.7v21l9-3.7l8.9,3.6l9.1-3.7V-2786z'
          />
        </g>
        <g>
          <line fill='none' x1='1467' y1='-2785.6' x2='1467' y2='-2765.3' />
          <g>
            <line
              fill='none'
              stroke='#95989A'
              strokeWidth='0.75'
              strokeMiterlimit='10'
              x1='1467'
              y1='-2785.6'
              x2='1467'
              y2='-2785.1'
            />
            <path
              fill='#95989A'
              d='M1467.4-2766.8h-0.8v-1h0.8V-2766.8z M1467.4-2768.9h-0.8v-1h0.8V-2768.9z M1467.4-2770.9h-0.8v-1h0.8
            V-2770.9z M1467.4-2772.9h-0.8v-1h0.8V-2772.9z M1467.4-2774.9h-0.8v-1h0.8V-2774.9z M1467.4-2777h-0.8v-1h0.8V-2777z
             M1467.4-2779h-0.8v-1h0.8V-2779z M1467.4-2781h-0.8v-1h0.8V-2781z M1467.4-2783h-0.8v-1h0.8V-2783z'
            />
            <line
              fill='none'
              stroke='#95989A'
              strokeWidth='0.75'
              strokeMiterlimit='10'
              x1='1467'
              y1='-2765.8'
              x2='1467'
              y2='-2765.3'
            />
          </g>
        </g>
        <g>
          <line fill='none' x1='1475.9' y1='-2781.9' x2='1475.9' y2='-2761.6' />
          <g>
            <line
              fill='none'
              stroke='#95989A'
              strokeWidth='0.75'
              strokeMiterlimit='10'
              x1='1475.9'
              y1='-2781.9'
              x2='1475.9'
              y2='-2781.4'
            />
            <path
              fill='#95989A'
              d='M1476.3-2763.2h-0.8v-1h0.8V-2763.2z M1476.3-2765.3h-0.8v-1h0.8V-2765.3z M1476.3-2767.3h-0.8v-1h0.8
            V-2767.3z M1476.3-2769.3h-0.8v-1h0.8V-2769.3z M1476.3-2771.3h-0.8v-1h0.8V-2771.3z M1476.3-2773.4h-0.8v-1h0.8V-2773.4z
             M1476.3-2775.4h-0.8v-1h0.8V-2775.4z M1476.3-2777.4h-0.8v-1h0.8V-2777.4z M1476.3-2779.4h-0.8v-1h0.8V-2779.4z'
            />
            <line
              fill='none'
              stroke='#95989A'
              strokeWidth='0.75'
              strokeMiterlimit='10'
              x1='1475.9'
              y1='-2762.1'
              x2='1475.9'
              y2='-2761.6'
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill='#95989A'
              d='M1473.2-2771.8c-2.4,0-4.4,1.9-4.4,4.3c0,2.4,2.2,6.3,4.3,8.4c2-2,4.5-5.9,4.5-8.3
            C1477.5-2769.8,1475.6-2771.7,1473.2-2771.8z M1473.2-2764.9c-1.4,0-2.6-1.2-2.6-2.6s1.2-2.6,2.6-2.6c1.4,0,2.6,1,2.6,2.4
            c0,0.1,0,0.1,0,0.2C1475.7-2766.1,1474.6-2765,1473.2-2764.9z'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default MapIcon
