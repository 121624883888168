import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  }
}

const Legumes = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-3728.748 1075.543 97.745 97.745'
  >
    <g transform='translate(-3914.5 1075.543)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(186.252 0.5)'
      />
      <g transform='translate(204.353 19.705)'>
        <g>
          <path
            fill='#009245'
            d='M295.5,41.776s5.518-.645,5.446-3.8c-.358-3.8,1.218-10.749,3.583-10.678,1.863.645,2.723,3.44,1.792,4.228-4.873,3.941-3.368,10.319,1.863,8.456l1.075.215L306.608,46l-9.6.287Z'
            transform='translate(-288.089 -27.299)'
          />
          <path
            fill='#00452a'
            d='M293.071,46.584c-5.733-1.218-10.391,2.58-6.45,12.613,4.371,7.238,1.577,19.349,11.824,18.417,2.365,1.792,7.811,1.218,9.173-1.72h0c9.245-.788,4.085-12.326,6.665-20.352h0c1.5-9.03-3.583-13.329-8.958-10.821C303.749,49.594,294.146,50.239,293.071,46.584Z'
            transform='translate(-285.159 -32.036)'
          />
          <g transform='translate(6.824 26.588)'>
            <path
              fill='#009245'
              d='M302.792,84.179a7.123,7.123,0,0,1-2.15-1.72,16.3,16.3,0,0,1-1.577-2.293,40.164,40.164,0,0,1-2.365-5.016,34.356,34.356,0,0,1-1.5-5.3,23.37,23.37,0,0,1-.5-5.446,35.172,35.172,0,0,0,.932,5.375,43.965,43.965,0,0,0,1.577,5.231,30.65,30.65,0,0,0,2.222,4.945,24.7,24.7,0,0,0,1.433,2.293A6.228,6.228,0,0,0,302.792,84.179Z'
              transform='translate(-294.681 -64.4)'
            />
          </g>
          <g transform='translate(22.46 29.669)'>
            <path
              fill='#00452a'
              d='M316.5,82.989c1.362-1.648,1.147-14.046.932-14.189'
              transform='translate(-316.5 -68.728)'
            />
            <path
              fill='#009245'
              d='M316.5,82.961a4.679,4.679,0,0,0,.43-1.72,13.956,13.956,0,0,0,.143-1.792c.072-1.218.143-2.365.143-3.583.072-1.218.072-2.365.143-3.583l.072-1.792a14.938,14.938,0,0,0,0-1.792,19.593,19.593,0,0,1,.358,3.583c.072,1.218,0,2.365,0,3.583-.072,1.218-.143,2.365-.287,3.583-.072.573-.215,1.218-.287,1.792A7.269,7.269,0,0,1,316.5,82.961Z'
              transform='translate(-316.5 -68.7)'
            />
          </g>
        </g>
        <g transform='translate(7.339 3.311)'>
          <path
            fill='#006c59'
            d='M362.18,43.156s10.248-7.095,11.538-5.948l-1-.788s-3.153.573-3.511,1.577c2.437-2.078,2.938-2.58,2.938-2.58l-.932-.788-4.658,3.941s2.867-3.655,2.437-3.87-3.153,3.368-3.655,3.082,1-5.661,1-5.661a.534.534,0,0,0-.932.215c-.717,1.577-3.727,5.446-5.8,7.955Z'
            transform='translate(-313.592 -31.919)'
          />
          <path
            fill='#f89009'
            d='M341.551,41.21c-1.935-2.508-6.306-1.147-10.391,3.511s-27.734,23.29-25.44,28.665c0,0-2.723,3.726-3.511,4.515s-1.147,4.945-6.808,7.238a9.254,9.254,0,0,0,7.023-5.948l4.586-3.583c4.586,3.44,22.574-11.538,33.037-20.782,5.876-3.941,7.668-6.808,4.586-10.176Z'
            transform='translate(-295.4 -34.201)'
          />
          <g transform='translate(38.34 8.084)'>
            <path
              fill='#f15a24'
              d='M348.9,43.2a23.173,23.173,0,0,0,2.078,3.082,29.507,29.507,0,0,0,2.365,2.867,10.621,10.621,0,0,1-2.795-2.58A9.841,9.841,0,0,1,348.9,43.2Z'
              transform='translate(-348.9 -43.2)'
            />
          </g>
          <g transform='translate(36.107 14.821)'>
            <path
              fill='#f15a24'
              d='M345.8,52.6a3.417,3.417,0,0,0,.86,1.577,11.015,11.015,0,0,0,1.218,1.362c.43.43.86.86,1.29,1.362.43.43.86.86,1.362,1.29a5.657,5.657,0,0,1-1.648-1,11.594,11.594,0,0,1-1.362-1.29,7.607,7.607,0,0,1-1.147-1.5A2.688,2.688,0,0,1,345.8,52.6Z'
              transform='translate(-345.785 -52.6)'
            />
          </g>
          <g transform='translate(32.535 21.127)'>
            <path
              fill='#f15a24'
              d='M345.673,67.061a2.125,2.125,0,0,1-1.935-.573,9.033,9.033,0,0,1-1.433-1.433,11.293,11.293,0,0,1-1.075-1.72,4.978,4.978,0,0,1-.358-.932,1.791,1.791,0,0,1,0-1,1.44,1.44,0,0,0,.287.932,5.348,5.348,0,0,0,.5.86c.358.573.717,1.075,1.147,1.648l1.29,1.5A2.7,2.7,0,0,0,345.673,67.061Z'
              transform='translate(-340.8 -61.4)'
            />
          </g>
          <g transform='translate(15.049 30.443)'>
            <path
              fill='#f15a24'
              d='M316.4,74.4c.358.43.645.932,1,1.29.358.43.788.788,1.147,1.218a4.693,4.693,0,0,1-1.29-1.075A3.343,3.343,0,0,1,316.4,74.4Z'
              transform='translate(-316.4 -74.4)'
            />
          </g>
          <g transform='translate(15.193 32.593)'>
            <path
              fill='#f15a24'
              d='M316.6,77.4c.43.43.86.932,1.29,1.362a7.2,7.2,0,0,0,1.433,1.147,4.685,4.685,0,0,1-1.648-.932A12.21,12.21,0,0,1,316.6,77.4Z'
              transform='translate(-316.6 -77.4)'
            />
          </g>
          <g transform='translate(23.649 27.433)'>
            <path
              fill='#f15a24'
              d='M328.4,70.2a3.758,3.758,0,0,0,.717,1.29,7.236,7.236,0,0,0,.932,1.147,5.283,5.283,0,0,0,2.437,1.648,4.768,4.768,0,0,1-2.58-1.5,7.822,7.822,0,0,1-.932-1.218A2.335,2.335,0,0,1,328.4,70.2Z'
              transform='translate(-328.4 -70.2)'
            />
          </g>
          <g transform='translate(14.834 37.681)'>
            <path
              fill='#f15a24'
              d='M318.537,87.223c-.287.143-.645-.072-.932-.287a5.819,5.819,0,0,1-.645-.717,9.636,9.636,0,0,1-.86-1.72c.358.5.645,1.075,1,1.648a2.807,2.807,0,0,0,.573.717C317.963,87.008,318.25,87.295,318.537,87.223Z'
              transform='translate(-316.1 -84.5)'
            />
          </g>
          <g transform='translate(22.574 33.525)'>
            <path
              fill='#f15a24'
              d='M329.265,81.423a1.44,1.44,0,0,1-.932-.287,2.033,2.033,0,0,1-.645-.717,4.723,4.723,0,0,1-.788-1.72l.43.86a4.82,4.82,0,0,0,.5.788,2.806,2.806,0,0,0,.573.717C328.763,81.137,328.978,81.352,329.265,81.423Z'
              transform='translate(-326.9 -78.7)'
            />
          </g>
          <path
            fill='none'
            d='M358.419,58.067s-2.867-2.078-2.437-2.867'
            transform='translate(-312.555 -38.516)'
          />
          <g transform='translate(26.763 18.762)'>
            <path
              fill='#f15a24'
              d='M332.785,58.1a6.275,6.275,0,0,0,.717,1.577,9.537,9.537,0,0,0,1,1.362c.358.43.717.86,1.147,1.29a3.819,3.819,0,0,0,1.433.932,2.778,2.778,0,0,1-1.648-.645,7,7,0,0,1-1.29-1.218,10.994,10.994,0,0,1-1-1.5A3.114,3.114,0,0,1,332.785,58.1Z'
              transform='translate(-332.746 -58.1)'
            />
          </g>
        </g>
        <g transform='translate(2.235 24.008)'>
          <path
            fill='#5c1a62'
            d='M342.543,88.491c-3.655,7.31-48.516,24.652-54.106,1.147-1.362-7.525,5.8-19.277,29.167-11.609,9.1,1.863,23.219-4.228,23.219-4.228Z'
            transform='translate(-288.278 -64.484)'
          />
          <path
            fill='#009245'
            d='M359.7,71.836c2.723,5.446.5,15.049-4.658,16.626a17.726,17.726,0,0,0,.358-11.179c-1.577,3.225-8.528,5.088-8.528,5.088.932-2.938,3.583-8.743,3.583-8.743-1.72-1.147-8.456.43-8.456.43.717-2.078,9.674-6.593,15.981-4.8l3.726-3.655-.645-4.8,4.013,4.8Z'
            transform='translate(-303.501 -60.8)'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(Legumes)
