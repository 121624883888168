import { Cookies } from 'react-cookie'
import { categoriesLinkValues, categoriesOptions } from './config/dataConf'
import ReactHtmlParser from 'react-html-parser'
import sanitizeHtml from 'sanitize-html'
import routes from './routing/routes'

const tokenMediaSize = '903px'
const mediaSize = '833px'

export const getMediaQuerySize = () => {
  const cookieManager = new Cookies()
  const token = cookieManager.get('token')

  if (token && token !== '') {
    return `@media (min-width: ${tokenMediaSize})`
  }
  return `@media (min-width: ${mediaSize})`
}

export const getMaxMediaQuerySize = () => {
  const cookieManager = new Cookies()
  const token = cookieManager.get('token')

  if (token && token !== '') {
    return `@media (max-width: ${tokenMediaSize})`
  }
  return `@media (max-width: ${mediaSize})`
}

export const encodeBase64 = string => {
  return Buffer.from(string).toString('base64')
}

export const decodeBase64 = string => {
  return Buffer.from(string, 'base64').toString('utf8')
}

export const isCategoryValid = categoryToTest => {
  let response = false

  categoriesLinkValues.forEach(category => {
    if (
      category.urlValue === categoryToTest ||
      categoryToTest === categoriesOptions[0].value
    )
      response = true
  })

  return response
}

export const getCategoryTitleFromUrlParams = categoryUrl => {
  let cat = null

  categoriesLinkValues.map(categoryObject => {
    if (!cat && categoryObject.urlValue === categoryUrl) {
      cat = categoryObject.titleValue
    }
  })

  return cat || 'Producteurs locaux'
}

export const getValueFromUrlValue = urlValue => {
  let cat = categoriesOptions[0].value

  categoriesLinkValues.map(categoryObject => {
    if (categoryObject.urlValue === urlValue) {
      cat = categoryObject.value
    }
  })

  return cat
}

export const getUrlValueFromValue = value => {
  let url = 'all'

  categoriesLinkValues.map(categoryObject => {
    if (categoryObject.value === value) {
      url = categoryObject.urlValue
    }
  })

  return url
}

export const fillUrlParamsExceptPage = (url, params) => {
  let res = url

  for (const value in params) {
    if (value !== 'page') {
      res = res.replace(':' + value, params[value])
    }
  }

  return res
}

export const interpretHtml = string => ReactHtmlParser(sanitizeHtml(string))

export const fetchAddressAutocomplete = (array, input) => {
  if (input.length > 3) {
    // eslint-disable-next-line no-undef
    return fetch(routes.apiAddress + input)
      .then(response => response.json())
      .then(data => {
        const addresses = data.features
          ? data.features.map(address => ({
              value: address.geometry.coordinates,
              label: address.properties.label
            }))
          : []
        addresses.map(address => array.push(address))

        return array
      })
  } else {
    return Promise.resolve(array)
  }
}
