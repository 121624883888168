import React from 'react'
import { Route } from 'react-router-dom'
import routes from 'src/routing/routes'
import { Switch } from 'react-router'
import AnnuaireVille from './scenes/AnnuaireVille'

const Annuaire = props => {
  return (
    <Switch>
      <Route path={routes.annuaireVilles} component={AnnuaireVille} />
    </Switch>
  )
}

export default Annuaire
