import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Route } from 'react-router'
import ErrorCode from './scenes/ErrorCode'

class ErrorPage extends React.Component {
  render() {
    const { codeNumber, message } = this.props
    const code = codeNumber || 404

    return (
      <Fragment>
        {typeof window !== 'undefined' && (
          <Helmet>
            <title>{window.location.href}</title>
          </Helmet>
        )}

        <Route
          render={({ staticContext }) => {
            if (staticContext) {
              staticContext.status = code
            }

            return (
              <ErrorCode
                code={code}
                message={message || (code === 404 ? 'Page introuvable' : null)}
              />
            )
          }}
        />
      </Fragment>
    )
  }
}

ErrorPage.propTypes = {
  codeNumber: PropTypes.number,
  message: PropTypes.string
}

export default ErrorPage
