import React from 'react'
import injectSheet from 'react-jss'
import PropTypes from 'prop-types'

const styles = theme => ({
  h5: {
    fontWeight: theme.bold,
    fontSize: theme.taillePetitTexte
  },
  jaune: {
    color: theme.titre5J
  },
  vert: {
    color: theme.titre5V
  }
})

const Titre5 = ({
  jaune = false,
  classes,
  className,
  children,
  component: Component = 'h5',
  ...props
}) => (
  <Component
    className={`${classes.h5} ${jaune ? classes.jaune : classes.vert} ${
      className || ''
    }`}
    {...props}
  >
    {children}
  </Component>
)

Titre5.propTypes = {
  jaune: PropTypes.bool
}

export default injectSheet(styles)(Titre5)
