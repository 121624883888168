import React from 'react'

const Marqueur = ({ ...props }) => (
  <svg {...props} viewBox='0 0 13 17.5'>
    <path
      fill='#ABE0E0'
      d='M6.5,17.5l-1-1C2.9,14,0,9.9,0,6.5C0,2.9,3,0,6.6,0c0,0,0,0,0,0C10.1,0,13,2.9,13,6.4l0,0.1
    c0,3.2-2.8,7.4-5.5,10L6.5,17.5z M6.6,3C6.6,3,6.6,3,6.6,3C4.6,3,3,4.6,3,6.5c0,1.6,1.4,4.3,3.5,6.7C8.4,11.1,10,8.3,10,6.5l0-0.1
    C10,4.5,8.5,3,6.6,3z'
    />
  </svg>
)

export default Marqueur
