import React, { Component } from 'react'
import AnnuaireVille from './AnnuaireVille'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import ErrorPage from '../../../ErrorPage'

const query = gql`
  {
    getTopCities {
      id
      name
    }
  }
`

class AnnuaireVilleContainer extends Component {
  render() {
    const {
      data: { getTopCities, loading }
    } = this.props

    return !loading && !getTopCities ? (
      <ErrorPage />
    ) : (
      <AnnuaireVille cities={getTopCities} loading={loading} />
    )
  }
}

export default graphql(query)(AnnuaireVilleContainer)
