import React from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router'

const RedirectWithStatus = ({ noStrict, from, to, status }) => (
  <Route
    strict={!noStrict}
    exact
    path={from}
    render={({ staticContext }) => {
      // there is no `staticContext` on the client, so
      // we need to guard against that here
      if (staticContext) staticContext.status = status

      return (
        <Switch>
          <Redirect from={from} to={to} />
        </Switch>
      )
    }}
  />
)

export default withRouter(RedirectWithStatus)
