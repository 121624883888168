import React from 'react'

const RS = ({ ...props }) => (
  <svg {...props} viewBox='-1152.443 371 20.443 20.442'>
    <path
      fill='#2daade'
      d='M67.621,0A10.221,10.221,0,1,0,77.842,10.221,10.224,10.224,0,0,0,67.621,0Zm4.1,8.145v.266a5.926,5.926,0,0,1-9.156,5c.16,0,.319.053.479.053a4.2,4.2,0,0,0,2.609-.905,2.131,2.131,0,0,1-1.97-1.437c.106,0,.266.053.373.053a1.641,1.641,0,0,0,.532-.053,2.161,2.161,0,0,1-1.7-2.076h0a1.838,1.838,0,0,0,.958.266,2.057,2.057,0,0,1-.958-1.757,2.423,2.423,0,0,1,.266-1.065,5.8,5.8,0,0,0,4.312,2.183,1.474,1.474,0,0,1-.053-.479,2.079,2.079,0,0,1,2.076-2.076,2.264,2.264,0,0,1,1.544.639,4.107,4.107,0,0,0,1.331-.532,2.206,2.206,0,0,1-.9,1.171,4.6,4.6,0,0,0,1.224-.319A3.02,3.02,0,0,1,71.72,8.145Z'
      transform='translate(-1209.843 371)'
    />
  </svg>
)

export default RS
