import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import routes from 'src/routing/routes'
import ForgottenPassword from './ForgottenPassword.container'
import NewPassword from 'src/scenes/front/ForgottenPassword/scenes/NewPassword'
import { withCookies } from 'react-cookie'
import ErrorPage from '../ErrorPage/ErrorPage'

class ForgottenPasswordRouter extends Component {
  render() {
    return this.props.cookies.get('token') ? (
      <Redirect to={routes.accueil} />
    ) : (
      <Switch>
        <Route
          exact
          path={routes.forgottenPassword}
          component={ForgottenPassword}
        />
        <Route exact path={routes.newPassword} component={NewPassword} />
        <Route component={ErrorPage} />
      </Switch>
    )
  }
}

export default withCookies(ForgottenPasswordRouter)
