import React from 'react'

const Nuage = ({ taille, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-2963 -40 159.925 164.984'
  >
    <path
      fill='#fff'
      d='M141.3,107.863v-.587a41.905,41.905,0,0,0-8.433-25.224V1.467H131.4V80.146A42.048,42.048,0,0,0,63.354,85.5a27.022,27.022,0,0,0-4.253-.293A29.411,29.411,0,0,0,31.09,105.663H29.624a29.815,29.815,0,0,0-14.152,3.593V0H14.005V110.136A29.86,29.86,0,0,0,0,135.361H0a29.745,29.745,0,0,0,29.7,29.624h100.53a29.808,29.808,0,0,0,29.7-29.7h0A29.833,29.833,0,0,0,141.3,107.863Z'
      transform='translate(-2963 -40)'
    />
  </svg>
)

export default Nuage
