import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  }
}

const Fromage = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-3072.243 1075.543 97.745 97.745'
  >
    <g transform='translate(-3914.5 1075.543)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(842.757 0.5)'
      />
      <g transform='translate(856.567 21.426)'>
        <path
          fill='#ffd8a9'
          d='M1195.3,53.7V83.368c.358,5.016,12.254,4.013,16.769,3.3,1.218-.645.932-3.01,3.87-3.511,4.443.5,3.01,3.3,5.016,3.655l41.135-.143V72.117c-.932-1.577-5.733-.717-6.091-5.8-.215-6.306,5.661-5.8,6.521-6.951l-.43-4.873Z'
          transform='translate(-1195.279 -36.501)'
        />
        <path
          fill='#edb57a'
          d='M1262.153,47.616h-66.862s-1.863-16.482,29.525-17.916Z'
          transform='translate(-1195.271 -29.7)'
        />
        <circle
          fill='#c9955f'
          cx='6.091'
          cy='6.091'
          r='6.091'
          transform='translate(29.331 32.75)'
        />
        <circle
          fill='#edb57a'
          cx='5.518'
          cy='5.518'
          r='5.518'
          transform='translate(30.048 32.678)'
        />
        <circle
          fill='#edb57a'
          cx='3.153'
          cy='3.153'
          r='3.153'
          transform='translate(14.855 34.972)'
        />
        <circle
          fill='#edb57a'
          cx='4.3'
          cy='4.3'
          r='4.3'
          transform='translate(5.109 21.857)'
        />
        <circle
          fill='#edb57a'
          cx='3.153'
          cy='3.153'
          r='3.153'
          transform='translate(44.022 25.154)'
        />
        <circle
          fill='#edb57a'
          cx='4.085'
          cy='4.085'
          r='4.085'
          transform='translate(52.55 36.691)'
        />
        <circle
          fill='#edb57a'
          cx='2.078'
          cy='2.078'
          r='2.078'
          transform='translate(22.022 25.082)'
        />
        <circle
          fill='#edb57a'
          cx='1.147'
          cy='1.147'
          r='1.147'
          transform='translate(35.566 22.789)'
        />
        <circle
          fill='#edb57a'
          cx='1.075'
          cy='1.075'
          r='1.075'
          transform='translate(45.025 42.783)'
        />
        <circle
          fill='#edb57a'
          cx='1.147'
          cy='1.147'
          r='1.147'
          transform='translate(7.187 40.705)'
        />
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(Fromage)
