import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  }
}

const Vin = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-2907.418 1075.543 97.745 97.745'
  >
    <g transform='translate(-3914.5 1075.543)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(1007.582 0.5)'
      />
      <g transform='translate(1031.359 8.067)'>
        <path
          fill='#bdccd4'
          d='M1458.979,70.123a15.51,15.51,0,0,0-2.293-7.023h-15.264a15.59,15.59,0,0,0-2.222,7.095'
          transform='translate(-1439.185 -25.806)'
        />
        <path
          fill='#8f272d'
          d='M1458.973,72.9c.287,5.733-3.512,9.961-8.313,12.039l.143,16.482,6.593,2.795h-15.981l6.163-2.867V84.939c-4.8-2.078-8.671-6.306-8.385-12.039h19.779Z'
          transform='translate(-1439.179 -28.583)'
        />
        <path
          fill='#8f272d'
          d='M1488.218,85.833l-.5-44.431s-.072-7.238-5.375-9.03c0,0-.143-16.626-.072-19.564,0-2.508-7.883-2.15-7.811,0,.072,1.362,0,19.277,0,19.277s-4.8,1.577-5.446,8.815c-.5,5.446,0,44.933,0,44.933C1471.735,90.276,1486.856,89.2,1488.218,85.833Z'
          transform='translate(-1447.57 -11.059)'
        />
        <rect
          fill='#bdccd4'
          width='14.691'
          height='24.724'
          transform='translate(23.305 34.643)'
        />
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(Vin)
