import React from 'react'
import { hydrate } from 'react-dom'
import 'bootstrap/dist/css/bootstrap.css'
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import Client from './Client'
import { loadableReady } from '@loadable/component'
// import * as Sentry from '@sentry/browser'
// import { NODE_ENV, PUBLIC_URL } from './config/env'

const removeNode = nodeName => {
  const node = document.getElementById(nodeName)

  if (node) {
    node.parentNode.removeChild(node)
  }
}
// if (NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://3aaad51ae50042e1aae309d555bdfd4b@sentry.webf.fr/29',
//     whitelistUrls: [`${PUBLIC_URL}/`]
//   })
// }

loadableReady(() => {
  hydrate(<Client />, document.getElementById('root'), () => {
    removeNode('bootstrap-server')
    removeNode('server-side-styles')
  })
})
