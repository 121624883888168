import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

const styles = theme => ({
  paragraphe: {
    fontWeight: theme.regular,
    color: theme.texte,
    fontSize: theme.taillePetitTexte
  }
})

const P = ({
  classes,
  className,
  children,
  component: Component = 'p',
  ...props
}) => (
  <Component
    {...props}
    className={`${classes.paragraphe}${className ? ` ${className}` : ''}`}
  >
    {children}
  </Component>
)

P.propTypes = {
  className: PropTypes.string
}

export default injectSheet(styles)(P)
