import React, { Component, Fragment } from 'react'
import { Grid } from 'react-bootstrap'
import injectSheet from 'react-jss'
import TopBar from '../../../components/top_bar/TopBar'
import Titre1 from '../../../components/font/Titre1'
import P from '../../../components/font/P'
import Titre2 from '../../../components/font/Titre2'
import Footer from '../../../components/footer/Footer'
import { Field, Form } from 'react-final-form'
import ButtonStyle from 'src/components/button/ButtonStyle'
import { colors } from 'src/config/themes'
import Select from 'react-select'
import { civiliteOptions } from 'src/config/dataConf'
import { withCookies } from 'react-cookie'
import { Helmet } from 'react-helmet'
import ModalConfirmation from './components/ModalConfirmation'
import 'regenerator-runtime/runtime'

const styles = theme => ({
  page: {
    backgroundColor: theme.backgroundColor,
    minHeight: theme.minHeight,
    paddingBottom: 15
  },
  grid: {
    backgroundColor: 'white',
    margin: '15px auto 0'
  },
  spaceField: {
    marginTop: 15
  },
  rubrique: {
    color: theme.titre1
  },
  space: {
    marginBottom: '10px'
  },
  textarea: {
    resize: 'none'
  },
  error: {
    color: 'red'
  },
  succeeded: {
    color: 'green'
  },
  disabled: {
    backgroundColor: colors.gris,
    borderColor: colors.gris,
    '&:hover': {
      borderColor: colors.gris
    }
  },
  validationDiv: {
    margin: '15px 0'
  },
  label: {
    width: 120,
    display: 'inline-block'
  },
  input: {
    border: theme.borderInput,
    width: 250,
    height: 38,
    padding: '2px 8px'
  },
  civility: {
    '& .civility__control': {
      border: theme.borderInput,
      borderRadius: 0
    },
    width: 250,
    display: 'inline-block'
  },
  textareaMessage: {
    resize: 'none',
    width: '100%',
    outline: 'none',
    height: 150,
    border: theme.borderInput,
    color: 'black',
    padding: '2px 8px'
  }
})

class Contact extends Component {
  state = {
    showModalConfirmation: false,
    succeed: false,
    errorMessage: null,
    formValues: null
  }

  onSubmit = values => {
    this.setState({ showModalConfirmation: true, formValues: values })
  }

  closeModalConfirmation = () => {
    this.setState({ showModalConfirmation: false })
  }

  sendForm = async () => {
    const message = await this.props.send(this.state.formValues)

    if (message) {
      this.setState({ errorMessage: message })
    } else {
      this.setState({ succeed: true })
    }
  }

  validate = (values, token) => {
    const errors = {}
    const errorMessage = ' Champ requis '

    if (!token && !values.firstName) {
      errors.firstName = errorMessage
    }

    if (!token && !values.lastName) {
      errors.lastName = errorMessage
    }

    if (!token && !values.mail) {
      errors.mail = errorMessage
    }

    if (!token && !values.civility) {
      errors.civility = errorMessage
    }

    if (!values.subject) {
      errors.subject = errorMessage
    }

    if (!values.message) {
      errors.message = errorMessage
    }

    return errors
  }

  render() {
    const { classes, cookies } = this.props
    const token = cookies.get('token')

    return (
      <Fragment>
        <Helmet>
          <title>Contactez nous</title>
        </Helmet>
        <div className={classes.page}>
          <TopBar />
          <Grid className={classes.grid}>
            <Titre1>Contactez l'équipe d'Acheter à la Source</Titre1>

            <Titre2 className={classes.rubrique}>
              N'hésitez pas à nous faire part de vos remarques
            </Titre2>
            <P>
              Ce formulaire de contact permet d'écrire{' '}
              <strong>aux informaticiens</strong> du site
              www.acheteralasource.com. Si vous souhaitez écrire à un producteur
              reportez-vous à sa fiche et remplissez le formulaire de contact en
              y accédant depuis le bouton "Poser une question".
            </P>
            <Form
              onSubmit={this.onSubmit}
              validate={values => this.validate(values, token)}
              render={({
                handleSubmit,
                submitting,
                submitError,
                submitSucceeded
              }) => (
                <form onSubmit={handleSubmit}>
                  {!token && (
                    <Fragment>
                      <Field name='firstName'>
                        {({ input, meta }) => (
                          <div>
                            <label
                              htmlFor={input.name}
                              className={classes.label}
                            >
                              Prénom :
                            </label>
                            <input
                              className={classes.input}
                              type='text'
                              {...input}
                              required
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>

                      <Field name='lastName'>
                        {({ input, meta }) => (
                          <div className={classes.spaceField}>
                            <label
                              htmlFor={input.name}
                              className={classes.label}
                            >
                              Nom :
                            </label>
                            <input
                              className={classes.input}
                              type='text'
                              {...input}
                              required
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>

                      <Field name='civility'>
                        {({ input, meta }) => (
                          <div className={classes.spaceField}>
                            <label
                              htmlFor={input.name}
                              className={classes.label}
                            >
                              Civilité :
                            </label>
                            <Select
                              instanceId='civility-2'
                              name='civility'
                              className={classes.civility}
                              classNamePrefix='civility'
                              placeholder={'Sélectionnez votre civilité'}
                              defaultValue={civiliteOptions[0]}
                              value={input.value}
                              onChange={input.onChange}
                              options={civiliteOptions}
                              isClearable={false}
                              isSearchable={false}
                              components={{ IndicatorSeparator: () => <div /> }}
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>

                      <Field name='mail'>
                        {({ input, meta }) => (
                          <div className={classes.spaceField}>
                            <label
                              htmlFor={input.name}
                              className={classes.label}
                            >
                              Email :
                            </label>
                            <input
                              className={classes.input}
                              type='email'
                              {...input}
                              required
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Fragment>
                  )}

                  <Field name='subject'>
                    {({ input, meta }) => (
                      <div className={classes.spaceField}>
                        <label htmlFor={input.name} className={classes.label}>
                          Sujet :
                        </label>
                        <input
                          className={classes.input}
                          type='text'
                          {...input}
                          required
                        />
                        {meta.touched && (meta.error || meta.submitError) && (
                          <span className={classes.error}>
                            {meta.error}
                            {meta.submitError}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>

                  <Field name='message'>
                    {({ input, meta }) => (
                      <div className={classes.spaceField}>
                        <label htmlFor={input.name} className={classes.label}>
                          Votre message :
                        </label>
                        <textarea
                          className={classes.textareaMessage}
                          {...input}
                        />
                        {meta.touched && (meta.error || meta.submitError) && (
                          <span className={classes.error}>
                            {meta.error}
                            {meta.submitError}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>

                  {this.state.errorMessage && (
                    <div className={classes.error}>
                      {this.state.errorMessage}
                    </div>
                  )}
                  {this.state.succeed && (
                    <div className={classes.succeeded}>Message envoyé !</div>
                  )}

                  <div className={classes.validationDiv}>
                    {submitting ? (
                      <ButtonStyle
                        className={classes.disabled}
                        disabled={submitting}
                        type='submit'
                        jaune
                        defaultFont
                      >
                        Envoyer
                      </ButtonStyle>
                    ) : (
                      <ButtonStyle type='submit' jaune defaultFont>
                        Envoyer
                      </ButtonStyle>
                    )}
                  </div>
                </form>
              )}
            />
            <ModalConfirmation
              show={this.state.showModalConfirmation}
              onHide={this.closeModalConfirmation}
              send={this.sendForm}
            />
          </Grid>
        </div>
        <Footer />
      </Fragment>
    )
  }
}

export default withCookies(injectSheet(styles)(Contact))
