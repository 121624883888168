import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import TopBarWithoutConnexion from '../../../../../components/top_bar/TopBarWithoutConnexion'
import { Grid } from 'react-bootstrap'
import ButtonLink from '../../../../../components/button/ButtonLink'
import routes from '../../../../../routing/routes'
import FondAccueil from '../../../../../components/svg/FondAccueil'
import injectSheet from 'react-jss'
import { colors } from '../../../../../config/themes'

const styles = theme => ({
  page: {
    paddingTop: 15,
    minHeight: '100vh',
    backgroundColor: theme.backgroundColor
  },
  divFondAccueil: {
    width: '100%'
  },
  erreur: {
    fontWeight: theme.extraBold,
    color: colors.vert,
    fontSize: 48,
    textAlign: 'center'
  },
  center: {
    textAlign: 'center'
  }
})

const ErrorCode = ({ classes, code, message }) => {
  return (
    <Fragment>
      <div className={classes.page}>
        <TopBarWithoutConnexion />

        <Grid fluid>
          <div className={classes.center}>
            <p className={classes.erreur}>
              Erreur {code}
              {message ? ` : ${message}` : ''}
            </p>
            <ButtonLink to={routes.accueil}>Retour à l'accueil</ButtonLink>
          </div>
          <div className={classes.divFondAccueil}>
            <FondAccueil width={'100%'} />
          </div>
        </Grid>
      </div>
    </Fragment>
  )
}

ErrorCode.propTypes = {
  code: PropTypes.number.isRequired,
  message: PropTypes.string
}

export default injectSheet(styles)(ErrorCode)
