import React from 'react'
import ProdAccueil from './ProdAccueil/ProdAccueil'
import { Col } from 'react-bootstrap'
import injectSheet from 'react-jss'

const styles = {
  flexProdAccueilContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'stretch'
  }
}

class listeArticle extends React.Component {
  render() {
    let { articles, classes } = this.props

    return (
      articles && (
        <div className={classes.flexProdAccueilContainer}>
          {articles.map((article, i) => (
            <Col key={i} xs={12} sm={4}>
              <ProdAccueil
                idProducer={article.id}
                companyName={article.companyName}
                presentation={article.presentation}
                photosProducer={article.photosProducer}
                address={article.address}
              />
            </Col>
          ))}
        </div>
      )
    )
  }
}

export default injectSheet(styles)(listeArticle)
