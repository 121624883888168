import React, { Component } from 'react'
import { withCookies } from 'react-cookie'
import CompteActifAFBlanc from '../svg/connexion/CompteActifAFBlanc'
import CompteInactif from '../svg/connexion/CompteInactif'
import CompteActifAFVert from '../svg/connexion/CompteActifAFVert'
import CompteInactifVert from '../svg/connexion/CompteInactifVert'
import CompteActifVert from '../svg/connexion/CompteActifVert'
import { Link } from 'react-router-dom'
import routes from '../../routing/routes'

class IconeConnexionGF extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      token: props.cookies.get('token')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { cookies } = this.props
    const token = cookies.get('token')

    if (!prevState.token && token) {
      this.setState({ token })
      this.props.closeModalConnexion()
    }

    if (this.state.token !== token) {
      this.setState({ token })
    }
  }

  render() {
    const { bleu, className, mobile, openModalConnexion } = this.props
    const token = this.state.token

    return mobile ? (
      token && token !== '' ? (
        <Link to={routes.dashboard}>
          <CompteActifVert taille={30} className={className} />
        </Link>
      ) : (
        <CompteInactifVert
          taille={30}
          className={className}
          onClick={openModalConnexion}
        />
      )
    ) : bleu ? (
      token && token !== '' ? (
        <Link to={routes.dashboard}>
          <CompteActifAFBlanc className={className} taille={100} />
        </Link>
      ) : (
        <CompteInactif
          className={className}
          taille={30}
          onClick={openModalConnexion}
        />
      )
    ) : token && token !== '' ? (
      <Link to={routes.dashboard}>
        <CompteActifAFVert className={className} taille={100} />
      </Link>
    ) : (
      <CompteInactifVert
        className={className}
        taille={30}
        onClick={openModalConnexion}
      />
    )
  }
}

export default withCookies(IconeConnexionGF)
