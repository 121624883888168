import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Col, Grid, Row } from 'react-bootstrap'
import injectSheet from 'react-jss'
import { withCookies } from 'react-cookie'
import { colors } from '../../../../../config/themes'
import routes from '../../../../../routing/routes'
import Logo from '../../../../../components/svg/Logo'
import Nuage from '../../../../../components/svg/Nuage'
import CompteActifAFVert from '../../../../../components/svg/connexion/CompteActifAFVert'
import ModalConnexion from '../../../../../components/modal/ModalConnexion'

const styles = theme => ({
  grid: {
    backgroundColor: theme.topBarColor
  },
  lienLogo: {
    margin: '15px 0px 15px 15px'
  },
  align: {
    textAlign: 'right',
    position: 'relative'
  },
  lienNuage: {
    color: theme.lien,
    fontSize: '16px',
    fontWeight: theme.bold,
    position: 'absolute',
    right: '55px',
    bottom: '25px',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
      color: colors.bleu
    }
  },
  lienNuageActifAF: {
    color: theme.lien,
    fontSize: '16px',
    fontWeight: theme.bold,
    position: 'absolute',
    right: '40px',
    bottom: '25px',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
      color: colors.bleu
    }
  }
})

class TopBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      token: props.cookies.get('token')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { cookies } = this.props
    const token = cookies.get('token')

    if (!prevState.token && token) {
      this.setState({ token })
      this.closeModalConnexion()
    }

    if (this.state.token !== token) {
      this.setState({ token })
    }
  }

  closeModalConnexion = () => {
    this.setState({ showModal: false })
  }

  openModalConnexion = () => {
    this.setState({ showModal: true })
  }

  render() {
    let { classes, className } = this.props
    const token = this.state.token

    return (
      <Grid
        fluid
        className={`${classes.grid}${className ? ` ${className}` : ''}`}
      >
        <Row>
          <Col xs={8}>
            <div className={classes.lienLogo}>
              <Link to={routes.accueil}>
                <Logo width={'200'} />
              </Link>
            </div>
          </Col>
          <Col xs={4} className={classes.align}>
            <Nuage taille={160} />
            {token ? (
              <Link to={routes.dashboard}>
                <CompteActifAFVert
                  taille={110}
                  className={classes.lienNuageActifAF}
                />
              </Link>
            ) : (
              <span
                className={classes.lienNuage}
                onClick={this.openModalConnexion}
              >
                Connexion
              </span>
            )}
          </Col>
        </Row>

        <ModalConnexion
          show={this.state.showModal}
          onHide={this.closeModalConnexion}
        />
      </Grid>
    )
  }
}

const injectedSheet = injectSheet(styles)(TopBar)

export default withCookies(injectedSheet)
