import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  }
}

const Volailles = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-3564.855 1232.485 97.745 97.745'
  >
    <g transform='translate(-3914.5 1075.543)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(350.145 157.442)'
      />
      <g transform='translate(369.853 169.648)'>
        <path
          fill='#ef2022'
          d='M522.55,241.884c-2.508-6.02,8.17-8.17,10.606,0'
          transform='translate(-517.82 -236.533)'
        />
        <path
          fill='#ef2022'
          d='M521.953,255.882c0,2.007-.645,3.583,1.075,3.583,1.863,0,.86-.932.86-2.938s-.215-3.726-.645-3.726S521.953,253.875,521.953,255.882Z'
          transform='translate(-517.725 -241.143)'
        />
        <g transform='translate(6.521 56.662)'>
          <path
            fill='#fedc60'
            d='M549.969,316.388l-2.437-.788-7.31,4.228-2.723.86,1.075,1h2.652Z'
            transform='translate(-528.685 -315.6)'
          />
          <g transform='translate(1.218 3.01)'>
            <path
              fill='#fedc60'
              d='M534.926,322.237,526.9,319.8l8.17,1.792Z'
              transform='translate(-526.9 -319.8)'
            />
          </g>
          <g transform='translate(0 5.375)'>
            <path
              fill='#fedc60'
              d='M534.875,323.817H525.2l9.674-.717Z'
              transform='translate(-525.2 -323.1)'
            />
          </g>
          <g transform='translate(2.15 5.59)'>
            <path
              fill='#fedc60'
              d='M536.37,324.045l-8.169,2.938,7.955-3.583Z'
              transform='translate(-528.2 -323.4)'
            />
          </g>
          <g transform='translate(10.606 6.091)'>
            <path
              fill='#fedc60'
              d='M540.717,324.1,540,329.69l.072-5.59Z'
              transform='translate(-540 -324.1)'
            />
          </g>
        </g>
        <g transform='translate(19.349 57.737)'>
          <path
            fill='#fedc60'
            d='M559.3,317.1l-2.437.717-2.867,10.463-2.293,3.081,1.433.143,2.365-1.218Z'
            transform='translate(-545.537 -317.1)'
          />
          <g transform='translate(0 13.759)'>
            <path
              fill='#fedc60'
              d='M549.621,336.945l-6.521.358,6.45-1Z'
              transform='translate(-543.1 -336.3)'
            />
          </g>
          <g transform='translate(0 13.974)'>
            <path
              fill='#fedc60'
              d='M550.41,337.173l-7.31,3.511,7.023-4.085Z'
              transform='translate(-543.1 -336.6)'
            />
          </g>
          <g transform='translate(4.73 13.759)'>
            <path
              fill='#fedc60'
              d='M553.14,336.658l-3.44,4.73,2.867-5.088Z'
              transform='translate(-549.7 -336.3)'
            />
          </g>
          <g transform='translate(8.456 13.401)'>
            <path
              fill='#fedc60'
              d='M555.473,335.8l1.935,3.8-2.508-3.44Z'
              transform='translate(-554.9 -335.8)'
            />
          </g>
        </g>
        <path
          fill='#fedc60'
          d='M520.185,246.3s-3.583,1.362-4.085,4.3c4.013.573,5.8.358,5.16,0'
          transform='translate(-516.1 -239.301)'
        />
        <path
          fill='#b8642a'
          d='M541.963,262.086c-7.668-3.082-2.723-25.3-18.561-20.639-5.518,3.87,2.222,7.453.5,17.414a28.132,28.132,0,0,0,.5,14.834s6.88-.072,6.951,0,8.6-5.661,8.6-5.661Z'
          transform='translate(-517.598 -237.744)'
        />
        <path
          fill='#802a2a'
          d='M567.008,274.185l-22.86-3.44a4.243,4.243,0,0,1-1.362-.358s-1.648,1.362-4.013-.287c1,.143-.5,2.007.358,3.941-.86.143-1.5.358-3.225-1.792a34.369,34.369,0,0,1-.717,4.8,4.134,4.134,0,0,1-2.651-2.508s-1.147,3.3-1.362,4.3a8.062,8.062,0,0,1-2.867-3.153,39.736,39.736,0,0,1-3.01,6.235c6.163,20.854,33.682,35.33,46.509,8.6l3.87-1.72-3.942-15.837Z'
          transform='translate(-518.707 -246.045)'
        />
        <path
          fill='#b8642a'
          d='M588.371,286.557c10.32,1.218,19.277-13.4,8.528-30.457-3.942,12.4-12.9,14.046-12.9,14.046a15.059,15.059,0,0,1,4.73,1.147,21.484,21.484,0,0,0-4.73,1.935c.86.358,2.58.358,3.3.645-.932.932-2.15,1.147-3.3,2.365,1.5.717,2.222.287,3.153.645-.645,1-1.792,1.5-1.648,1.863,1.218.5,2.938.645,3.44.86-1.075.86-2.508,1.362-2.221,1.577,1.433,1,3.727,2.15,4.156,1.577-.358,1-2.723,2.365-3.368,2.15A1.675,1.675,0,0,1,588.371,286.557Z'
          transform='translate(-535.341 -242.078)'
        />
        <circle
          fill='#4c2f21'
          cx='1.505'
          cy='1.505'
          r='1.505'
          transform='translate(6.163 5.423)'
        />
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(Volailles)
