import React from 'react'

const Logo = ({ width, height, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='-1603.5 1917.006 135.5 32.355'
  >
    <g transform='translate(-1616.5 1899)'>
      <path
        fill='#059248'
        d='M22.116,6.379,19.769,7.692a.929.929,0,0,0-.278.278l-.278.6c-.04.119-.08.2-.119.318-.04.239-.08.517-.119.8L17.7,9.92a20.5,20.5,0,0,1,.716-2.387L18.1,3.117a.512.512,0,0,1,.438-.517.478.478,0,0,1,.477.438l.278,3.819L21.8,5.464l.517-.04-.2.955'
        transform='translate(2.342 16.441)'
      />
      <g transform='translate(31.797 29.399)'>
        <path
          d='M53.754,48.184a.563.563,0,0,1-.4.676.572.572,0,0,1-.676-.358s-.239-.835-.557-1.989H48.86C48.542,47.667,48.3,48.5,48.3,48.5a.541.541,0,0,1-1.034-.318L50.014,38.4a.535.535,0,0,1,1.034,0ZM51.884,45.4c-.438-1.671-.995-3.62-1.353-4.853-.318,1.233-.875,3.183-1.353,4.853Z'
          transform='translate(-47.249 -34.275)'
        />
        <path
          d='M67.1,46.254V40.525a2.555,2.555,0,0,1,1.91-2.467,2.594,2.594,0,0,1,2.9,1.193.624.624,0,0,1-.2.756.579.579,0,0,1-.756-.2,1.453,1.453,0,0,0-1.671-.676,1.492,1.492,0,0,0-1.114,1.432v5.729a1.449,1.449,0,0,0,1.114,1.432,1.506,1.506,0,0,0,1.671-.676.553.553,0,1,1,.955.557,2.594,2.594,0,0,1-2.9,1.193A2.638,2.638,0,0,1,67.1,46.254Z'
          transform='translate(-59.203 -34.254)'
        />
        <path
          d='M88.455,37.94a.544.544,0,0,1,.557.557v9.787a.544.544,0,0,1-.557.557.569.569,0,0,1-.557-.557V43.748H84.914v4.535a.544.544,0,0,1-.557.557.569.569,0,0,1-.557-.557V38.457a.557.557,0,1,1,1.114,0v4.177H87.9V38.457A.536.536,0,0,1,88.455,37.94Z'
          transform='translate(-69.259 -34.215)'
        />
        <path
          d='M102.6,38.657a.544.544,0,0,1,.557-.557h3.382a.557.557,0,1,1,0,1.114h-2.825v3.58h2.506a.557.557,0,1,1,0,1.114h-2.506v3.9h2.825a.557.557,0,1,1,0,1.114h-3.382a.544.544,0,0,1-.557-.557Z'
          transform='translate(-80.58 -34.335)'
        />
        <path
          d='M121.313,38.1a.557.557,0,1,1,0,1.114h-1.671V48.4a.544.544,0,0,1-.557.557.569.569,0,0,1-.557-.557v-9.19h-1.671a.569.569,0,0,1-.557-.557.544.544,0,0,1,.557-.557Z'
          transform='translate(-88.829 -34.335)'
        />
        <path
          d='M134.24,38.657a.544.544,0,0,1,.557-.557h3.382a.557.557,0,0,1,0,1.114h-2.864v3.58h2.506a.557.557,0,0,1,0,1.114h-2.506v3.9h2.825a.557.557,0,1,1,0,1.114h-3.382a.544.544,0,0,1-.557-.557V38.657Z'
          transform='translate(-99.608 -34.335)'
        />
        <path
          d='M153.48,43.988l1.552,4.177a.529.529,0,0,1-.995.358l-1.631-4.376h-1.392v4.177a.544.544,0,0,1-.557.557.569.569,0,0,1-.557-.557V38.657a.544.544,0,0,1,.557-.557h2.029a2.572,2.572,0,0,1,2.586,2.546v.995A2.59,2.59,0,0,1,153.48,43.988Zm.477-2.347v-.955a1.5,1.5,0,0,0-1.472-1.472h-1.472v3.9h1.472A1.469,1.469,0,0,0,153.958,41.641Z'
          transform='translate(-109.063 -34.335)'
        />
        <path
          d='M180.254,42.546a.563.563,0,0,1-.4.676.572.572,0,0,1-.676-.358s-.239-.835-.557-1.989H175.36c-.318,1.154-.557,1.989-.557,1.989a.541.541,0,1,1-1.034-.318l2.705-9.787a.535.535,0,0,1,1.034,0Zm-3.7-13.765,1.671,1.512a.535.535,0,1,1-.716.8l-1.671-1.512a.535.535,0,1,1,.716-.8Zm1.83,10.98c-.438-1.671-.995-3.62-1.353-4.853-.318,1.233-.875,3.183-1.353,4.853Z'
          transform='translate(-123.423 -28.637)'
        />
        <path
          d='M202.2,48.2V38.457a.544.544,0,0,1,.557-.557.569.569,0,0,1,.557.557v9.19h2.825a.569.569,0,0,1,.557.557.544.544,0,0,1-.557.557h-3.382A.6.6,0,0,1,202.2,48.2Z'
          transform='translate(-140.556 -34.215)'
        />
        <path
          d='M221.554,48.184a.563.563,0,0,1-.4.676.572.572,0,0,1-.676-.358s-.239-.835-.557-1.989H216.66c-.318,1.154-.557,1.989-.557,1.989a.541.541,0,1,1-1.034-.318l2.745-9.787a.535.535,0,0,1,1.034,0Zm-1.91-2.785c-.438-1.671-.995-3.62-1.353-4.853-.318,1.233-.875,3.183-1.353,4.853Z'
          transform='translate(-148.293 -34.275)'
        />
        <path
          d='M246.436,46.172A1.635,1.635,0,0,0,246,44.939l-2.705-2.785a2.44,2.44,0,0,1-.716-1.989,2.58,2.58,0,0,1,.995-1.87,2.55,2.55,0,0,1,2.069-.477,2.6,2.6,0,0,1,1.711,1.233.542.542,0,0,1-.2.756.521.521,0,0,1-.716-.2,1.527,1.527,0,0,0-.995-.716,1.4,1.4,0,0,0-1.193.278,1.374,1.374,0,0,0-.557,1.074,1.525,1.525,0,0,0,.4,1.154l2.705,2.785a2.753,2.753,0,0,1,.756,2.029,2.787,2.787,0,0,1-1.034,1.91,2.675,2.675,0,0,1-2.108.477,2.768,2.768,0,0,1-1.79-1.273.611.611,0,0,1,.2-.756.542.542,0,0,1,.756.2,1.538,1.538,0,0,0,2.268.477A1.292,1.292,0,0,0,246.436,46.172Z'
          transform='translate(-164.861 -34.132)'
        />
        <path
          d='M259.2,46.215V40.486a2.581,2.581,0,0,1,2.586-2.586,2.547,2.547,0,0,1,2.546,2.586v5.729a2.566,2.566,0,1,1-5.132,0Zm1.074-5.729v5.729a1.469,1.469,0,0,0,1.472,1.472,1.5,1.5,0,0,0,1.472-1.472V40.486a1.5,1.5,0,0,0-1.472-1.472A1.469,1.469,0,0,0,260.274,40.486Z'
          transform='translate(-174.88 -34.215)'
        />
        <path
          d='M279.486,47.726a1.5,1.5,0,0,0,1.472-1.472v-7.8a.544.544,0,0,1,.557-.557.569.569,0,0,1,.557.557v7.758a2.586,2.586,0,0,1-5.172,0V38.457a.544.544,0,0,1,.557-.557.569.569,0,0,1,.557.557v7.758A1.5,1.5,0,0,0,279.486,47.726Z'
          transform='translate(-185.538 -34.215)'
        />
        <path
          d='M298.78,43.988l1.552,4.177a.529.529,0,0,1-.995.358l-1.631-4.376h-1.392v4.177a.544.544,0,0,1-.557.557.569.569,0,0,1-.557-.557V38.657a.544.544,0,0,1,.557-.557h2.029a2.573,2.573,0,0,1,2.586,2.546v.995A2.472,2.472,0,0,1,298.78,43.988Zm.517-2.347v-.955a1.5,1.5,0,0,0-1.472-1.472h-1.512v3.9h1.472A1.5,1.5,0,0,0,299.3,41.641Z'
          transform='translate(-196.558 -34.335)'
        />
        <path
          d='M312.5,46.254V40.525a2.555,2.555,0,0,1,1.91-2.467,2.594,2.594,0,0,1,2.9,1.193.624.624,0,0,1-.2.756.579.579,0,0,1-.756-.2,1.453,1.453,0,0,0-1.671-.676,1.492,1.492,0,0,0-1.114,1.432v5.729a1.449,1.449,0,0,0,1.114,1.432,1.506,1.506,0,0,0,1.671-.676.553.553,0,1,1,.955.557,2.594,2.594,0,0,1-2.9,1.193A2.638,2.638,0,0,1,312.5,46.254Z'
          transform='translate(-206.976 -34.254)'
        />
        <path
          d='M329.24,38.657a.544.544,0,0,1,.557-.557h3.382a.557.557,0,0,1,0,1.114h-2.864v3.58h2.506a.557.557,0,1,1,0,1.114h-2.506v3.9h2.825a.557.557,0,0,1,0,1.114h-3.382a.544.544,0,0,1-.557-.557V38.657Z'
          transform='translate(-217.032 -34.335)'
        />
      </g>
      <line
        fill='none'
        stroke='#000'
        strokeWidth='0.898px'
        strokeMiterlimit='10'
        x2='111.551'
        transform='translate(36.79 26.838)'
      />
      <line
        fill='none'
        stroke='#000'
        strokeWidth='0.898px'
        strokeMiterlimit='10'
        x2='122.332'
        transform='translate(26.009 49.912)'
      />
      <path
        fill='#eb1a29'
        d='M8.075,17.8A7.964,7.964,0,0,0,0,25.676c-.04,4.376,4.1,11.5,7.758,15.277,3.66-3.58,8.116-10.7,8.155-15.117A7.922,7.922,0,0,0,8.075,17.8ZM7.956,31.723A6.127,6.127,0,1,1,14.083,25.6,6.129,6.129,0,0,1,7.956,31.723Z'
        transform='translate(13.001 7.288)'
      />
      <path
        fill='#059248'
        d='M27.666,4.415c-.2-1.79,2.467-6.166,8.593-3.66C34.27,2.426,32.8,7.24,27.666,5.012,27.467,4.535,27.666,4.415,27.666,4.415Z'
        transform='translate(-3.606 18.007)'
      />
    </g>
  </svg>
)

export default Logo
