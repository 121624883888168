import React from 'react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import TopBarSearch from './TopBarSearch'

const gqlRegions = gql`
  {
    regions {
      name
      squareBounds
    }
  }
`

const TopBarSearchContainer = ({ data: { regions, loading }, ...props }) => {
  let regionsPacked = loading
    ? []
    : (regions || []).map(region => ({
        value: region.squareBounds,
        label: region.name
      }))

  return <TopBarSearch regions={regionsPacked} {...props} />
}

export default graphql(gqlRegions)(TopBarSearchContainer)
