import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  }
}

const Poissons = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-3729.178 1232.485 97.745 97.745'
  >
    <g transform='translate(-3914.5 1075.543)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(185.822 157.442)'
      />
      <g transform='translate(196.992 182.16)'>
        <path
          fill='#397cac'
          d='M312.906,264.962l-6.306-7.883c6.593-3.368,21.642-3.081,21.642-3.081l4.515,5.661'
          transform='translate(-283.874 -253.992)'
        />
        <path
          fill='#35caf0'
          d='M296.3,275.171c2.078,1.147,9.46-6.593,13.258-7.883,0,0,14.046-6.235,20.926-5.231-1,4.658,22.359,25.44-6.808,37.91-2.938,1.218-14.333-5.3-14.333-5.3L294.8,287.139Z'
          transform='translate(-280.53 -256.246)'
        />
        <path
          fill='#397cac'
          d='M289.8,290.049s3.655-7.023.86-12.254c-2.365-2.795-9.388-7.453-14.834-6.45-5.518,2.437,15.694,7.095.5,22.5-4.945,5.59,10.534,1.29,13.473-3.8'
          transform='translate(-274.887 -258.87)'
        />
        <path
          fill='#0071bc'
          d='M317.3,308.7'
          transform='translate(-286.906 -269.494)'
        />
        <path
          fill='#397cac'
          d='M326.981,313.043c-4.586,6.163-19.564,4.3-20.281,0l7.6-4.443C314.3,308.672,322.251,313.473,326.981,313.043Z'
          transform='translate(-283.902 -269.466)'
        />
        <path
          fill='#35caf0'
          d='M339.3,261.9s-16.984,12.828-5.8,37.981c15.479,1.29,30.027-8.456,30.528-14.619C364.524,279.6,356.282,262.832,339.3,261.9Z'
          transform='translate(-290.415 -256.233)'
        />
        <g transform='translate(43.58 12.204)'>
          <path
            fill='#397cac'
            d='M338.638,271.149a2.826,2.826,0,0,0-1.433,1.075,3.267,3.267,0,0,0-.43,1.577,3.069,3.069,0,0,0,.43,1.577,2.666,2.666,0,0,0,1.5,1,2.253,2.253,0,0,1-2.15-.5,2.76,2.76,0,0,1-.86-2.15,3.034,3.034,0,0,1,.86-2.15A1.993,1.993,0,0,1,338.638,271.149Z'
            transform='translate(-335.7 -271.021)'
          />
        </g>
        <g transform='translate(43.58 17.507)'>
          <path
            fill='#397cac'
            d='M338.638,278.549a2.826,2.826,0,0,0-1.433,1.075,3.267,3.267,0,0,0-.43,1.577,3.069,3.069,0,0,0,.43,1.577,2.667,2.667,0,0,0,1.5,1,2.253,2.253,0,0,1-2.15-.5,2.76,2.76,0,0,1-.86-2.15,3.034,3.034,0,0,1,.86-2.15A1.993,1.993,0,0,1,338.638,278.549Z'
            transform='translate(-335.7 -278.421)'
          />
        </g>
        <g transform='translate(43.58 22.81)'>
          <path
            fill='#397cac'
            d='M338.638,285.949a2.825,2.825,0,0,0-1.433,1.075,3.267,3.267,0,0,0-.43,1.577,3.069,3.069,0,0,0,.43,1.577,2.667,2.667,0,0,0,1.5,1,2.253,2.253,0,0,1-2.15-.5,2.76,2.76,0,0,1-.86-2.15,3.034,3.034,0,0,1,.86-2.15A1.993,1.993,0,0,1,338.638,285.949Z'
            transform='translate(-335.7 -285.821)'
          />
        </g>
        <g transform='translate(43.58 28.113)'>
          <path
            fill='#397cac'
            d='M338.638,293.349a2.826,2.826,0,0,0-1.433,1.075,3.267,3.267,0,0,0-.43,1.577,3.069,3.069,0,0,0,.43,1.577,2.667,2.667,0,0,0,1.5,1,2.253,2.253,0,0,1-2.15-.5,2.76,2.76,0,0,1-.86-2.15,3.034,3.034,0,0,1,.86-2.15A1.993,1.993,0,0,1,338.638,293.349Z'
            transform='translate(-335.7 -293.221)'
          />
        </g>
        <g transform='translate(41.359 15.285)'>
          <path
            fill='#397cac'
            d='M335.538,275.449a2.826,2.826,0,0,0-1.433,1.075,3.267,3.267,0,0,0-.43,1.577,3.069,3.069,0,0,0,.43,1.577,2.667,2.667,0,0,0,1.5,1,2.253,2.253,0,0,1-2.15-.5,2.76,2.76,0,0,1-.86-2.15,3.034,3.034,0,0,1,.86-2.15A2.055,2.055,0,0,1,335.538,275.449Z'
            transform='translate(-332.6 -275.321)'
          />
        </g>
        <g transform='translate(41.359 20.588)'>
          <path
            fill='#397cac'
            d='M335.538,282.849a2.826,2.826,0,0,0-1.433,1.075,3.267,3.267,0,0,0-.43,1.577,3.069,3.069,0,0,0,.43,1.577,2.667,2.667,0,0,0,1.5,1,2.253,2.253,0,0,1-2.15-.5,2.76,2.76,0,0,1-.86-2.15,3.034,3.034,0,0,1,.86-2.15A2.055,2.055,0,0,1,335.538,282.849Z'
            transform='translate(-332.6 -282.721)'
          />
        </g>
        <g transform='translate(41.359 25.891)'>
          <path
            fill='#397cac'
            d='M335.538,290.249a2.826,2.826,0,0,0-1.433,1.075,3.267,3.267,0,0,0-.43,1.577,3.069,3.069,0,0,0,.43,1.577,2.667,2.667,0,0,0,1.5,1,2.253,2.253,0,0,1-2.15-.5,2.76,2.76,0,0,1-.86-2.15,3.034,3.034,0,0,1,.86-2.15A2.055,2.055,0,0,1,335.538,290.249Z'
            transform='translate(-332.6 -290.121)'
          />
        </g>
        <g transform='translate(28.674 13.135)'>
          <path
            fill='#397cac'
            d='M317.838,272.449a2.826,2.826,0,0,0-1.433,1.075,3.267,3.267,0,0,0-.43,1.577,3.069,3.069,0,0,0,.43,1.577,2.667,2.667,0,0,0,1.5,1,2.253,2.253,0,0,1-2.15-.5,2.76,2.76,0,0,1-.86-2.15,3.034,3.034,0,0,1,.86-2.15A2.055,2.055,0,0,1,317.838,272.449Z'
            transform='translate(-314.9 -272.321)'
          />
        </g>
        <g transform='translate(28.674 18.438)'>
          <path
            fill='#397cac'
            d='M317.838,279.849a2.826,2.826,0,0,0-1.433,1.075,3.267,3.267,0,0,0-.43,1.577,3.069,3.069,0,0,0,.43,1.577,2.667,2.667,0,0,0,1.5,1,2.253,2.253,0,0,1-2.15-.5,2.76,2.76,0,0,1-.86-2.15,3.034,3.034,0,0,1,.86-2.15A2.055,2.055,0,0,1,317.838,279.849Z'
            transform='translate(-314.9 -279.721)'
          />
        </g>
        <g transform='translate(31.828 31.668)'>
          <path
            fill='#397cac'
            d='M322.238,298.267a2.826,2.826,0,0,0-1.433,1.075,3.267,3.267,0,0,0-.43,1.577,3.069,3.069,0,0,0,.43,1.577,2.667,2.667,0,0,0,1.5,1,2.253,2.253,0,0,1-2.15-.5,2.76,2.76,0,0,1-.86-2.15,3.034,3.034,0,0,1,.86-2.15A2.444,2.444,0,0,1,322.238,298.267Z'
            transform='translate(-319.3 -298.182)'
          />
        </g>
        <g transform='translate(22.296 27.225)'>
          <path
            fill='#397cac'
            d='M308.938,292.067a2.826,2.826,0,0,0-1.433,1.075,3.267,3.267,0,0,0-.43,1.577,3.069,3.069,0,0,0,.43,1.577,2.667,2.667,0,0,0,1.5,1,2.253,2.253,0,0,1-2.15-.5,3.117,3.117,0,0,1,0-4.3A2.444,2.444,0,0,1,308.938,292.067Z'
            transform='translate(-306 -291.982)'
          />
        </g>
        <g transform='translate(26.095 16.302)'>
          <path
            fill='#397cac'
            d='M314.238,276.854a2.826,2.826,0,0,0-1.433,1.075,3.267,3.267,0,0,0-.43,1.577,3.069,3.069,0,0,0,.43,1.577,2.666,2.666,0,0,0,1.5,1,2.253,2.253,0,0,1-2.15-.5,2.76,2.76,0,0,1-.86-2.15,3.034,3.034,0,0,1,.86-2.15A2.229,2.229,0,0,1,314.238,276.854Z'
            transform='translate(-311.3 -276.739)'
          />
        </g>
        <g transform='translate(29.176 28.543)'>
          <path
            fill='#397cac'
            d='M318.538,293.949a2.826,2.826,0,0,0-1.433,1.075,3.267,3.267,0,0,0-.43,1.577,3.069,3.069,0,0,0,.43,1.577,2.667,2.667,0,0,0,1.5,1,2.253,2.253,0,0,1-2.15-.5,2.76,2.76,0,0,1-.86-2.15,3.034,3.034,0,0,1,.86-2.15A2.055,2.055,0,0,1,318.538,293.949Z'
            transform='translate(-315.6 -293.821)'
          />
        </g>
        <circle
          fill='#1b1464'
          cx='2.508'
          cy='2.508'
          r='2.508'
          transform='translate(56.336 18.352)'
        />
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(Poissons)
