import React from 'react'

let L, markerIcon
if (typeof window !== 'undefined') {
  L = require('leaflet')
  markerIcon = require('public/img/marqueur2als.svg')
  L.Icon.Default.imagePath = '/img/leaflet/'
} else {
  L = null
  markerIcon = null
}

export const boundsFranceStringified = JSON.stringify([
  { lng: -4.959554672241212, lat: 51.22715165588078 },
  { lng: 10.57511329650879, lat: 51.22715165588078 },
  { lng: 10.57511329650879, lat: 41.20300415233575 },
  { lng: -4.959554672241212, lat: 41.20300415233575 }
])

export const categoriesOptions = [
  { value: 'all', label: 'Toutes les catégories' },
  { value: 'Produits fermiers', label: 'Produits fermiers' },
  { value: 'Légumes', label: 'Légumes' },
  { value: 'Fruits', label: 'Fruits' },
  { value: 'Miel', label: 'Miel' },
  { value: 'Œufs', label: 'Œufs' },
  {
    value: 'Fromages et produits laitiers',
    label: 'Fromages et produits laitiers'
  },
  { value: 'Vins et spiritueux', label: 'Vins et spiritueux' },
  {
    value: 'Plantes et produits du jardin',
    label: 'Plantes et produits du jardin'
  },
  { value: 'Poissons', label: 'Poissons' },
  { value: 'Volailles et lapins', label: 'Volailles et lapins' },
  { value: 'Bovins', label: 'Bovins' },
  { value: 'Moutons, chèvres', label: 'Moutons et chèvres' },
  { value: 'Porcs', label: 'Porcs' },
  { value: 'Gibiers', label: 'Gibiers' },
  { value: 'Autres', label: 'Autres' }
]

export const categoriesLinkValues = [
  {
    value: categoriesOptions[1].value,
    urlValue: 'produits-fermiers',
    titleValue: 'Vente de produits fermiers'
  },
  {
    value: categoriesOptions[2].value,
    urlValue: 'legumes',
    titleValue: 'Maraîchers'
  },
  {
    value: categoriesOptions[3].value,
    urlValue: 'fruits',
    titleValue: 'Arboriculteurs'
  },
  {
    value: categoriesOptions[4].value,
    urlValue: 'apiculture',
    titleValue: 'Apiculteurs'
  },
  {
    value: categoriesOptions[5].value,
    urlValue: 'oeufs',
    titleValue: "Producteurs d'œufs"
  },
  {
    value: categoriesOptions[6].value,
    urlValue: 'fromages',
    titleValue: 'Producteurs de fromages'
  },
  {
    value: categoriesOptions[7].value,
    urlValue: 'vin',
    titleValue: 'Viticulteurs'
  },
  {
    value: categoriesOptions[8].value,
    urlValue: 'horticulture',
    titleValue: 'Horticulteurs'
  },
  {
    value: categoriesOptions[9].value,
    urlValue: 'poissons',
    titleValue: 'Pisciculteurs'
  },
  {
    value: categoriesOptions[10].value,
    urlValue: 'volailles-lapins',
    titleValue: 'Eleveurs de volailles, lapins'
  },
  {
    value: categoriesOptions[11].value,
    urlValue: 'bovins',
    titleValue: 'Eleveurs de bovins'
  },
  {
    value: categoriesOptions[12].value,
    urlValue: 'moutons-chevres',
    titleValue: 'Eleveurs de moutons, chèvres'
  },
  {
    value: categoriesOptions[13].value,
    urlValue: 'porc',
    titleValue: 'Eleveurs de porc'
  },
  {
    value: categoriesOptions[14].value,
    urlValue: 'gibier',
    titleValue: 'Eleveurs de gibier'
  },
  {
    value: categoriesOptions[15].value,
    urlValue: 'autres',
    titleValue: 'Autres'
  }
]

export const professions = [
  'Vente de produits fermiers',
  'Maraîcher',
  'Arboriculteur',
  'Apiculteur',
  "Producteur d'oeufs",
  'Producteur de fromages',
  'Viticulteur',
  'Horticulteur',
  'Pisciculteurs',
  'Eleveur de volailles, lapins',
  'Eleveur de bovins',
  'Eleveur de moutons, chèvres',
  'Eleveur de porc',
  'Eleveur de gibier'
]

export const icon =
  typeof window !== 'undefined' && L
    ? L.icon({
        iconUrl: markerIcon,
        iconSize: [40, 60],
        iconAnchor: [16, 62],
        popupAnchor: [0, -58]
      })
    : null

export const civiliteOptions = [
  { value: '', label: 'Sélectionnez votre civilité' },
  { value: 'monsieur', label: 'Monsieur' },
  { value: 'madame', label: 'Madame' }
]

export const ClickioResetConsent = () => {
  const text =
    'Changer les préférences relatives à la collecte des données personnelles'

  return typeof window !== 'undefined' ? (
    <a
      href='#'
      onClick={() => {
        // eslint-disable-next-line no-undef
        if (window.__lxG__consent__ !== undefined) {
          window.__lxG__consent__.showConsent()
        } else {
          alert(
            'This function only for users from European Economic Area (EEA)'
          )
        }
        return false
      }}
    >
      {text}
    </a>
  ) : (
    <a href='#'>{text}</a>
  )
}

export const sortPhotosByPosition = (a, b) => {
  const na = a.position
  const nb = b.position

  let comparison = 0
  if (na > nb) {
    comparison = 1
  } else if (na < nb) {
    comparison = -1
  }
  return comparison
}

export const listCookiesPreferences = {
  cookiesConsentStatisitcs:
    "Autoriser la collecte de vos données de navigation pour l'usage de statistiques",
  cookiesConsentPersonalizedAds:
    "Nous autoriser à analyser la façon dont vous utilisez le site. Les informations récoltées sont anonymes et permettent d'améliorer Acheter à la Source."
}

export const lienRGPD =
  'https://storage.sbg.cloud.ovh.net/v1/AUTH_dd430e2a89404e97bf6a772afa5dd938/2als-production-public/Politique_utilisation_donne%CC%81es_personnelles.pdf'

export const commonPub = '//s.clickiocdn.com/t/common_258.js'

export const responsiveAd = `((__lxGc__=window.__lxGc__||{'s':{},'b':0})['s']['_206175']=__lxGc__['s']['_206175']||{'b':{}})['b']['_633308']={'i':__lxGc__.b++};`
export const smBannerAd = `((__lxGc__=window.__lxGc__||{'s':{},'b':0})['s']['_206175']=__lxGc__['s']['_206175']||{'b':{}})['b']['_653394']={'i':__lxGc__.b++};`
export const lgBannerAd = `((__lxGc__=window.__lxGc__||{'s':{},'b':0})['s']['_206175']=__lxGc__['s']['_206175']||{'b':{}})['b']['_653388']={'i':__lxGc__.b++};`
export const mapAd = `((__lxGc__=window.__lxGc__||{'s':{},'b':0})['s']['_206175']=__lxGc__['s']['_206175']||{'b':{}})['b']['_647718']={'i':__lxGc__.b++};`

export const mapAdLgSmLimitSize = 1094
