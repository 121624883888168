const env =
  typeof window !== 'undefined' && window.__ENV__ ? window.__ENV__ : {}

const {
  API_ENDPOINT = process.env.API_ENDPOINT,
  URL_TILES = process.env.URL_TILES,
  PUBLIC_URL = process.env.PUBLIC_URL,
  PORT = process.env.PORT,
  NODE_ENV = process.env.NODE_ENV
} = env

export { API_ENDPOINT, URL_TILES, PUBLIC_URL, PORT, NODE_ENV }
