import React, { Component } from 'react'
import NewPassword from './NewPassword'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { FORM_ERROR } from 'final-form'

const query = gql`
  query VerifyAuthentificationPassword($idUser: ID!, $token: String!) {
    verifyAuthentificationPassword(idUser: $idUser, token: $token)
  }
`

const mutation = gql`
  mutation(
    $password: String!
    $passwordConfirmed: String!
    $idUser: ID!
    $token: String!
  ) {
    changePasswordByRecover(
      password: $password
      passwordConfirmed: $passwordConfirmed
      idUser: $idUser
      token: $token
    )
  }
`

class NewPasswordContainer extends Component {
  changePassword = values => {
    const {
      match: {
        params: { id, token }
      }
    } = this.props

    return this.props
      .changePassword({
        variables: {
          password: values.password,
          passwordConfirmed: values.confirmPassword,
          idUser: id,
          token
        }
      })
      .then(({ data: { changePasswordByRecover } }) => {
        if (changePasswordByRecover) {
          return { [FORM_ERROR]: changePasswordByRecover }
        }
        return null
      })
  }

  render() {
    const {
      verifyLink: { loading, verifyAuthentificationPassword }
    } = this.props

    return (
      <NewPassword
        loading={loading}
        verified={verifyAuthentificationPassword}
        changePassword={this.changePassword}
      />
    )
  }
}

export default compose(
  graphql(query, {
    name: 'verifyLink',
    options: ({
      match: {
        params: { id, token }
      }
    }) => ({
      variables: {
        idUser: id,
        token
      }
    })
  }),
  graphql(mutation, {
    name: 'changePassword'
  })
)(NewPasswordContainer)
