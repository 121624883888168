// eslint-disable-next-line camelcase
import { preg_quote } from 'locutus/php/pcre'
import React, { Component } from 'react'
import routes from 'src/routing/routes'
import injectSheet from 'react-jss'
import { Alert } from 'react-bootstrap'
import Marqueur from 'src/components/svg/Marqueur'
import Button from 'src/components/button/ButtonStyle'
import { withRouter } from 'react-router'
import { boundsFranceStringified, categoriesOptions } from 'src/config/dataConf'
import Async from 'react-select/async'
import Select from 'react-select'
import {
  encodeBase64,
  fetchAddressAutocomplete,
  getUrlValueFromValue
} from 'src/utils'
import responsiveComponent from 'src/hoc/responsiveComponent'

const styles = theme => {
  // eslint-disable-next-line
  let isSafari =
    typeof window !== 'undefined' &&
    (/constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]'
      })(
        !window['safari'] ||
          (typeof safari !== 'undefined' && safari.pushNotification)
      ))

  return {
    /// //////// Pour les deux formats //////////
    indication: {
      color: theme.accueil.titreSearch,
      fontSize: theme.taillePetitTexte,
      fontWeight: theme.bold,
      display: 'block'
    },
    alert: {
      marginTop: 20
    },

    /// ///////// Format Mobile //////////////

    div: {
      textAlign: 'center',
      margin: '50px 20px 0px 20px',
      backgroundColor: 'white',
      padding: '15px 10px',
      fontFamily: `${theme.fontGlobal} !important`,
      fontSize: `14px !important`
    },
    divinput: {
      padding: '10px 10%'
    },
    marqueur: {
      padding: 6,
      position: 'absolute',
      top: 22,
      right: 0
    },
    addressGroup: {
      position: 'relative',
      marginTop: 15
    },
    categories: {
      '& .categories__control': {
        width: '100%',
        marginRight: 15,
        border: theme.borderInput,
        borderRadius: 0,
        '& .categories__value-container': {
          ...(typeof window === 'undefined'
            ? {
                lineHeight: '36px',
                textAlign: 'left',
                paddingLeft: 10
              }
            : {}),
          '& .css-gj7qu5-dummyInput': {
            ...(typeof window === 'undefined' ? { display: 'none' } : {})
          }
        },
        '& .categories__indicators': {
          '& .css-19bqh2r': {
            ...(typeof window === 'undefined' ? { display: 'none' } : {})
          }
        },
        '&:hover': {
          cursor: 'pointer'
        }
      },
      '& .categories__menu': {
        zIndex: 3
      }
    },
    autocomplete: {
      '& .address__control': {
        '& .address__single-value': {
          width: '80%'
        },
        border: '1px solid #95989A',
        borderRadius: 0,
        '& .address__placeholder': {
          textAlign: 'left',
          ...(typeof window === 'undefined' ? { paddingLeft: 8 } : {})
        },
        ...(typeof window === 'undefined' ? { lineHeight: '36px' } : {}),
        '& .css-1g6gooi': {
          ...(typeof window === 'undefined' ? { display: 'none' } : {})
        },
        '&:hover': {
          cursor: 'pointer'
        }
      },
      '& .address__menu': {
        zIndex: 3
      }
    },

    /// /////////// Autre Format //////////////

    divAutreFormat: {
      margin: '50px auto 0px auto',
      width: '668px',
      backgroundColor: 'white',
      fontFamily: `${theme.fontGlobal} !important`,
      fontSize: `14px !important`
    },
    formAutreFormat: {
      padding: 15
    },
    categoryGroup: {
      display: 'inline-block'
    },
    addressGroupAF: {
      display: 'inline-block',
      marginRight: '15px',
      width: '260px',
      position: 'relative',
      verticalAlign: 'top'
    },
    marqueurAF: {
      position: 'absolute',
      top: 18,
      right: 0,
      padding: 8
    },
    divButtonAutreFormat: {
      ...(isSafari ? { verticalAlign: 'text-bottom' } : {}),
      display: 'inline-block'
    },
    categoriesAF: {
      '& .categories__control': {
        width: 260,
        marginRight: 15,
        border: theme.borderInput,
        borderRadius: 0,
        '& .categories__value-container': {
          ...(typeof window === 'undefined'
            ? {
                lineHeight: '36px',
                paddingRight: 35
              }
            : {}),
          '& .css-gj7qu5-dummyInput': {
            ...(typeof window === 'undefined' ? { display: 'none' } : {})
          }
        },
        '& .categories__indicators': {
          '& .css-19bqh2r': {
            ...(typeof window === 'undefined' ? { display: 'none' } : {})
          }
        },
        '&:hover': {
          cursor: 'pointer'
        }
      },
      '& .categories__menu': {
        zIndex: 3
      }
    },
    autocompleteAF: {
      '& .addressAF__control': {
        width: 260,
        '& .addressAF__single-value': {
          width: 230
        },
        '& .addressAF__input input': {
          maxWidth: 225
        },
        '& .addressAF__placeholder': {
          paddingRight: 40
        },
        border: theme.borderInput,
        borderRadius: 0,
        ...(typeof window === 'undefined' ? { lineHeight: '36px' } : {}),
        '& .css-1g6gooi': {
          ...(typeof window === 'undefined' ? { display: 'none' } : {})
        },
        '&:hover': {
          cursor: 'pointer'
        }
      },
      '& .addressAF__menu': {
        zIndex: 3
      }
    },
    submitButton: {
      padding: 5
    }
  }
}

class SearchAccueil extends Component {
  constructor(props) {
    super(props)

    this.state = {
      query: '',
      category: categoriesOptions[0],
      showAlert: false
    }
  }

  optionsAutocomplete = input => {
    const { regions } = this.props
    const escapedInput = preg_quote(input)
    const array = regions.filter(region =>
      region.label.toLowerCase().match(new RegExp(escapedInput, 'i'))
    )

    return fetchAddressAutocomplete(array, input)
  }

  handleChange = categoryOption => {
    this.setState({ category: categoryOption })
  }

  onChange = value => {
    this.setState({ query: value })
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.query) {
      if (this.state.query.value.length === 4) {
        const jsonBounds = this.state.query.value.map(bound => ({
          lat: bound[0],
          lng: bound[1]
        }))
        this.props.history.push(
          `${routes.recherche}?category=${getUrlValueFromValue(
            this.state.category.value
          )}&page=1&bounds=${encodeBase64(JSON.stringify(jsonBounds))}`
        )
      } else if (this.state.query.value.length === 2) {
        this.props.history.push(
          `${routes.recherche}?category=${getUrlValueFromValue(
            this.state.category.value
          )}&coordinates=${this.state.query.value}`
        )
      }
    } else if (this.state.category.value !== 'all') {
      this.props.history.push(
        `${routes.recherche}?category=${getUrlValueFromValue(
          this.state.category.value
        )}&page=1&bounds=${encodeBase64(boundsFranceStringified)}`
      )
    } else {
      this.setState({ showAlert: true })
    }
  }

  render() {
    const { classes, regions, isMobile } = this.props
    const alertMessage =
      'Vous devez choisir une adresse ou une région dans la liste avant de cliquer sur rechercher.'

    return isMobile ? (
      /* Format mobile */
      <div className={classes.div}>
        <form>
          <div className={classes.divinput}>
            <div>
              <span className={classes.indication}>Sélectionnez</span>
              <Select
                instanceId='mobile-1'
                className={classes.categories}
                classNamePrefix='categories'
                value={this.state.category}
                onChange={this.handleChange}
                name='categories'
                options={categoriesOptions}
                isClearable={false}
                isSearchable={false}
                components={{ IndicatorSeparator: () => <div /> }}
              />
            </div>
            <div className={classes.addressGroup}>
              <span className={classes.indication}>Votre ville ou adresse</span>
              <Async
                instanceId='mobile-2'
                name='autocomplete-region-address'
                autoFocus
                defaultOptions={regions}
                value={this.state.query}
                onChange={this.onChange}
                loadOptions={this.optionsAutocomplete}
                placeholder={'Près de chez moi'}
                isClearable={false}
                isMulti={false}
                components={{ IndicatorsContainer: () => <div /> }}
                className={classes.autocomplete}
                classNamePrefix='address'
              />
              <div className={classes.marqueur}>
                <Marqueur width={16} height={22} />
              </div>
            </div>
          </div>
          <div>
            <Button onClick={this.handleSubmit}>Rechercher</Button>
          </div>
        </form>
        {this.state.showAlert && (
          <Alert bsStyle='warning' className={classes.alert}>
            {alertMessage}
          </Alert>
        )}
      </div>
    ) : (
      /* Autre format */
      <div className={classes.divAutreFormat}>
        <form className={classes.formAutreFormat}>
          <div className={classes.categoryGroup}>
            <span className={classes.indication}>Sélectionnez</span>
            <Select
              instanceId='desktop-1'
              className={classes.categoriesAF}
              classNamePrefix='categories'
              value={this.state.category}
              onChange={this.handleChange}
              name='categories'
              options={categoriesOptions}
              isClearable={false}
              isSearchable={false}
              components={{ IndicatorSeparator: () => null }}
            />
          </div>

          <div className={classes.addressGroupAF}>
            <span className={classes.indication}>Votre ville ou adresse</span>
            <Async
              instanceId='desktop-2'
              name='autocomplete-region-address-desktop'
              autoFocus
              defaultOptions={regions}
              value={this.state.query}
              onChange={this.onChange}
              loadOptions={this.optionsAutocomplete}
              placeholder={'Près de chez moi'}
              isClearable={false}
              isMulti={false}
              components={{ IndicatorsContainer: () => null }}
              className={classes.autocompleteAF}
              classNamePrefix='addressAF'
            />
            <div className={classes.marqueurAF}>
              <Marqueur width={16} height={22} />
            </div>
          </div>
          <div className={classes.divButtonAutreFormat}>
            <Button
              className={classes.submitButton}
              onClick={this.handleSubmit}
            >
              Rechercher
            </Button>
          </div>
          {this.state.showAlert && (
            <Alert bsStyle='warning' className={classes.alert}>
              {alertMessage}
            </Alert>
          )}
        </form>
      </div>
    )
  }
}

const StyledSearch = injectSheet(styles)(SearchAccueil)
export default responsiveComponent(withRouter(StyledSearch))
