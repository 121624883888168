import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import SearchAccueil from './SearchAccueil'

const gqlRegions = gql`
  {
    regions {
      name
      squareBounds
    }
  }
`

const SearchAccueilContainer = ({ data: { regions, loading } }) => {
  const regionsPacked = loading
    ? []
    : regions.map(region => ({
        value: region.squareBounds,
        label: region.name
      }))

  return <SearchAccueil regions={regionsPacked} />
}

export default graphql(gqlRegions)(SearchAccueilContainer)
