import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import { colors } from '../config/themes'
import P from './font/P'

const styles = theme => ({
  spinnerContainer: theme.spinnerContainer,
  spinner: theme.spinner,
  loadingText: {
    textAlign: 'center',
    marginTop: 15
  }
})

let ReactSpinner
class Spinner extends PureComponent {
  constructor(props) {
    super(props)

    if (typeof window !== 'undefined') {
      ReactSpinner = require('react-spinner-material').default
    }
  }

  render() {
    const { classes, className, color, radius, stroke, visible } = this.props

    return typeof window !== 'undefined' ? (
      <div
        className={`${classes.spinnerContainer}${
          className ? ` ${className}` : ''
        }`}
      >
        <div className={classes.spinner}>
          <ReactSpinner
            color={color || colors.vert}
            radius={radius}
            stroke={stroke}
            visible={visible}
          />
        </div>
      </div>
    ) : (
      <P className={classes.loadingText}>chargement...</P>
    )
  }
}

Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  radius: PropTypes.number,
  stroke: PropTypes.number,
  visible: PropTypes.bool
}

export default withStyles(styles)(Spinner)
