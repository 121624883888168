import React from 'react'
import { withRouter } from 'react-router-dom'
import routes from './routing/routes'

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      typeof window !== 'undefined' &&
      this.props.location !== prevProps.location &&
      this.props.location.pathname !== routes.recherche
    ) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)
