import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import { Link } from 'react-router-dom'

const styles = theme => ({
  link: {
    padding: 7,
    '&:hover, &:focus': {
      textDecoration: 'none'
    }
  },
  vert: {
    backgroundColor: theme.button.backgroundColor,
    color: theme.button.color,
    fontWeight: theme.regular,
    border: `3px solid ${theme.button.borderColor}`,
    '&:hover, &:focus': {
      color: theme.button.color,
      borderColor: theme.button.borderHoverColor,
      backgroundColor: theme.button.backgroundColorHover
    },
    '&:active': {
      backgroundColor: theme.button.backgroundColorActive,
      borderColor: theme.button.borderActiveColor
    }
  },
  jaune: {
    backgroundColor: theme.button.backgroundColorJ,
    fontWeight: theme.regular,
    color: theme.button.color,
    border: `3px solid ${theme.button.borderColorJ}`,
    '&:hover, &:focus': {
      color: theme.button.color,
      borderColor: theme.button.borderHoverColorJ,
      backgroundColor: theme.button.backgroundColorHoverJ
    },
    '&:active': {
      backgroundColor: theme.button.backgroundColorActiveJ,
      borderColor: theme.button.borderActiveColorJ
    }
  },
  barrioFont: {
    fontFamily: 'barrio',
    fontSize: '22px'
  }
})

const ButtonLink = ({
  children,
  classes,
  className,
  jaune,
  defaultFont,
  disabled,
  ...props
}) => {
  const classNames = `${classes.link}${className ? ` ${className}` : ''} ${
    jaune ? classes.jaune : classes.vert
  }${jaune && !defaultFont ? ` ${classes.barrioFont}` : ''}`

  return disabled ? (
    <span {...props} className={classNames}>
      {children}
    </span>
  ) : (
    <Link {...props} className={classNames}>
      {children}
    </Link>
  )
}

ButtonLink.propTypes = {
  children: PropTypes.string.isRequired,
  defaultFont: PropTypes.bool,
  disabled: PropTypes.bool,
  jaune: PropTypes.bool
}

export default injectSheet(styles)(ButtonLink)
