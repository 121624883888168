import React from 'react'
import injectSheet from 'react-jss'

const styles = theme => ({
  h2: {
    fontWeight: theme.bold,
    color: theme.titre2,
    fontSize: theme.tailleMoyenTexte
  }
})

const Titre = ({
  classes,
  className,
  children,
  component: Component = 'h2',
  ...props
}) => (
  <Component {...props} className={`${classes.h2} ${className || ''}`}>
    {children}
  </Component>
)

export default injectSheet(styles)(Titre)
