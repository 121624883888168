import React, { Component } from 'react'
import { hot } from 'react-hot-loader/root'
import configureApolloClient from './configureApolloClient'
import { CookiesProvider } from 'react-cookie'
import { ApolloProvider } from 'react-apollo'
import Router from './routing/Router'
// import * as Sentry from '@sentry/browser'
// import { NODE_ENV } from './config/env'

const client = configureApolloClient()

class Client extends Component {
  state = {
    hasError: false,
    eventId: null
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  // componentDidCatch(error, errorInfo) {
  //   if (NODE_ENV === 'production') {
  //     Sentry.withScope(scope => {
  //       scope.setExtras(errorInfo)
  //       const eventId = Sentry.captureException(error)
  //       this.setState({ eventId })
  //     })
  //   }
  // }

  render() {
    return (
      <CookiesProvider>
        <ApolloProvider client={client}>
          <Router />
        </ApolloProvider>
      </CookiesProvider>
    )
  }
}

export default hot(Client)
