import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  },
  cls3: {
    strokeMiterlimit: '10',
    fill: '#edb57a',
    stroke: '#4c2f21',
    strokeWidth: '0.284px'
  },
  cls6: {
    strokeMiterlimit: '10',
    stroke: '#edb57a',
    strokeWidth: '0.853px'
  },
  cls7: {
    strokeMiterlimit: '10',
    stroke: '#edb57a',
    strokeWidth: '0.853px',
    fill: '#fff'
  }
}

const ProduitsFermiers = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-3893.573 1075.543 97.745 97.745'
  >
    <g transform='translate(-3914.5 1075.543)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(21.427 0.5)'
      />
      <g transform='translate(30.743 11.321)'>
        <path
          fill='#edb57a'
          d='M115.277,71.23V94.664H52.5V70.8'
          transform='translate(-45.62 -31.242)'
        />
        <path
          className={classes.cls3}
          d='M113.18,56.891h3.941l2.508-.43.932-8.385L81,20.2,45.1,47.289l2.078,9.6h66Z'
          transform='translate(-43.523 -16.904)'
        />
        <path
          fill='#ff3c3b'
          d='M77.585,18.252,116.856,46.2l-.072,9.388,4.3-.072V44.839L81.168,15.6,42.9,43.907V55.588h3.655V45.627l37.91-27.59'
          transform='translate(-42.9 -15.6)'
        />
        <rect
          fill='#4c2f21'
          width='32.463'
          height='21.786'
          transform='translate(21.499 41.995)'
        />
        <rect
          fill='#4c2f21'
          width='15.049'
          height='16.482'
          transform='translate(30.815 12.971)'
        />
        <rect
          fill='#4c2f21'
          width='62.777'
          height='2.508'
          transform='translate(6.88 63.995)'
        />
        <rect
          fill='#4c2f21'
          width='70.301'
          height='2.508'
          transform='translate(3.583 37.48)'
        />
        <path
          className={classes.cls6}
          d='M72.9,74.2,89.239,96.057V74.2L72.9,96.2'
          transform='translate(-51.401 -32.205)'
        />
        <line
          className={classes.cls6}
          x2='16.124'
          y2='21.857'
          transform='translate(37.838 41.995)'
        />
        <line
          className={classes.cls6}
          x1='16.124'
          y2='21.857'
          transform='translate(37.838 41.995)'
        />
        <line
          className={classes.cls7}
          x2='15.121'
          y2='16.482'
          transform='translate(30.815 12.971)'
        />
        <line
          className={classes.cls6}
          x1='15.121'
          y2='16.482'
          transform='translate(30.815 12.971)'
        />
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(ProduitsFermiers)
