// eslint-disable-next-line camelcase
import { preg_quote } from 'locutus/php/pcre/index'
import React, { Component } from 'react'
import Logo from 'src/components/svg/Logo'
import { Link } from 'react-router-dom'
import routes from 'src/routing/routes'
import { Form, FormControl, FormGroup } from 'react-bootstrap'
import injectSheet from 'react-jss'
import Facebook from 'src/components/svg/Facebook'
import Twitter from 'src/components/svg/Twitter'
import Marqueur from 'src/components/svg/Marqueur'
import ButtonStyle from 'src/components/button/ButtonStyle'
import ModalConnexion from 'src/components/modal/ModalConnexion'
import { withRouter } from 'react-router'
import Async from 'react-select/async'
import Select from 'react-select'
import { boundsFranceStringified, categoriesOptions } from 'src/config/dataConf'
import IconeConnexion from 'src/components/top_bar/IconeConnexion'
import * as qs from 'query-string'
import {
  encodeBase64,
  fetchAddressAutocomplete,
  getMediaQuerySize,
  getUrlValueFromValue,
  getValueFromUrlValue
} from 'src/utils'

const styles = theme => {
  const mediaQuery = getMediaQuerySize()

  return {
    flexbox: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      [mediaQuery]: {
        flexWrap: 'nowrap'
      }
    },
    bleu: {
      backgroundColor: theme.topBarColor
    },
    blanc: {
      backgroundColor: theme.topBarColorSearch
    },
    lienLogo: {
      margin: '15px 0',
      flex: '2 0 auto',
      order: 1,
      [mediaQuery]: {
        order: 1
      }
    },
    form: {
      paddingTop: '15px',
      paddingBottom: '15px',
      marginTop: '10px',
      textAlign: 'center',
      flex: '1 0 auto',
      order: 3,
      [mediaQuery]: {
        order: 2
      }
    },
    divConnexion: {
      paddingTop: 25,
      textAlign: 'right',
      paddingLeft: 0,
      flex: '2 0 auto',
      order: 2,
      [mediaQuery]: {
        order: 3
      }
    },
    compte: {
      marginRight: 15,
      '&:hover': {
        cursor: 'pointer'
      }
    },
    reseaux_sociaux: {
      display: 'inline-block'
    },
    marqueur: {
      padding: 6
    },
    localisation: {
      marginRight: '15px',
      width: '165px'
    },
    divButton: {
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    button: {
      padding: '3px'
    },
    spaceRight: {
      marginRight: 5
    },
    categories: {
      display: 'inline-block',
      verticalAlign: 'middle',
      '& .categories__control': {
        width: 185,
        marginRight: 15,
        border: theme.borderInput,
        borderRadius: 0,
        '& .categories__value-container': {
          ...(typeof window === 'undefined'
            ? {
                lineHeight: '34px',
                backgroundColor: 'white',
                textAlign: 'left',
                paddingLeft: 10
              }
            : {}),
          '& .css-gj7qu5-dummyInput': {
            ...(typeof window === 'undefined' ? { display: 'none' } : {})
          }
        },
        '& .categories__indicators': {
          '& .css-19bqh2r': {
            ...(typeof window === 'undefined' ? { display: 'none' } : {})
          }
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }
    },
    autocomplete: {
      '& .addressAF__control': {
        border: theme.borderInput,
        borderRadius: 0,
        ...(typeof window === 'undefined'
          ? {
              lineHeight: '34px',
              backgroundColor: 'white',
              textAlign: 'left',
              paddingLeft: 10
            }
          : {}),
        '& .addressAF__single-value': {
          width: 130
        },
        '& .addressAF__input input': {
          maxWidth: 125
        },
        '& .addressAF__placeholder': {
          paddingRight: 40
        },
        '& .css-1g6gooi': {
          ...(typeof window === 'undefined' ? { display: 'none' } : {})
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  }
}

class TopBarSearch extends Component {
  constructor(props) {
    super(props)
    const category = getValueFromUrlValue(
      qs.parse(this.props.location.search).category
    )
    let search = null
    if (category) {
      for (let i = 0; i < categoriesOptions.length; i++) {
        if (categoriesOptions[i].value === category) {
          search = categoriesOptions[i]
        }
      }

      if (search) {
        this.state = {
          showModal: false,
          query: '',
          category: search
        }
      }
    }

    if (!category || !search) {
      this.state = {
        showModal: false,
        query: '',
        category: categoriesOptions[0]
      }
    }
  }

  closeModalConnexion = () => {
    this.setState({ showModal: false })
  }

  openModalConnexion = () => {
    this.setState({ showModal: true })
  }

  optionsAutocomplete = input => {
    const { regions } = this.props
    const escapedInput = preg_quote(input)
    const array = regions
      ? regions.filter(region =>
          region.label.toLowerCase().match(new RegExp(escapedInput, 'i'))
        )
      : []

    return fetchAddressAutocomplete(array, input)
  }

  handleChange = categoryOption => {
    this.setState({ category: categoryOption })
  }

  onChange = value => {
    this.setState({ query: value })
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.query) {
      if (this.state.query.value.length === 4) {
        const jsonBounds = this.state.query.value.map(bound => ({
          lat: bound[0],
          lng: bound[1]
        }))
        this.props.history.push(
          `${routes.recherche}?category=${getUrlValueFromValue(
            this.state.category.value
          )}&page=1&bounds=${encodeBase64(JSON.stringify(jsonBounds))}`
        )
      } else if (this.state.query.value.length === 2) {
        this.props.history.push(
          `${routes.recherche}?category=${getUrlValueFromValue(
            this.state.category.value
          )}&coordinates=${this.state.query.value}`
        )
      }
    } else if (this.state.category.value !== 'all') {
      this.props.history.push(
        `${routes.recherche}?category=${getUrlValueFromValue(
          this.state.category.value
        )}&page=1&bounds=${encodeBase64(boundsFranceStringified)}`
      )
    } else {
      this.setState({ showAlert: true })
    }
  }

  render() {
    const { classes, className, bleu, regions } = this.props

    return (
      <div
        className={`${bleu ? classes.bleu : classes.blanc} ${classes.flexbox} ${
          className || ''
        }`}
      >
        <div className={classes.lienLogo}>
          <Link to={routes.accueil}>
            <Logo width={'200'} />
          </Link>
        </div>

        <Form inline className={classes.form}>
          <Select
            instanceId='topBar-1'
            className={classes.categories}
            classNamePrefix='categories'
            value={this.state.category}
            onChange={this.handleChange}
            name='categories'
            options={categoriesOptions}
            isClearable={false}
            isSearchable={false}
            components={{ IndicatorSeparator: () => <div /> }}
          />

          <FormGroup className={classes.localisation}>
            <Async
              instanceId='topBar-2'
              name='autocomplete-region-address-desktop'
              defaultOptions={regions}
              value={this.state.query}
              onChange={this.onChange}
              loadOptions={this.optionsAutocomplete}
              placeholder='Ville, adresse...'
              isClearable={false}
              isMulti={false}
              components={{ IndicatorsContainer: () => <div /> }}
              className={classes.autocomplete}
              classNamePrefix='addressAF'
            />

            <FormControl.Feedback>
              <Marqueur className={classes.marqueur} />
            </FormControl.Feedback>
          </FormGroup>

          <div className={classes.divButton}>
            <ButtonStyle className={classes.button} onClick={this.handleSubmit}>
              Rechercher
            </ButtonStyle>
          </div>
        </Form>
        <div className={classes.divConnexion}>
          <span className={classes.compte}>
            <IconeConnexion
              bleu={bleu}
              openModalConnexion={this.openModalConnexion}
              closeModalConnexion={this.closeModalConnexion}
            />
          </span>
          <a
            href='https://www.facebook.com/acheteralasource/'
            className={classes.spaceRight}
            target='_blank'
          >
            <Facebook width={30} />
          </a>
          <a href='https://twitter.com/acheteralasourc' target='_blank'>
            <Twitter width={30} />
          </a>
        </div>
        <ModalConnexion
          show={this.state.showModal}
          onHide={this.closeModalConnexion}
        />
      </div>
    )
  }
}

export default withRouter(injectSheet(styles)(TopBarSearch))
