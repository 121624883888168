import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  }
}

const Bovin = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-3400.46 1232.485 97.745 97.745'
  >
    <g transform='translate(-3914.5 1075.543)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(514.54 157.442)'
      />
      <g transform='translate(524.573 165.325)'>
        <path
          fill='#cb916f'
          d='M768.279,253.146c-9.961-1-12.828-15.479-11.036-22.646-5.231,5.088-10.1,26.014,8.958,27.232l4.013-3.225Z'
          transform='translate(-737.822 -230.5)'
        />
        <path
          fill='#cb916f'
          d='M795.335,253.146c9.961-1,12.828-15.479,11.036-22.646,5.231,5.088,10.1,26.014-8.958,27.232l-4.013-3.225Z'
          transform='translate(-749.399 -230.5)'
        />
        <path
          fill='#773f0e'
          d='M774.771,260.856c-3.01-2.007-1.29-6.808,1.362-6.378-1.075-1.72,4.371-4.8,6.45-1.075,3.153-2.365,6.593.072,6.163,2.867,2.866.215,3.368,2.652,2.437,5.231Z'
          transform='translate(-743.669 -236.491)'
        />
        <path
          fill='#773f0e'
          d='M757.444,298.673c-2.293-8.886,5.8-32.893,14.691-35.473h13.043c7.811,4.8,14.046,27.017,12.828,36.046Z'
          transform='translate(-739.099 -239.766)'
        />
        <ellipse
          fill='#cb916f'
          cx='22.072'
          cy='15.981'
          rx='22.072'
          ry='15.981'
          transform='translate(16.268 49.017)'
        />
        <path
          fill='#663306'
          d='M752.782,266.608c-7.955-4.873-17.916,2.938-20.782,12.183,3.44,1.72,12.4,11.179,22.717-5.088-3.8,4.945-8.313,4.73-12.326,1C743.323,271.195,747.193,265.748,752.782,266.608Z'
          transform='translate(-732 -240.308)'
        />
        <path
          fill='#663306'
          d='M809.235,266.608c7.955-4.873,17.916,2.938,20.782,12.183-3.44,1.72-12.4,11.179-22.717-5.088,3.8,4.945,8.313,4.73,12.326,1C818.623,271.195,814.753,265.748,809.235,266.608Z'
          transform='translate(-753.338 -240.308)'
        />
        <circle
          fill='#42210b'
          cx='2.723'
          cy='2.723'
          r='2.723'
          transform='translate(29.095 37.48)'
        />
        <circle
          fill='#42210b'
          cx='2.723'
          cy='2.723'
          r='2.723'
          transform='translate(42.353 37.48)'
        />
        <path
          fill='#4c2f21'
          d='M768.028,320.94a2.992,2.992,0,0,0,1.863-2.867,3.059,3.059,0,0,0-3.081-3.081,3.011,3.011,0,0,0-3.01,2.437h0v-.287a3.941,3.941,0,1,1,7.883,0A3.658,3.658,0,0,1,768.028,320.94Z'
          transform='translate(-741.011 -253.935)'
        />
        <path
          fill='#4c2f21'
          d='M799.783,320.94a2.992,2.992,0,0,1-1.863-2.867,3.075,3.075,0,0,1,3.153-3.081,3.011,3.011,0,0,1,3.01,2.437h0v-.287a3.941,3.941,0,1,0-7.883,0A3.6,3.6,0,0,0,799.783,320.94Z'
          transform='translate(-750.192 -253.935)'
        />
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(Bovin)
