import React from 'react'
import App from '../App'
import { BrowserRouter } from 'react-router-dom'

const Router = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

export default Router
