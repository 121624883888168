import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Logo from '../svg/Logo'
import { Link } from 'react-router-dom'
import routes from '../../routing/routes'
import { Col, Grid, Row } from 'react-bootstrap'
import injectSheet from 'react-jss'
import ModalConnexion from '../modal/ModalConnexion'
import IconeConnexion from './IconeConnexion'
import classnames from 'classnames'

const styles = theme => ({
  grid: {
    backgroundColor: theme.topBarColor
  },
  lienLogo: {
    margin: '15px 0px 15px 15px'
  },
  compte: {
    marginTop: '30px',
    marginRight: '15px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  alignRight: {
    textAlign: 'right'
  }
})

class TopBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  closeModalConnexion = () => {
    this.setState({ showModal: false })
  }

  openModalConnexion = () => {
    this.setState({ showModal: true })
  }

  render() {
    const { classes, className } = this.props

    return (
      <Grid fluid className={classnames(classes.grid, className)}>
        <Row>
          <Col xs={8}>
            <div className={classes.lienLogo}>
              <Link to={routes.accueil}>
                <Logo width={'200'} />
              </Link>
            </div>
          </Col>
          <Col xs={4} className={classes.alignRight}>
            <IconeConnexion
              className={classes.compte}
              mobile
              openModalConnexion={this.openModalConnexion}
              closeModalConnexion={this.closeModalConnexion}
            />
          </Col>
        </Row>

        <ModalConnexion
          show={this.state.showModal}
          onHide={this.closeModalConnexion}
        />
      </Grid>
    )
  }
}

export default injectSheet(styles)(TopBar)

TopBar.propTypes = {
  className: PropTypes.string
}
