import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import routes from './routes'
import ErrorPage from '../scenes/front/ErrorPage'

const ProducerRedirect = ({ companyName }) => (
  <Query query={queryWithName} variables={{ companyName }}>
    {({ loading, error, data }) => {
      if (loading) return <p>Chargement...</p>
      if (error) return <ErrorPage />

      const { ficheProducerWithName } = data
      if (!ficheProducerWithName) return <ErrorPage />

      const newPath = routes.ficheProducteur.replace(
        ':id(\\d+)',
        ficheProducerWithName.id
      )
      return <Redirect to={newPath} />
    }}
  </Query>
)

const RedirectProducerWithStatus = ({ noStrict, from, status }) => (
  <Switch>
    <Route
      strict={!noStrict}
      exact
      path={from}
      render={({ match: { params }, staticContext }) => {
        if (staticContext) staticContext.status = status
        return <ProducerRedirect companyName={params.nomProducteur} />
      }}
    />

    <Route component={ErrorPage} />
  </Switch>
)

const queryWithName = gql`
  query FicheProducerWithName($companyName: String!) {
    ficheProducerWithName(companyName: $companyName) {
      id
      companyName
      presentation
      valid
      address {
        id
        postalCode
        city
        address
        coordinates {
          x
          y
        }
      }
      website
      categories {
        id
        reference
      }
      photosProducer {
        id
        cloudPath
      }
      products {
        id
        name
        available
        photosProduct {
          id
          cloudPath
        }
        saleMode {
          ... on PackedSale {
            id
            offers {
              id
              pieces
              quantity
              price
              unit
            }
          }
          ... on BulkSale {
            id
            price
            unit
          }
        }
      }
    }
  }
`

export default RedirectProducerWithStatus
