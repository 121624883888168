import React from 'react'
import injectSheet from 'react-jss'
import Titre1 from '../../../../../../components/font/Titre1'
import P from '../../../../../../components/font/P'
import { Col, Row } from 'react-bootstrap'
import Titre5 from '../../../../../../components/font/Titre5'
import Oeil from '../../../../../../components/svg/Oeil'
import { Link } from 'react-router-dom'
import routes from '../../../../../../routing/routes'
import ModalMapArticle from '../../ModalMapArticle/ModalMapArticle'
import { interpretHtml } from 'src/utils'

const styles = {
  wrapper: {
    textAlign: 'center',
    backgroundColor: 'white',
    margin: '10px 0px',
    transitionDuration: 300,
    '&:hover': {
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.3);'
    }
  },
  titreFiche: {
    textAlign: 'left'
  },
  fiche: {
    backgroundColor: 'white',
    textAlign: 'justify',
    padding: '15px 15px'
  },
  divImage: {
    width: '100%',
    paddingTop: '60%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    position: 'relative'
  },
  map: {
    position: 'absolute',
    bottom: '-25px',
    right: '15px'
  },
  lirePlus: {
    textAlign: 'right'
  },
  text: {
    display: 'inline-block'
  },
  oeil: {
    margin: '0px 5px -6px 0px'
  }
}

const ProdAccueil = ({
  classes,
  idProducer,
  companyName,
  presentation = 'Pas de presentation',
  photosProducer,
  address
}) => {
  const image =
    photosProducer && photosProducer.length > 0
      ? photosProducer[0].cloudPath
      : '/img/no_photo.jpg'

  return (
    <div className={classes.wrapper}>
      <div
        className={classes.divImage}
        style={{ backgroundImage: `url(${image})` }}
      >
        <ModalMapArticle ficheProducer={{ companyName, address }} />
      </div>
      <div className={classes.fiche}>
        <Row>
          <Col xs={10} className={classes.titreFiche}>
            <Titre1 component={'p'}>{companyName.toUpperCase()}</Titre1>
          </Col>
        </Row>
        <P>
          {interpretHtml(
            presentation.length < 600
              ? presentation.replace(/\n/g, '<br/>')
              : presentation.replace(/\n/g, '<br/>').substr(0, 600) + '...'
          )}
        </P>
        <Row>
          <Col xs={12} className={classes.lirePlus}>
            <Link to={routes.ficheProducteur.replace(':id(\\d+)', idProducer)}>
              <Oeil taille={25} className={classes.oeil} />
              <Titre5 className={classes.text}>Lire plus</Titre5>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default injectSheet(styles)(ProdAccueil)
