import React from 'react'

const CompteConnexion = ({ taille, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-438 30 14.339 20.484'
  >
    <g transform='translate(-741.661 -0.516)'>
      <path
        fill='#0091a3'
        d='M14.339,26H0V24.57A7.169,7.169,0,0,1,7.17,17.4h0a7.169,7.169,0,0,1,7.17,7.17Z'
        transform='translate(303.661 24.997)'
      />
      <circle
        fill='#0091a3'
        cx='5.326'
        cy='5.326'
        r='5.326'
        transform='translate(305.505 30.516)'
      />
    </g>
  </svg>
)

export default CompteConnexion
