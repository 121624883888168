import { ApolloClient } from 'apollo-client'
import { ApolloLink, concat } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory'
import { Cookies } from 'react-cookie'
import { API_ENDPOINT } from './config/env'

export const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'INTERFACE',
          name: 'SaleMode',
          possibleTypes: [
            {
              name: 'PackedSale'
            },
            {
              name: 'BulkSale'
            }
          ]
        },
        {
          kind: 'INTERFACE',
          name: 'Element',
          possibleTypes: [
            {
              name: 'Message'
            }
          ]
        },
        {
          kind: 'INTERFACE',
          name: 'Document',
          possibleTypes: [
            {
              name: 'PieceJointeMessage'
            },
            {
              name: 'PhotoProduct'
            },
            {
              name: 'PhotoProducer'
            }
          ]
        }
      ]
    }
  }
})

export const authMiddleware = new ApolloLink((operation, forward) => {
  // get the authentication token from cookies if it exists
  const cookieManager = new Cookies()
  const token = cookieManager.get('token')
  operation.setContext({
    headers: {
      ...(token && token !== '' ? { Authorization: `Bearer ${token}` } : {})
    }
  })

  return forward(operation)
})

export default () =>
  new ApolloClient({
    link: concat(authMiddleware, createUploadLink({ uri: API_ENDPOINT })),
    cache: new InMemoryCache({ fragmentMatcher }).restore(
      window.__APOLLO_STATE__
    )
  })
