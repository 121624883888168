import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  }
}

const Oeufs = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-3234.202 1075.543 97.745 97.745'
  >
    <g transform='translate(-3914.5 1075.543)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(680.798 0.5)'
      />
      <g transform='translate(688.968 21.712)'>
        <path
          fill='#d4905f'
          d='M1005.732,47.012a11.466,11.466,0,1,1-22.932,0c0-6.306,5.16-16.912,11.466-16.912C1000.644,30.1,1005.732,40.706,1005.732,47.012Z'
          transform='translate(-967.464 -30.1)'
        />
        <path
          fill='#d4905f'
          d='M1042.232,47.012a11.466,11.466,0,1,1-22.932,0c0-6.306,5.16-16.912,11.466-16.912C1037.144,30.1,1042.232,40.706,1042.232,47.012Z'
          transform='translate(-977.807 -30.1)'
        />
        <path
          fill='#fcac72'
          d='M988.732,62.512a11.466,11.466,0,1,1-22.932,0c0-6.306,5.16-16.912,11.466-16.912C983.644,45.6,988.732,56.206,988.732,62.512Z'
          transform='translate(-962.647 -34.492)'
        />
        <path
          fill='#fcac72'
          d='M1024.232,62.512a11.466,11.466,0,1,1-22.932,0c0-6.306,5.16-16.912,11.466-16.912S1024.232,56.206,1024.232,62.512Z'
          transform='translate(-972.706 -34.492)'
        />
        <path
          fill='#fcac72'
          d='M1059.632,62.512a11.466,11.466,0,1,1-22.932,0c0-6.306,5.16-16.912,11.466-16.912S1059.632,56.206,1059.632,62.512Z'
          transform='translate(-982.737 -34.492)'
        />
        <g transform='translate(0 27.519)'>
          <path
            fill='#e1cf7e'
            d='M1040.372,68.5h-1c0,.358.072.645.072,1a12.469,12.469,0,1,1-24.939,0,5.077,5.077,0,0,1,.072-1H1014c0,.358.072.645.072,1a12.469,12.469,0,0,1-24.939,0,5.077,5.077,0,0,1,.072-1h-.573c0,.358.072.645.072,1a12.469,12.469,0,0,1-24.939,0,5.077,5.077,0,0,1,.072-1h-1a1.437,1.437,0,0,0-1.433,1.433V84.051a1.437,1.437,0,0,0,1.433,1.433h77.539a1.437,1.437,0,0,0,1.433-1.433V69.933A1.486,1.486,0,0,0,1040.372,68.5Z'
            transform='translate(-961.4 -68.5)'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(Oeufs)
