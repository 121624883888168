import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import usePub from '../hooks/usePub'

const PubContainer = ({ className, scriptPubCore }) => {
  const pub = useRef(null)
  usePub(pub, scriptPubCore)

  return <div className={className} ref={pub} />
}

PubContainer.propTypes = {
  className: PropTypes.string,
  scriptPubCore: PropTypes.string.isRequired
}

export default PubContainer
