import React, { Component } from 'react'
import Logo from '../svg/Logo'
import { Link } from 'react-router-dom'
import routes from '../../routing/routes'
import injectSheet from 'react-jss'
import ButtonStyle from '../button/ButtonStyle'
import { withRouter } from 'react-router'
import { withCookies } from 'react-cookie'

const styles = theme => ({
  grid: {
    backgroundColor: theme.topBarColor,
    paddingBottom: 15
  },
  lienLogo: {
    width: '50%',
    padding: '0 0 0 15px',
    display: 'inline-block',
    verticalAlign: 'top'
  },
  buttonLogout: {
    textAlign: 'right',
    width: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    paddingRight: 15
  },
  logo: {
    maxWidth: '200px'
  }
})

class TopBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      token: props.cookies.get('token')
    }
  }

  disconnect = () => {
    const { cookies } = this.props
    cookies.remove('token', { path: '/' })
    this.setState({ token: cookies.get('token') })
    this.props.history.push(routes.accueil)
  }

  render() {
    const { classes, className } = this.props

    return (
      <div className={`${className || ''} ${classes.grid}`}>
        <div className={classes.lienLogo}>
          <Link to={routes.accueil}>
            <Logo width={'100%'} className={classes.logo} />
          </Link>
        </div>
        {this.state.token && (
          <div className={classes.buttonLogout}>
            <ButtonStyle onClick={this.disconnect}>Déconnexion</ButtonStyle>
          </div>
        )}
      </div>
    )
  }
}

const styleSheet = injectSheet(styles)(TopBar)
const withRout = withRouter(styleSheet)

export default withCookies(withRout)
