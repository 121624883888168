export default theme => ({
  hrTitle: {
    borderColor: theme.ficheProducteur.hrColor,
    border: '1px solid',
    marginTop: 0
  },
  ads: {
    backgroundColor: 'white',
    textAlign: 'center'
  },
  hrSeparator: {
    borderColor: theme.Plan.hrColor
  },
  wrapperAll: {
    backgroundColor: theme.backgroundColor
  },
  wrapperWithoutFooter: {
    minHeight: theme.minHeight
  },
  cityContainer: {
    backgroundColor: 'white'
  }
})
