import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: '#0093a3'
  },
  cls2: {
    fill: '#773f0e'
  },
  cls3: {
    fill: '#fff'
  },
  cls4: {
    fill: '#51c2bf'
  },
  cls5: {
    fill: '#796575'
  },
  cls6: {
    fill: '#a8bef9'
  },
  cls7: {
    fill: '#74c367'
  },
  cls8: {
    fill: '#2e555a'
  },
  cls9: {
    fill: '#abe0e0'
  },
  cls10: {
    fill: '#cb916f'
  },
  cls11: {
    fill: '#ebed98'
  },
  cls12: {
    fill: '#fedc60'
  },
  cls13: {
    fill: '#ff4637'
  },
  cls14: {
    fill: '#006c59'
  },
  cls15: {
    stroke: '#0093a3',
    fill: 'none',
    strokeLinecap: 'round',
    strokeMiterlimit: 10,
    strokeWidth: '3.36px'
  },
  cls16: {
    fill: '#e9d086'
  },
  cls17: {
    fill: '#eff3c2'
  },
  cls18: {
    fill: '#f8af08'
  },
  cls19: {
    clipPath: 'url(#clip-path)'
  },
  cls20: {
    stroke: '#a3debc',
    fill: 'none',
    strokeLinecap: 'round',
    strokeMiterlimit: 10,
    strokeWidth: '3.36px'
  },
  cls21: {
    fill: '#a5b200'
  },
  cls22: {
    clipPath: 'url(#clip-path-11)'
  },
  cls23: {
    stroke: '#fedc60',
    strokeLinejoin: 'round',
    strokeWidth: '2.24px',
    strokeDasharray: '0 4.48',
    fill: 'none',
    strokeLinecap: 'round',
    strokeMiterlimit: 10
  },
  cls24: {
    fill: '#008495'
  },
  cls25: {
    clipPath: 'url(#clip-path-19)'
  },
  cls26: {
    fill: '#edb57a'
  },
  cls27: {
    fill: '#663306'
  },
  cls28: {
    fill: '#231f20'
  },
  cls29: {
    clipPath: 'url(#clip-path-23)'
  },
  cls30: {
    clipPath: 'url(#clip-path-24)'
  },
  cls31: {
    clipPath: 'url(#clip-path-25)'
  },
  cls32: {
    fill: '#61a356'
  },
  cls33: {
    fill: '#783c07'
  },
  cls34: {
    fill: '#9eb600'
  },
  cls35: {
    fill: '#ff3c3b'
  },
  cls36: {
    fill: '#ef2022'
  },
  cls37: {
    fill: '#c3ffff'
  }
}

const FondAccueil = ({ width, classes }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink={'http://www.w3.org/1999/xlink'}
    width={width}
    viewBox='-3890 263.594 1086.95 412.811'
  >
    <defs>
      <clipPath id='clip-path'>
        <path
          id='Tracé_296'
          d='M506.6,368.771s-10.41,13.76-8.376,27.162a407.082,407.082,0,0,1,62.041,2.333c6.282-13.7,11.965-29.375,16.333-37.811A115.324,115.324,0,0,0,506.6,368.771Z'
          transform='translate(-497.96 -358.303)'
        />
      </clipPath>
      <clipPath id='clip-path-11'>
        <path
          id='Tracé_317'
          d='M469.3,375.8s-41.879,17.111-64.2,38.529c22.794-5.145,48.46-11.188,55.819-11.367C460.022,399.133,461.159,386.15,469.3,375.8Z'
          transform='translate(-405.1 -375.8)'
        />
      </clipPath>
      <clipPath id='clip-path-19'>
        <path
          id='Tracé_373'
          d='M1228.93,481.706h27.76s-1.257-28.478-12.743-32.606c-5.684,2.453-23.931,14.478-25.546,16.154,2.991.658,8.795,3.829,10.53,16.453'
          transform='translate(-1218.4 -449.1)'
        />
      </clipPath>
      <clipPath id='clip-path-23'>
        <path
          id='Tracé_392'
          d='M1579.448,428.5h-7.3a3.664,3.664,0,0,1-3.649-3.65v-.9a3.664,3.664,0,0,1,3.649-3.649h7.3a3.664,3.664,0,0,1,3.65,3.649v.9A3.664,3.664,0,0,1,1579.448,428.5Z'
          transform='translate(-1568.5 -420.3)'
        />
      </clipPath>
      <clipPath id='clip-path-24'>
        <path
          id='Tracé_399'
          d='M1512.828,442.654l-7.3-.3a3.681,3.681,0,0,1-3.53-3.829l.06-.9a3.681,3.681,0,0,1,3.829-3.53l7.3.3a3.681,3.681,0,0,1,3.53,3.829l-.06.9A3.718,3.718,0,0,1,1512.828,442.654Z'
          transform='translate(-1501.997 -434.097)'
        />
      </clipPath>
      <clipPath id='clip-path-25'>
        <path
          id='Tracé_406'
          d='M1620.888,463.054l7.3-.3a3.681,3.681,0,0,0,3.53-3.829l-.06-.9a3.681,3.681,0,0,0-3.829-3.53l-7.3.3a3.681,3.681,0,0,0-3.53,3.829l.06.9A3.718,3.718,0,0,0,1620.888,463.054Z'
          transform='translate(-1616.997 -454.497)'
        />
      </clipPath>
    </defs>
    <g id='Groupe_125' transform='translate(-3890 263.594)'>
      <path
        id='Tracé_262'
        className={classes.cls1}
        d='M1157.621,471.6c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906v16.094h201.021Z'
        transform='translate(-384.288 -189.452)'
      />
      <g id='Groupe_33' transform='translate(649.968 267.37)'>
        <path
          id='Tracé_263'
          className={classes.cls2}
          d='M1102.853,462.635l1.2-15.675-1.017-.06-1.2,15.675-15.436-1.2,5.863,7.778,19.444,1.5,6.94-6.82Z'
          transform='translate(-1086.4 -446.9)'
        />
        <path
          id='Tracé_264'
          className={classes.cls3}
          d='M1116.077,447.3l10.47,14.957-11.547-.9Z'
          transform='translate(-1097.889 -447.061)'
        />
      </g>
      <path
        id='Tracé_265'
        className={classes.cls4}
        d='M1134.021,478.3c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906s-12.564-1.2-12.564-4.906c0,3.709-5.624,4.906-12.564,4.906S933,482.009,933,478.3v16.094h201.021Z'
        transform='translate(-374.807 -192.144)'
      />
      <path
        id='Tracé_266'
        className={classes.cls5}
        d='M1564.557,273.54c-10.709-29.5-8.017-45.589-10.709-54.922-2.692-9.393-29.495-6.7-48.221-21.418-16.094,16.094-77.716,71.015-104.519,61.623-16.094,18.786-50.913,76.4-73.708,92.494,65.691,21.418,193-4.008,193-4.008Z'
        transform='translate(-533.247 -79.22)'
      />
      <path
        id='Tracé_267'
        className={classes.cls3}
        d='M1637.707,208.766c-8.436-2.214-17.889-5.385-25.906-11.666-5.444,5.444-7.3,7.718-20.1,18.068l4.008,14.06,15.436-16.094,13.4,4.667C1624.485,217.92,1640.22,209.425,1637.707,208.766Z'
        transform='translate(-639.422 -79.18)'
      />
      <path
        id='Tracé_268'
        className={classes.cls6}
        d='M1651.03,423.013c-16.094-29.5-18.786-112.6-22.794-119.3s-40.2,20.1-67.007-1.316c-12.085,8.017-42.9,46.9-61.623,38.888-14.718,25.487-60.306,92.494-60.306,92.494Z'
        transform='translate(-578.199 -121.481)'
      />
      <path
        id='Tracé_269'
        className={classes.cls7}
        d='M1179.4,472.479c28.119-24.111,57.614-52.289,131.322-53.606s108.587,30.811,126,52.289C1392.506,481.872,1179.4,472.479,1179.4,472.479Z'
        transform='translate(-473.792 -168.255)'
      />
      <path
        id='Tracé_270'
        className={classes.cls5}
        d='M141.1,159.5S185.313,58.99,188,42.9C204.1,45.589,237.6,17.41,249.627,0c9.393,8.017,16.094,21.418,34.82,13.4,10.709,42.9,41.52,150.108,68.323,164.825,18.786,17.41,37.512,113.912,54.922,126-46.9,6.7-194.32-12.085-194.32-12.085Z'
        transform='translate(-56.683 0)'
      />
      <path
        id='Tracé_271'
        className={classes.cls3}
        d='M193.4,85.972c8.017-19.683,14.658-37.093,15.675-43.076C225.168,45.589,258.672,17.41,270.7,0c9.393,8.017,16.094,21.418,34.82,13.4,1.974,7.778,4.547,17.709,7.718,28.837,1.795,6.4-14.418,26.145-14.418,26.145L280.031,49.6l-24.111,36.2L238.51,64.375C238.51,64.315,207.7,77.716,193.4,85.972Z'
        transform='translate(-77.693)'
      />
      <path
        id='Tracé_272'
        className={classes.cls6}
        d='M63.8,365.931c20.1-33.5,30.811-147.416,41.52-171.526s79.092,38.888,111.22-38.888c9.393-24.111,45.589,187.62,97.818,207.722C267.513,375.324,63.8,365.931,63.8,365.931Z'
        transform='translate(-25.63 -61.708)'
      />
      <g id='Groupe_34' transform='translate(16.752 232.491)'>
        <rect
          id='Rectangle_11'
          className={classes.cls2}
          width='2.692'
          height='36.854'
          transform='translate(22.136 32.845)'
        />
        <path
          id='Tracé_273'
          className={classes.cls7}
          d='M70.777,416.3a14.51,14.51,0,0,0,.778-4.547,13.844,13.844,0,0,0-8.735-12.8,11.038,11.038,0,0,0-20.461-5.025,13.716,13.716,0,0,0-9.692,13.1,5.8,5.8,0,0,0,.06,1.017,13.732,13.732,0,1,0,19.145,19.623,11.057,11.057,0,0,0,20.4-5.923A12.146,12.146,0,0,0,70.777,416.3Z'
          transform='translate(-28 -388.6)'
        />
      </g>
      <g id='Groupe_56' transform='translate(134.612 220.106)'>
        <rect
          id='Rectangle_12'
          className={classes.cls4}
          width='21.418'
          height='55.999'
          transform='translate(85.733 25.487)'
        />
        <rect
          id='Rectangle_13'
          className={classes.cls4}
          width='15.136'
          height='7.299'
          transform='translate(88.844 21.239)'
        />
        <g id='Groupe_35' transform='translate(91.955 30.093)'>
          <rect
            id='Rectangle_14'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_15'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649)'
          />
          <rect
            id='Rectangle_16'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.359)'
          />
        </g>
        <g id='Groupe_36' transform='translate(91.955 35.777)'>
          <rect
            id='Rectangle_17'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_18'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649)'
          />
          <rect
            id='Rectangle_19'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.359)'
          />
        </g>
        <g id='Groupe_37' transform='translate(91.955 41.461)'>
          <rect
            id='Rectangle_20'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_21'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649)'
          />
          <rect
            id='Rectangle_22'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.359)'
          />
        </g>
        <g id='Groupe_38' transform='translate(91.955 47.084)'>
          <rect
            id='Rectangle_23'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_24'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649)'
          />
          <rect
            id='Rectangle_25'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.359)'
          />
        </g>
        <g id='Groupe_39' transform='translate(91.955 54.264)'>
          <rect
            id='Rectangle_26'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_27'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649)'
          />
          <rect
            id='Rectangle_28'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.359)'
          />
        </g>
        <g id='Groupe_40' transform='translate(91.955 59.947)'>
          <rect
            id='Rectangle_29'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_30'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649)'
          />
          <rect
            id='Rectangle_31'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.359)'
          />
        </g>
        <g id='Groupe_41' transform='translate(91.955 65.571)'>
          <rect
            id='Rectangle_32'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_33'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649)'
          />
          <rect
            id='Rectangle_34'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.359)'
          />
        </g>
        <g id='Groupe_42' transform='translate(91.955 71.255)'>
          <rect
            id='Rectangle_35'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_36'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649)'
          />
          <rect
            id='Rectangle_37'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.359)'
          />
        </g>
        <rect
          id='Rectangle_38'
          className={classes.cls5}
          width='21.418'
          height='55.999'
          transform='translate(0 25.487)'
        />
        <g id='Groupe_43' transform='translate(6.282 41.461)'>
          <rect
            id='Rectangle_39'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_40'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.59)'
          />
          <rect
            id='Rectangle_41'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.299)'
          />
        </g>
        <g id='Groupe_44' transform='translate(6.282 47.084)'>
          <rect
            id='Rectangle_42'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_43'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.59)'
          />
          <rect
            id='Rectangle_44'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.299)'
          />
        </g>
        <g id='Groupe_45' transform='translate(6.282 54.264)'>
          <rect
            id='Rectangle_45'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_46'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.59)'
          />
          <rect
            id='Rectangle_47'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.299)'
          />
        </g>
        <g id='Groupe_46' transform='translate(6.282 59.947)'>
          <rect
            id='Rectangle_48'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_49'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.59)'
          />
          <rect
            id='Rectangle_50'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.299)'
          />
        </g>
        <g id='Groupe_47' transform='translate(6.282 65.571)'>
          <rect
            id='Rectangle_51'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_52'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.59)'
          />
          <rect
            id='Rectangle_53'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.299)'
          />
        </g>
        <g id='Groupe_48' transform='translate(6.282 71.255)'>
          <rect
            id='Rectangle_54'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_55'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.59)'
          />
          <rect
            id='Rectangle_56'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(7.299)'
          />
        </g>
        <rect
          id='Rectangle_57'
          className={classes.cls1}
          width='28.957'
          height='75.742'
          transform='translate(57.315 5.743)'
        />
        <rect
          id='Rectangle_58'
          className={classes.cls1}
          width='20.521'
          height='9.812'
          transform='translate(61.563)'
        />
        <g id='Groupe_49' transform='translate(60.426 12.743)'>
          <rect
            id='Rectangle_59'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_60'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649)'
          />
          <rect
            id='Rectangle_61'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 5.624)'
          />
          <rect
            id='Rectangle_62'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 5.624)'
          />
          <rect
            id='Rectangle_63'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 11.307)'
          />
          <rect
            id='Rectangle_64'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 11.307)'
          />
          <rect
            id='Rectangle_65'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 16.991)'
          />
          <rect
            id='Rectangle_66'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 16.991)'
          />
          <rect
            id='Rectangle_67'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 24.17)'
          />
          <rect
            id='Rectangle_68'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 24.17)'
          />
          <rect
            id='Rectangle_69'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 29.794)'
          />
          <rect
            id='Rectangle_70'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 29.794)'
          />
          <rect
            id='Rectangle_71'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 35.478)'
          />
          <rect
            id='Rectangle_72'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 35.478)'
          />
          <rect
            id='Rectangle_73'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 41.102)'
          />
          <rect
            id='Rectangle_74'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 41.102)'
          />
        </g>
        <g id='Groupe_50' transform='translate(69.221 12.743)'>
          <rect
            id='Rectangle_75'
            className={classes.cls8}
            width='1.615'
            height='3.649'
          />
          <rect
            id='Rectangle_76'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649)'
          />
          <rect
            id='Rectangle_77'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 5.624)'
          />
          <rect
            id='Rectangle_78'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 5.624)'
          />
          <rect
            id='Rectangle_79'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 11.307)'
          />
          <rect
            id='Rectangle_80'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 11.307)'
          />
          <rect
            id='Rectangle_81'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 16.991)'
          />
          <rect
            id='Rectangle_82'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 16.991)'
          />
          <rect
            id='Rectangle_83'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 24.17)'
          />
          <rect
            id='Rectangle_84'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 24.17)'
          />
          <rect
            id='Rectangle_85'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 29.794)'
          />
          <rect
            id='Rectangle_86'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 29.794)'
          />
          <rect
            id='Rectangle_87'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 35.478)'
          />
          <rect
            id='Rectangle_88'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 35.478)'
          />
          <rect
            id='Rectangle_89'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 41.102)'
          />
          <rect
            id='Rectangle_90'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 41.102)'
          />
        </g>
        <g id='Groupe_51' transform='translate(60.426 12.743)'>
          <rect
            id='Rectangle_91'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(17.589)'
          />
          <rect
            id='Rectangle_92'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(21.239)'
          />
          <rect
            id='Rectangle_93'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(17.589 5.624)'
          />
          <rect
            id='Rectangle_94'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(21.239 5.624)'
          />
          <rect
            id='Rectangle_95'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(17.589 11.307)'
          />
          <rect
            id='Rectangle_96'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(21.239 11.307)'
          />
          <rect
            id='Rectangle_97'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(17.589 16.991)'
          />
          <rect
            id='Rectangle_98'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(21.239 16.991)'
          />
          <rect
            id='Rectangle_99'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(17.589 24.17)'
          />
          <rect
            id='Rectangle_100'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(21.239 24.17)'
          />
          <rect
            id='Rectangle_101'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(17.589 29.794)'
          />
          <rect
            id='Rectangle_102'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(21.239 29.794)'
          />
          <rect
            id='Rectangle_103'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(17.589 35.478)'
          />
          <rect
            id='Rectangle_104'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(21.239 35.478)'
          />
          <rect
            id='Rectangle_105'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(17.589 41.102)'
          />
          <rect
            id='Rectangle_106'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(21.239 41.102)'
          />
          <rect
            id='Rectangle_107'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 48.281)'
          />
          <rect
            id='Rectangle_108'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 48.281)'
          />
          <rect
            id='Rectangle_109'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(0 53.965)'
          />
          <rect
            id='Rectangle_110'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(3.649 53.965)'
          />
          <rect
            id='Rectangle_111'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(8.795 48.281)'
          />
          <rect
            id='Rectangle_112'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(12.444 48.281)'
          />
          <rect
            id='Rectangle_113'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(8.795 53.965)'
          />
          <rect
            id='Rectangle_114'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(12.444 53.965)'
          />
          <rect
            id='Rectangle_115'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(17.589 48.281)'
          />
          <rect
            id='Rectangle_116'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(21.239 48.281)'
          />
          <rect
            id='Rectangle_117'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(17.589 53.965)'
          />
          <rect
            id='Rectangle_118'
            className={classes.cls8}
            width='1.615'
            height='3.649'
            transform='translate(21.239 53.965)'
          />
        </g>
        <rect
          id='Rectangle_119'
          className={classes.cls9}
          width='32.367'
          height='62.4'
          transform='translate(26.504 19.085)'
        />
        <rect
          id='Rectangle_120'
          className={classes.cls9}
          width='22.854'
          height='8.137'
          transform='translate(31.23 14.359)'
        />
        <rect
          id='Rectangle_121'
          className={classes.cls9}
          width='13.102'
          height='8.137'
          transform='translate(36.136 12.145)'
        />
        <g id='Groupe_52' transform='translate(30.333 24.948)'>
          <rect
            id='Rectangle_122'
            className={classes.cls8}
            width='1.256'
            height='2.872'
          />
          <rect
            id='Rectangle_123'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.872)'
          />
          <rect
            id='Rectangle_124'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 4.427)'
          />
          <rect
            id='Rectangle_125'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.872 4.427)'
          />
          <rect
            id='Rectangle_126'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 8.854)'
          />
          <rect
            id='Rectangle_127'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.872 8.854)'
          />
          <rect
            id='Rectangle_128'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 13.222)'
          />
          <rect
            id='Rectangle_129'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.872 13.222)'
          />
          <rect
            id='Rectangle_130'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 18.846)'
          />
          <rect
            id='Rectangle_131'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.872 18.846)'
          />
          <rect
            id='Rectangle_132'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 23.273)'
          />
          <rect
            id='Rectangle_133'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.872 23.273)'
          />
          <rect
            id='Rectangle_134'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 27.7)'
          />
          <rect
            id='Rectangle_135'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.872 27.7)'
          />
          <rect
            id='Rectangle_136'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 32.127)'
          />
          <rect
            id='Rectangle_137'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.872 32.127)'
          />
        </g>
        <g id='Groupe_53' transform='translate(37.691 24.948)'>
          <rect
            id='Rectangle_138'
            className={classes.cls8}
            width='1.256'
            height='2.872'
          />
          <rect
            id='Rectangle_139'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812)'
          />
          <rect
            id='Rectangle_140'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 4.427)'
          />
          <rect
            id='Rectangle_141'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 4.427)'
          />
          <rect
            id='Rectangle_142'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 8.854)'
          />
          <rect
            id='Rectangle_143'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 8.854)'
          />
          <rect
            id='Rectangle_144'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 13.222)'
          />
          <rect
            id='Rectangle_145'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 13.222)'
          />
          <rect
            id='Rectangle_146'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 18.846)'
          />
          <rect
            id='Rectangle_147'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 18.846)'
          />
          <rect
            id='Rectangle_148'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 23.273)'
          />
          <rect
            id='Rectangle_149'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 23.273)'
          />
          <rect
            id='Rectangle_150'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 27.7)'
          />
          <rect
            id='Rectangle_151'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 27.7)'
          />
          <rect
            id='Rectangle_152'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 32.127)'
          />
          <rect
            id='Rectangle_153'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 32.127)'
          />
        </g>
        <g id='Groupe_54' transform='translate(45.05 24.948)'>
          <rect
            id='Rectangle_154'
            className={classes.cls8}
            width='1.256'
            height='2.872'
          />
          <rect
            id='Rectangle_155'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812)'
          />
          <rect
            id='Rectangle_156'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 4.427)'
          />
          <rect
            id='Rectangle_157'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 4.427)'
          />
          <rect
            id='Rectangle_158'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 8.854)'
          />
          <rect
            id='Rectangle_159'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 8.854)'
          />
          <rect
            id='Rectangle_160'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 13.222)'
          />
          <rect
            id='Rectangle_161'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 13.222)'
          />
          <rect
            id='Rectangle_162'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 18.846)'
          />
          <rect
            id='Rectangle_163'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 18.846)'
          />
          <rect
            id='Rectangle_164'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 23.273)'
          />
          <rect
            id='Rectangle_165'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 23.273)'
          />
          <rect
            id='Rectangle_166'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 27.7)'
          />
          <rect
            id='Rectangle_167'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 27.7)'
          />
          <rect
            id='Rectangle_168'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 32.127)'
          />
          <rect
            id='Rectangle_169'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.812 32.127)'
          />
        </g>
        <g id='Groupe_55' transform='translate(30.333 24.948)'>
          <rect
            id='Rectangle_170'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(22.017)'
          />
          <rect
            id='Rectangle_171'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(24.888)'
          />
          <rect
            id='Rectangle_172'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(22.017 4.427)'
          />
          <rect
            id='Rectangle_173'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(24.888 4.427)'
          />
          <rect
            id='Rectangle_174'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(22.017 8.854)'
          />
          <rect
            id='Rectangle_175'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(24.888 8.854)'
          />
          <rect
            id='Rectangle_176'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(22.017 13.222)'
          />
          <rect
            id='Rectangle_177'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(24.888 13.222)'
          />
          <rect
            id='Rectangle_178'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(22.017 18.846)'
          />
          <rect
            id='Rectangle_179'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(24.888 18.846)'
          />
          <rect
            id='Rectangle_180'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(22.017 23.273)'
          />
          <rect
            id='Rectangle_181'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(24.888 23.273)'
          />
          <rect
            id='Rectangle_182'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(22.017 27.7)'
          />
          <rect
            id='Rectangle_183'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(24.888 27.7)'
          />
          <rect
            id='Rectangle_184'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(22.017 32.127)'
          />
          <rect
            id='Rectangle_185'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(24.888 32.127)'
          />
          <rect
            id='Rectangle_186'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 37.751)'
          />
          <rect
            id='Rectangle_187'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.872 37.751)'
          />
          <rect
            id='Rectangle_188'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 42.179)'
          />
          <rect
            id='Rectangle_189'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.872 42.179)'
          />
          <rect
            id='Rectangle_190'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 46.606)'
          />
          <rect
            id='Rectangle_191'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.872 46.606)'
          />
          <rect
            id='Rectangle_192'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(0 51.033)'
          />
          <rect
            id='Rectangle_193'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(2.872 51.033)'
          />
          <rect
            id='Rectangle_194'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(7.359 37.751)'
          />
          <rect
            id='Rectangle_195'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(10.171 37.751)'
          />
          <rect
            id='Rectangle_196'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(7.359 42.179)'
          />
          <rect
            id='Rectangle_197'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(10.171 42.179)'
          />
          <rect
            id='Rectangle_198'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(7.359 46.606)'
          />
          <rect
            id='Rectangle_199'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(10.171 46.606)'
          />
          <rect
            id='Rectangle_200'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(7.359 51.033)'
          />
          <rect
            id='Rectangle_201'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(10.171 51.033)'
          />
          <rect
            id='Rectangle_202'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(14.718 37.751)'
          />
          <rect
            id='Rectangle_203'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(17.53 37.751)'
          />
          <rect
            id='Rectangle_204'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(14.718 42.179)'
          />
          <rect
            id='Rectangle_205'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(17.53 42.179)'
          />
          <rect
            id='Rectangle_206'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(14.718 46.606)'
          />
          <rect
            id='Rectangle_207'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(17.53 46.606)'
          />
          <rect
            id='Rectangle_208'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(14.718 51.033)'
          />
          <rect
            id='Rectangle_209'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(17.53 51.033)'
          />
          <rect
            id='Rectangle_210'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(22.017 37.751)'
          />
          <rect
            id='Rectangle_211'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(24.888 37.751)'
          />
          <rect
            id='Rectangle_212'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(22.017 42.179)'
          />
          <rect
            id='Rectangle_213'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(24.888 42.179)'
          />
          <rect
            id='Rectangle_214'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(22.017 46.606)'
          />
          <rect
            id='Rectangle_215'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(24.888 46.606)'
          />
          <rect
            id='Rectangle_216'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(22.017 51.033)'
          />
          <rect
            id='Rectangle_217'
            className={classes.cls8}
            width='1.256'
            height='2.872'
            transform='translate(24.888 51.033)'
          />
        </g>
        <rect
          id='Rectangle_218'
          className={classes.cls8}
          width='4.068'
          height='4.068'
          transform='translate(3.051 33.982)'
        />
        <rect
          id='Rectangle_219'
          className={classes.cls8}
          width='4.068'
          height='4.068'
          transform='translate(14.179 33.982)'
        />
        <rect
          id='Rectangle_220'
          className={classes.cls8}
          width='4.068'
          height='4.068'
          transform='translate(8.496 33.982)'
        />
        <rect
          id='Rectangle_221'
          className={classes.cls1}
          width='21.658'
          height='7.419'
          transform='translate(0 22.735)'
        />
      </g>
      <path
        id='Tracé_274'
        className={classes.cls7}
        d='M317,449.605c37.512-28.119,97.818-89.8,164.825-91.118s127.313,68.323,179.6,89.8C563.61,463.007,317,449.605,317,449.605Z'
        transform='translate(-127.346 -144.005)'
      />
      <g id='Groupe_57' transform='translate(814.136 237.217)'>
        <rect
          id='Rectangle_222'
          className={classes.cls10}
          width='43.196'
          height='29.375'
          transform='translate(10.829 18.906)'
        />
        <path
          id='Tracé_275'
          className={classes.cls2}
          d='M1424.429,433.912H1378.9V424.1h43.495Z'
          transform='translate(-1368.071 -407.588)'
        />
        <rect
          id='Rectangle_223'
          className={classes.cls3}
          width='3.35'
          height='5.085'
          transform='translate(46.905 34.102)'
        />
        <rect
          id='Rectangle_224'
          className={classes.cls3}
          width='3.35'
          height='5.085'
          transform='translate(37.452 34.102)'
        />
        <rect
          id='Rectangle_225'
          className={classes.cls3}
          width='3.35'
          height='5.085'
          transform='translate(27.999 34.102)'
        />
        <rect
          id='Rectangle_226'
          className={classes.cls3}
          width='3.35'
          height='5.085'
          transform='translate(18.547 34.102)'
        />
        <rect
          id='Rectangle_227'
          className={classes.cls10}
          width='11.128'
          height='35.119'
          transform='translate(0 13.162)'
        />
        <path
          id='Tracé_276'
          className={classes.cls2}
          d='M1360.8,409.662h11.128l-5.744-13.162Z'
          transform='translate(-1360.8 -396.5)'
        />
        <rect
          id='Rectangle_228'
          className={classes.cls3}
          width='3.35'
          height='6.402'
          transform='translate(3.889 16.512)'
        />
      </g>
      <path
        id='Tracé_277'
        className={classes.cls11}
        d='M1363.1,481.919c49.6-33.5,91.177-41.161,128.689-42.9,57.614-2.692,92.494,42.9,131.322,45.589C1573.514,502.022,1363.1,481.919,1363.1,481.919Z'
        transform='translate(-547.588 -176.319)'
      />
      <g id='Groupe_58' transform='translate(852.366 291.481)'>
        <path
          id='Tracé_278'
          className={classes.cls2}
          d='M1425.777,498.361v-4.188h18.248v4.188h1.017V491.6H1424.7v6.761Z'
          transform='translate(-1424.7 -488.968)'
        />
        <circle
          id='Ellipse_9'
          className={classes.cls12}
          cx='1.556'
          cy='1.556'
          r='1.556'
          transform='translate(1.077 0.538)'
        />
        <circle
          id='Ellipse_10'
          className={classes.cls12}
          cx='1.556'
          cy='1.556'
          r='1.556'
          transform='translate(16.213)'
        />
        <circle
          id='Ellipse_11'
          className={classes.cls13}
          cx='1.556'
          cy='1.556'
          r='1.556'
          transform='translate(3.171)'
        />
        <circle
          id='Ellipse_12'
          className={classes.cls13}
          cx='1.556'
          cy='1.556'
          r='1.556'
          transform='translate(13.581 0.538)'
        />
        <ellipse
          id='Ellipse_13'
          className={classes.cls14}
          cx='2.872'
          cy='1.556'
          rx='2.872'
          ry='1.556'
          transform='translate(6.82 0.538)'
        />
      </g>
      <g id='Groupe_59' transform='translate(779.435 277.421)'>
        <path
          id='Tracé_279'
          className={classes.cls2}
          d='M1323.609,476.5v9.094h-16.752V476.5h-.957v15.794h18.666V476.5Z'
          transform='translate(-1304.045 -468.842)'
        />
        <path
          id='Tracé_280'
          className={classes.cls12}
          d='M1325.654,473.272l.06-.179c.06-.18-.18-.539-.18-.539l-2.692-8.854h-17.23l-2.692,8.854-.12.718c.3,1.2,1.436,1.615,2.812,1.615s2.453-.419,2.812-1.615h.179c.3,1.2,1.436,1.615,2.812,1.615s2.453-.419,2.812-1.615h.18c.3,1.2,1.436,1.615,2.812,1.615s2.453-.419,2.812-1.615h.179c.3,1.2,1.436,1.615,2.812,1.615a2.454,2.454,0,0,0,2.633-1.615Z'
          transform='translate(-1302.8 -463.7)'
        />
      </g>
      <g id='Groupe_60' transform='translate(806.119 278.378)'>
        <path
          id='Tracé_281'
          className={classes.cls2}
          d='M1389.233,477.5v8.795h-36.375V477.5h-.957v15.256h38.23V477.5Z'
          transform='translate(-1349.208 -470.201)'
        />
        <path
          id='Tracé_282'
          className={classes.cls13}
          d='M1391.553,474.334c0-.179.06-.3.06-.479h-.18l-2.573-8.555h-38.708l-2.573,8.555h-.18a1.169,1.169,0,0,0,.06.479l-.06.18h.06c.3,1.137,1.376,1.555,2.692,1.555s2.393-.419,2.692-1.555h.179c.3,1.137,1.376,1.555,2.692,1.555s2.393-.419,2.692-1.555h.179c.3,1.137,1.376,1.555,2.692,1.555s2.393-.419,2.692-1.555h.179c.3,1.137,1.376,1.555,2.692,1.555s2.393-.419,2.692-1.555h.179c.3,1.137,1.376,1.555,2.692,1.555s2.393-.419,2.692-1.555h.179c.3,1.137,1.376,1.555,2.692,1.555s2.393-.419,2.692-1.555h.18c.3,1.137,1.376,1.555,2.692,1.555s2.393-.419,2.692-1.555h.18c.3,1.137,1.376,1.555,2.692,1.555s2.393-.419,2.692-1.555h.06Z'
          transform='translate(-1347.4 -465.3)'
        />
      </g>
      <path
        id='Tracé_283'
        className={classes.cls4}
        d='M618.533,361.8c4.487.239,17.589,3.829,30.632,9.812,15.555,7.179,32.008,17.649,46.9,27.341,8.256,11.966,2.932,4.367-37.333,18.427-11.367-13.4-56.537-17.769-56.537-17.769S615.6,367.244,618.533,361.8Z'
        transform='translate(-241.917 -145.343)'
      />
      <path
        id='Tracé_284'
        className={classes.cls15}
        d='M634.3,371.1s33.982,5.8,69.7,34.82'
        transform='translate(-254.813 -149.079)'
      />
      <path
        id='Tracé_285'
        className={classes.cls15}
        d='M629.9,381.6s33.982,5.8,69.7,34.82'
        transform='translate(-253.045 -153.297)'
      />
      <path
        id='Tracé_286'
        className={classes.cls15}
        d='M625.4,392s33.982,5.8,69.7,34.82'
        transform='translate(-251.237 -157.475)'
      />
      <path
        id='Tracé_287'
        className={classes.cls15}
        d='M619.7,405s34.162,4.367,71.135,31.828'
        transform='translate(-248.947 -162.698)'
      />
      <path
        id='Tracé_288'
        className={classes.cls15}
        d='M614.7,416.6s34.221,3.709,71.733,30.452'
        transform='translate(-246.939 -167.358)'
      />
      <path
        id='Tracé_289'
        className={classes.cls16}
        d='M606,476.515C644.888,452.4,663.375,412.2,756.108,412.2c64.315,0,108.587,36.2,147.415,60.306C944.386,497.873,606,476.515,606,476.515Z'
        transform='translate(-243.444 -165.59)'
      />
      <g id='Groupe_61' transform='translate(495.912 215.978)'>
        <rect
          id='Rectangle_229'
          className={classes.cls17}
          width='26.444'
          height='27.042'
          transform='translate(1.855 4.667)'
        />
        <path
          id='Tracé_290'
          className={classes.cls13}
          d='M859.053,374.234h-5.026l-4.308-3.41-4.068,3.41H828.9l1.854-9.034h26.384Z'
          transform='translate(-828.9 -362.687)'
        />
        <rect
          id='Rectangle_230'
          className={classes.cls13}
          width='3.111'
          height='4.667'
          transform='translate(3.709)'
        />
        <rect
          id='Rectangle_231'
          className={classes.cls3}
          width='3.111'
          height='4.667'
          transform='translate(5.564 14)'
        />
        <rect
          id='Rectangle_232'
          className={classes.cls3}
          width='3.111'
          height='4.667'
          transform='translate(13.521 14)'
        />
        <rect
          id='Rectangle_233'
          className={classes.cls2}
          width='4.367'
          height='7.179'
          transform='translate(13.042 24.589)'
        />
        <rect
          id='Rectangle_234'
          className={classes.cls3}
          width='3.111'
          height='4.667'
          transform='translate(21.418 14)'
        />
        <rect
          id='Rectangle_235'
          className={classes.cls3}
          width='3.111'
          height='4.667'
          transform='translate(5.564 21.777)'
        />
        <rect
          id='Rectangle_236'
          className={classes.cls3}
          width='3.111'
          height='4.667'
          transform='translate(21.418 21.777)'
        />
      </g>
      <g id='Groupe_62' transform='translate(526.065 233.867)'>
        <rect
          id='Rectangle_237'
          className={classes.cls11}
          width='24.051'
          height='16.333'
          transform='translate(1.675 4.607)'
        />
        <path
          id='Tracé_291'
          className={classes.cls2}
          d='M906.761,401.884h-4.008l-3.47-3.111-4.248,3.111H879.3l1.675-5.684h24.051Z'
          transform='translate(-879.3 -393.029)'
        />
        <rect
          id='Rectangle_238'
          className={classes.cls2}
          width='2.872'
          height='4.607'
          transform='translate(18.307)'
        />
        <rect
          id='Rectangle_239'
          className={classes.cls2}
          width='4.008'
          height='6.581'
          transform='translate(10.829 14.299)'
        />
        <rect
          id='Rectangle_240'
          className={classes.cls3}
          width='2.872'
          height='4.308'
          transform='translate(4.248 13.461)'
        />
        <rect
          id='Rectangle_241'
          className={classes.cls3}
          width='2.872'
          height='4.308'
          transform='translate(19.145 13.461)'
        />
      </g>
      <path
        id='Tracé_292'
        className={classes.cls3}
        d='M819.072,413.3c8.735,6.7,22.136,16.752,82.443,10.709a8.564,8.564,0,0,1,2.692.9c-24.111,4.487-72.81,6.282-88.007-11.367A21.127,21.127,0,0,1,819.072,413.3Z'
        transform='translate(-327.886 -166.032)'
      />
      <g id='Groupe_63' transform='translate(474.553 231.174)'>
        <rect
          id='Rectangle_242'
          className={classes.cls10}
          width='17.111'
          height='23.991'
          transform='translate(1.675 4.128)'
        />
        <path
          id='Tracé_293'
          className={classes.cls2}
          d='M813.9,398.117h-4.427l-3.829-3.051-3.59,3.051H793.2l1.675-8.017h17.35Z'
          transform='translate(-793.2 -387.886)'
        />
        <rect
          id='Rectangle_243'
          className={classes.cls2}
          width='2.752'
          height='4.128'
          transform='translate(3.291)'
        />
        <rect
          id='Rectangle_244'
          className={classes.cls3}
          width='2.752'
          height='4.128'
          transform='translate(4.966 12.444)'
        />
        <rect
          id='Rectangle_245'
          className={classes.cls2}
          width='3.889'
          height='6.342'
          transform='translate(11.607 21.837)'
        />
        <rect
          id='Rectangle_246'
          className={classes.cls3}
          width='2.752'
          height='4.128'
          transform='translate(12.145 12.444)'
        />
        <rect
          id='Rectangle_247'
          className={classes.cls3}
          width='2.752'
          height='4.128'
          transform='translate(4.966 19.324)'
        />
      </g>
      <g id='Groupe_64' transform='translate(405.752 286.156)'>
        <circle
          id='Ellipse_14'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(2.273)'
        />
        <circle
          id='Ellipse_15'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(5.026 2.094)'
        />
        <circle
          id='Ellipse_16'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(3.47 4.308)'
        />
        <circle
          id='Ellipse_17'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(0.718 4.308)'
        />
        <circle
          id='Ellipse_18'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(0 1.376)'
        />
        <circle
          id='Ellipse_19'
          className={classes.cls18}
          cx='1.795'
          cy='1.795'
          r='1.795'
          transform='translate(3.35 3.291)'
        />
      </g>
      <g id='Groupe_65' transform='translate(420.189 276.714)'>
        <circle
          id='Ellipse_20'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(3.444 4.412) rotate(-61.387)'
        />
        <circle
          id='Ellipse_21'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(4.839 7.555) rotate(-61.387)'
        />
        <circle
          id='Ellipse_22'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(2.405 8.798) rotate(-61.387)'
        />
        <circle
          id='Ellipse_23'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(0 7.433) rotate(-61.387)'
        />
        <circle
          id='Ellipse_24'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(0.786 4.528) rotate(-61.387)'
        />
        <circle
          id='Ellipse_25'
          className={classes.cls18}
          cx='1.795'
          cy='1.795'
          r='1.795'
          transform='translate(3.467 6.592) rotate(-61.387)'
        />
      </g>
      <g id='Groupe_66' transform='translate(447.102 286.324)'>
        <circle
          id='Ellipse_26'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.92, -0.392, 0.392, 0.92, 1.503, 1.971)'
        />
        <circle
          id='Ellipse_27'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.92, -0.392, 0.392, 0.92, 4.891, 2.739)'
        />
        <circle
          id='Ellipse_28'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.92, -0.392, 0.392, 0.92, 4.352, 5.425)'
        />
        <circle
          id='Ellipse_29'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.92, -0.392, 0.392, 0.92, 1.798, 6.565)'
        />
        <circle
          id='Ellipse_30'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.92, -0.392, 0.392, 0.92, 0, 4.111)'
        />
        <circle
          id='Ellipse_31'
          className={classes.cls18}
          cx='1.795'
          cy='1.795'
          r='1.795'
          transform='matrix(0.92, -0.392, 0.392, 0.92, 3.799, 4.554)'
        />
      </g>
      <g id='Groupe_67' transform='translate(498.268 275.525)'>
        <circle
          id='Ellipse_32'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.119, -0.993, 0.993, 0.119, 0, 7.492)'
        />
        <circle
          id='Ellipse_33'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.119, -0.993, 0.993, 0.119, 2.399, 4.989)'
        />
        <circle
          id='Ellipse_34'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.119, -0.993, 0.993, 0.119, 4.492, 6.777)'
        />
        <circle
          id='Ellipse_35'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.119, -0.993, 0.993, 0.119, 4.149, 9.563)'
        />
        <circle
          id='Ellipse_36'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.119, -0.993, 0.993, 0.119, 1.098, 9.872)'
        />
        <circle
          id='Ellipse_37'
          className={classes.cls18}
          cx='1.795'
          cy='1.795'
          r='1.795'
          transform='matrix(0.119, -0.993, 0.993, 0.119, 3.461, 6.832)'
        />
      </g>
      <g id='Groupe_68' transform='translate(520.168 280.133)'>
        <circle
          id='Ellipse_38'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.119, -0.993, 0.993, 0.119, 0, 7.492)'
        />
        <circle
          id='Ellipse_39'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.119, -0.993, 0.993, 0.119, 2.399, 4.989)'
        />
        <circle
          id='Ellipse_40'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.119, -0.993, 0.993, 0.119, 4.492, 6.777)'
        />
        <circle
          id='Ellipse_41'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.119, -0.993, 0.993, 0.119, 4.149, 9.563)'
        />
        <circle
          id='Ellipse_42'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='matrix(0.119, -0.993, 0.993, 0.119, 1.098, 9.872)'
        />
        <circle
          id='Ellipse_43'
          className={classes.cls18}
          cx='1.795'
          cy='1.795'
          r='1.795'
          transform='matrix(0.119, -0.993, 0.993, 0.119, 3.462, 6.832)'
        />
      </g>
      <g id='Groupe_69' transform='translate(465.364 268.178)'>
        <circle
          id='Ellipse_44'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(4.178 4.162) rotate(-45)'
        />
        <circle
          id='Ellipse_45'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(4.682 7.632) rotate(-45)'
        />
        <circle
          id='Ellipse_46'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(1.978 8.107) rotate(-45)'
        />
        <circle
          id='Ellipse_47'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(0 6.141) rotate(-45)'
        />
        <circle
          id='Ellipse_48'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(1.615 3.554) rotate(-45)'
        />
        <circle
          id='Ellipse_49'
          className={classes.cls18}
          cx='1.795'
          cy='1.795'
          r='1.795'
          transform='translate(3.588 6.267) rotate(-45)'
        />
      </g>
      <g id='Groupe_70' transform='translate(474.461 286.325)'>
        <circle
          id='Ellipse_50'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(1.448 2.227) rotate(-26.309)'
        />
        <circle
          id='Ellipse_51'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(4.842 2.832) rotate(-26.309)'
        />
        <circle
          id='Ellipse_52'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(4.44 5.589) rotate(-26.309)'
        />
        <circle
          id='Ellipse_53'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(1.967 6.772) rotate(-26.309)'
        />
        <circle
          id='Ellipse_54'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(0 4.456) rotate(-26.309)'
        />
        <circle
          id='Ellipse_55'
          className={classes.cls18}
          cx='1.795'
          cy='1.795'
          r='1.795'
          transform='translate(3.836 4.723) rotate(-26.309)'
        />
      </g>
      <g id='Groupe_71' transform='translate(494.765 289.463)'>
        <circle
          id='Ellipse_56'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(3.113 4.644) rotate(-67.536)'
        />
        <circle
          id='Ellipse_57'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(4.873 7.668) rotate(-67.536)'
        />
        <circle
          id='Ellipse_58'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(2.584 9.128) rotate(-67.536)'
        />
        <circle
          id='Ellipse_59'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(0 8.064) rotate(-67.536)'
        />
        <circle
          id='Ellipse_60'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(0.487 5.078) rotate(-67.536)'
        />
        <circle
          id='Ellipse_61'
          className={classes.cls18}
          cx='1.795'
          cy='1.795'
          r='1.795'
          transform='translate(3.411 6.793) rotate(-67.536)'
        />
      </g>
      <g id='Groupe_72' transform='translate(446.614 272.755)'>
        <circle
          id='Ellipse_62'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(2.273)'
        />
        <circle
          id='Ellipse_63'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(5.026 2.094)'
        />
        <circle
          id='Ellipse_64'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(3.47 4.308)'
        />
        <circle
          id='Ellipse_65'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(0.718 4.308)'
        />
        <circle
          id='Ellipse_66'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(0 1.376)'
        />
        <circle
          id='Ellipse_67'
          className={classes.cls18}
          cx='1.795'
          cy='1.795'
          r='1.795'
          transform='translate(3.35 3.291)'
        />
      </g>
      <g id='Groupe_73' transform='translate(433.02 264.97)'>
        <circle
          id='Ellipse_68'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(2.931 4.988) rotate(-5.711)'
        />
        <circle
          id='Ellipse_69'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(0 3.189) rotate(-5.711)'
        />
        <circle
          id='Ellipse_70'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(1.311 0.738) rotate(-5.711)'
        />
        <circle
          id='Ellipse_71'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(4.067 0.5) rotate(-5.711)'
        />
        <circle
          id='Ellipse_72'
          className={classes.cls3}
          cx='2.513'
          cy='2.513'
          r='2.513'
          transform='translate(5.025 3.374) rotate(-5.711)'
        />
        <circle
          id='Ellipse_73'
          className={classes.cls18}
          cx='1.795'
          cy='1.795'
          r='1.795'
          transform='translate(3.117 3.058) rotate(-5.711)'
        />
      </g>
      <path
        id='Tracé_294'
        className={classes.cls11}
        d='M506.6,368.771s-10.41,13.76-8.376,27.162a407.082,407.082,0,0,1,62.041,2.333c6.282-13.7,11.965-29.375,16.333-37.811A115.324,115.324,0,0,0,506.6,368.771Z'
        transform='translate(-200.042 -143.939)'
      />
      <g id='Groupe_84' transform='translate(297.918 214.365)'>
        <g id='Groupe_74' className={classes.cls19}>
          <path
            id='Tracé_295'
            className={classes.cls20}
            d='M521.109,367.4S510.4,381.7,510.4,395.519'
            transform='translate(-502.957 -361.958)'
          />
        </g>
        <g id='Groupe_75' className={classes.cls19}>
          <path
            id='Tracé_297'
            className={classes.cls20}
            d='M535.419,358.4S521.3,378.083,521.3,391.9'
            transform='translate(-507.336 -358.342)'
          />
        </g>
        <g id='Groupe_76' className={classes.cls19}>
          <path
            id='Tracé_299'
            className={classes.cls20}
            d='M545.519,358.4S531.4,378.083,531.4,391.9'
            transform='translate(-511.393 -358.342)'
          />
        </g>
        <g id='Groupe_77' className={classes.cls19}>
          <path
            id='Tracé_301'
            className={classes.cls20}
            d='M555.619,358.4S541.5,378.083,541.5,391.9'
            transform='translate(-515.451 -358.342)'
          />
        </g>
        <g id='Groupe_78' className={classes.cls19}>
          <path
            id='Tracé_303'
            className={classes.cls20}
            d='M565.719,358.4S551.6,378.083,551.6,391.9'
            transform='translate(-519.508 -358.342)'
          />
        </g>
        <g id='Groupe_79' className={classes.cls19}>
          <path
            id='Tracé_305'
            className={classes.cls20}
            d='M575.719,358.4s-12.863,19.743-14.119,33.5'
            transform='translate(-523.525 -358.342)'
          />
        </g>
        <g id='Groupe_80' className={classes.cls19}>
          <path
            id='Tracé_307'
            className={classes.cls20}
            d='M585.418,358.4s-13.461,20.222-14.718,33.982'
            transform='translate(-527.181 -358.342)'
          />
        </g>
        <g id='Groupe_81' className={classes.cls19}>
          <path
            id='Tracé_309'
            className={classes.cls20}
            d='M595.518,358.4s-12.624,21.179-14.718,34.82'
            transform='translate(-531.238 -358.342)'
          />
        </g>
        <g id='Groupe_82' className={classes.cls19}>
          <path
            id='Tracé_311'
            className={classes.cls20}
            d='M605.377,358.4s-12.8,21.837-15.077,35.538'
            transform='translate(-535.055 -358.342)'
          />
        </g>
        <g id='Groupe_83' className={classes.cls19}>
          <path
            id='Tracé_313'
            className={classes.cls20}
            d='M614.735,359.3S602.53,379.582,599,394.479'
            transform='translate(-538.55 -358.704)'
          />
        </g>
      </g>
      <path
        id='Tracé_315'
        className={classes.cls21}
        d='M469.3,375.8s-41.879,17.111-64.2,38.529c22.794-5.145,48.46-11.188,55.819-11.367C460.022,399.133,461.159,386.15,469.3,375.8Z'
        transform='translate(-162.738 -150.967)'
      />
      <g id='Groupe_93' transform='translate(242.362 224.833)'>
        <g id='Groupe_85' className={classes.cls22}>
          <path
            id='Tracé_316'
            className={classes.cls23}
            d='M484.8,383.609s9.393-3.709,13.162-3.709'
            transform='translate(-437.117 -377.447)'
          />
        </g>
        <g id='Groupe_86' className={classes.cls22}>
          <path
            id='Tracé_318'
            className={classes.cls23}
            d='M470.2,390.553c5.444-2.034,16.333-5.564,20.1-5.265'
            transform='translate(-431.252 -379.604)'
          />
        </g>
        <g id='Groupe_87' className={classes.cls22}>
          <path
            id='Tracé_320'
            className={classes.cls23}
            d='M456.6,397.442c4.367-2.094,22.017-7.239,26.444-6.581'
            transform='translate(-425.789 -381.827)'
          />
        </g>
        <g id='Groupe_88' className={classes.cls22}>
          <path
            id='Tracé_322'
            className={classes.cls23}
            d='M444.7,403.9s26.025-8.615,31.469-7.837'
            transform='translate(-421.008 -383.922)'
          />
        </g>
        <g id='Groupe_89' className={classes.cls22}>
          <path
            id='Tracé_324'
            className={classes.cls23}
            d='M429.4,412.928c5.564-3.709,35.059-11.607,39.426-10.41'
            transform='translate(-414.862 -386.484)'
          />
        </g>
        <g id='Groupe_90' className={classes.cls22}>
          <path
            id='Tracé_326'
            className={classes.cls23}
            d='M414.8,421.443c6.162-3.111,41.1-13.94,46.666-12.085'
            transform='translate(-408.997 -389.196)'
          />
        </g>
        <g id='Groupe_91' className={classes.cls22}>
          <path
            id='Tracé_328'
            className={classes.cls23}
            d='M403.8,428.189c6.162-2.214,42.777-13.94,53.606-12.384'
            transform='translate(-404.578 -391.814)'
          />
        </g>
        <g id='Groupe_92' className={classes.cls22}>
          <path
            id='Tracé_330'
            className={classes.cls23}
            d='M400.6,432.124c4.607-2.034,43.136-12.145,54.084-12.624'
            transform='translate(-403.292 -393.355)'
          />
        </g>
      </g>
      <g id='Groupe_95' transform='translate(580.508 258.037)'>
        <g id='Groupe_94' transform='translate(7.957 18.965)'>
          <rect
            id='Rectangle_248'
            className={classes.cls2}
            width='1.615'
            height='10.231'
          />
        </g>
        <path
          id='Tracé_332'
          className={classes.cls14}
          d='M988.308,453.5l-3.59-6.761h1.5l-3.171-5.983H984.3l-5.026-9.453-4.966,9.453h1.256l-3.171,5.983h1.5L970.3,453.5Z'
          transform='translate(-970.3 -431.3)'
        />
      </g>
      <g id='Groupe_97' transform='translate(601.508 258.216)'>
        <g id='Groupe_96' transform='translate(4.547 10.829)'>
          <rect
            id='Rectangle_249'
            className={classes.cls2}
            width='0.957'
            height='5.863'
          />
        </g>
        <path
          id='Tracé_333'
          className={classes.cls24}
          d='M1015.69,444.283l-2.034-3.889h.838l-1.795-3.41h.718l-2.872-5.384-2.872,5.384h.718l-1.795,3.41h.838l-2.034,3.889Z'
          transform='translate(-1005.4 -431.6)'
        />
      </g>
      <g id='Groupe_98' transform='translate(772.136 257.259)'>
        <path
          id='Tracé_334'
          className={classes.cls5}
          d='M1292.865,437.417h0c-.18-.06-.239-.179-.18-.3l.6-1.914a.219.219,0,0,1,.3-.12h.06a.219.219,0,0,1,.12.3l-.6,1.915A.456.456,0,0,1,1292.865,437.417Z'
          transform='translate(-1291.428 -432.033)'
        />
        <path
          id='Tracé_335'
          className={classes.cls5}
          d='M1296.739,437.393h0c-.18-.06-.239-.179-.239-.239l.419-1.974c0-.12.12-.179.239-.179h.06c.12,0,.18.12.18.239l-.419,1.974C1296.979,437.333,1296.859,437.393,1296.739,437.393Z'
          transform='translate(-1292.97 -432.009)'
        />
        <path
          id='Tracé_336'
          className={classes.cls5}
          d='M1294.227,437.422h0c-.179.06-.3,0-.3-.12l-.539-1.915c-.06-.12.06-.239.12-.3h.06a.177.177,0,0,1,.24.12l.538,1.915A.219.219,0,0,1,1294.227,437.422Z'
          transform='translate(-1291.714 -432.038)'
        />
        <path
          id='Tracé_337'
          className={classes.cls5}
          d='M1298.027,437.462h0c-.18.06-.3,0-.3-.12l-.538-1.914a.176.176,0,0,1,.12-.239h.06c.12-.06.239.06.3.12l.539,1.914C1298.206,437.343,1298.146,437.462,1298.027,437.462Z'
          transform='translate(-1293.24 -432.078)'
        />
        <path
          id='Tracé_338'
          className={classes.cls5}
          d='M1302.132,433.107c.179-.718-1.5-2.453-2.573-1.5-.479,1.2-.06,1.615.479,1.795S1302.012,433.765,1302.132,433.107Z'
          transform='translate(-1294.115 -430.534)'
        />
        <path
          id='Tracé_339'
          className={classes.cls3}
          d='M1296.583,432.872a1.01,1.01,0,0,0-.18-.6,1.127,1.127,0,0,0,.12-.479,1.009,1.009,0,0,0-1.017-1.017h-.06a.985.985,0,0,0-.9-.479.448.448,0,0,0-.3.06,1.022,1.022,0,0,0-1.556,0,1.127,1.127,0,0,0-.479-.12,5.45,5.45,0,0,0-1.615,1.855,1.053,1.053,0,0,0,.359.778c0,.06-.06.18-.06.239a1.008,1.008,0,0,0,1.017,1.017h.179a.965.965,0,0,0,.778.359h.179a1.263,1.263,0,0,0,.6.239.9.9,0,0,0,.658-.239.887.887,0,0,0,.419.06,1.066,1.066,0,0,0,.957-.6A1.1,1.1,0,0,0,1296.583,432.872Z'
          transform='translate(-1290.6 -430)'
        />
        <circle
          id='Ellipse_74'
          className={classes.cls3}
          cx='0.179'
          cy='0.179'
          r='0.179'
          transform='translate(6.761 1.376)'
        />
        <ellipse
          id='Ellipse_75'
          className={classes.cls3}
          cx='0.239'
          cy='0.658'
          rx='0.239'
          ry='0.658'
          transform='translate(6.103 0.838)'
        />
      </g>
      <g id='Groupe_99' transform='translate(769.682 267.893)'>
        <path
          id='Tracé_340'
          className={classes.cls5}
          d='M1296.5,455.293h0c.18-.06.239-.12.239-.239l-.359-1.974c0-.12-.12-.179-.239-.179h-.06c-.12,0-.18.12-.18.239l.359,1.974C1296.259,455.233,1296.379,455.293,1296.5,455.293Z'
          transform='translate(-1290.275 -449.833)'
        />
        <path
          id='Tracé_341'
          className={classes.cls5}
          d='M1292.719,454.893h0a.219.219,0,0,0,.239-.239l-.179-1.974c0-.12-.12-.18-.239-.18h-.06c-.12,0-.179.12-.179.239l.179,1.974C1292.539,454.833,1292.6,454.893,1292.719,454.893Z'
          transform='translate(-1288.828 -449.673)'
        />
        <path
          id='Tracé_342'
          className={classes.cls5}
          d='M1295.02,455.158h0a.266.266,0,0,0,.359-.12l.778-1.855a.219.219,0,0,0-.12-.3h-.06a.218.218,0,0,0-.3.12l-.778,1.855C1294.9,454.978,1294.9,455.1,1295.02,455.158Z'
          transform='translate(-1289.873 -449.817)'
        />
        <path
          id='Tracé_343'
          className={classes.cls5}
          d='M1291.3,454.858h0a.266.266,0,0,0,.359-.12l.778-1.855a.218.218,0,0,0-.12-.3h-.06a.219.219,0,0,0-.3.12l-.778,1.855A.218.218,0,0,0,1291.3,454.858Z'
          transform='translate(-1288.371 -449.697)'
        />
        <path
          id='Tracé_344'
          className={classes.cls5}
          d='M1286.5,450.049c-.06-.718,1.735-2.273,2.692-1.2.359,1.256-.12,1.615-.658,1.735C1287.935,450.707,1286.559,450.707,1286.5,450.049Z'
          transform='translate(-1286.497 -448.059)'
        />
        <path
          id='Tracé_345'
          className={classes.cls3}
          d='M1289.9,450.3a.831.831,0,0,1,.3-.6,1.171,1.171,0,0,1-.06-.479,1.056,1.056,0,0,1,1.137-.9h.06a1.027,1.027,0,0,1,.9-.359,2.582,2.582,0,0,1,.3.06,1.01,1.01,0,0,1,.838-.239,1.11,1.11,0,0,1,.718.419,1.166,1.166,0,0,1,.479-.06.98.98,0,0,1,.9,1.017,1.027,1.027,0,0,1,.479.957,1.111,1.111,0,0,1-.419.718v.239a1.056,1.056,0,0,1-1.137.9h-.12a1.01,1.01,0,0,1-.838.239.22.22,0,0,1-.18-.06.83.83,0,0,1-.658.12.736.736,0,0,1-.6-.3.887.887,0,0,1-.419.06,1.222,1.222,0,0,1-.9-.718A.864.864,0,0,1,1289.9,450.3Z'
          transform='translate(-1287.86 -447.774)'
        />
        <circle
          id='Ellipse_76'
          className={classes.cls3}
          cx='0.179'
          cy='0.179'
          r='0.179'
          transform='translate(1.029 1.205) rotate(-83.869)'
        />
        <ellipse
          id='Ellipse_77'
          className={classes.cls3}
          cx='0.658'
          cy='0.239'
          rx='0.658'
          ry='0.239'
          transform='translate(1.518 1.724) rotate(-83.869)'
        />
      </g>
      <g id='Groupe_100' transform='translate(792.477 270.346)'>
        <path
          id='Tracé_346'
          className={classes.cls5}
          d='M1334.6,459.393h0c.179-.06.239-.12.239-.239l-.359-1.974c0-.12-.12-.179-.239-.179h-.06c-.12,0-.179.12-.179.239l.359,1.974C1334.359,459.333,1334.479,459.453,1334.6,459.393Z'
          transform='translate(-1328.375 -453.933)'
        />
        <path
          id='Tracé_347'
          className={classes.cls5}
          d='M1330.819,458.993h0a.219.219,0,0,0,.239-.239l-.18-1.974c0-.12-.12-.179-.239-.179h-.06c-.12,0-.179.12-.179.239l.179,1.974C1330.579,458.933,1330.7,458.993,1330.819,458.993Z'
          transform='translate(-1326.928 -453.773)'
        />
        <path
          id='Tracé_348'
          className={classes.cls5}
          d='M1333.1,459.258h0a.266.266,0,0,0,.359-.12l.778-1.855a.219.219,0,0,0-.12-.3h-.06a.219.219,0,0,0-.3.12l-.778,1.855A.219.219,0,0,0,1333.1,459.258Z'
          transform='translate(-1327.957 -453.917)'
        />
        <path
          id='Tracé_349'
          className={classes.cls5}
          d='M1329.4,458.958h0a.266.266,0,0,0,.359-.12l.778-1.855a.219.219,0,0,0-.12-.3h-.06a.219.219,0,0,0-.3.12l-.778,1.855A.218.218,0,0,0,1329.4,458.958Z'
          transform='translate(-1326.471 -453.797)'
        />
        <path
          id='Tracé_350'
          className={classes.cls5}
          d='M1324.6,454.149c-.06-.718,1.735-2.273,2.692-1.2.359,1.256-.12,1.615-.658,1.735C1326.035,454.807,1324.659,454.807,1324.6,454.149Z'
          transform='translate(-1324.598 -452.159)'
        />
        <path
          id='Tracé_351'
          className={classes.cls3}
          d='M1328,454.4a.831.831,0,0,1,.3-.6,1.166,1.166,0,0,1-.06-.479,1.056,1.056,0,0,1,1.137-.9h.06a1.027,1.027,0,0,1,.9-.359,2.6,2.6,0,0,1,.3.06,1.01,1.01,0,0,1,.838-.239,1.11,1.11,0,0,1,.718.419,1.165,1.165,0,0,1,.479-.06.98.98,0,0,1,.9,1.017,1.027,1.027,0,0,1,.479.957,1.11,1.11,0,0,1-.419.718v.239a1.056,1.056,0,0,1-1.137.9h-.12a1.01,1.01,0,0,1-.838.239.22.22,0,0,1-.18-.06.83.83,0,0,1-.658.12.736.736,0,0,1-.6-.3.887.887,0,0,1-.419.06,1.221,1.221,0,0,1-.9-.718A.864.864,0,0,1,1328,454.4Z'
          transform='translate(-1325.96 -451.874)'
        />
        <circle
          id='Ellipse_78'
          className={classes.cls3}
          cx='0.179'
          cy='0.179'
          r='0.179'
          transform='translate(1.015 1.198) rotate(-83.869)'
        />
        <ellipse
          id='Ellipse_79'
          className={classes.cls3}
          cx='0.658'
          cy='0.239'
          rx='0.658'
          ry='0.239'
          transform='translate(1.504 1.717) rotate(-83.869)'
        />
      </g>
      <g id='Groupe_101' transform='translate(802.288 255.15)'>
        <path
          id='Tracé_352'
          className={classes.cls5}
          d='M1351,433.993h0c.18-.06.239-.12.239-.239l-.359-1.974c0-.12-.12-.179-.239-.179h-.06c-.12,0-.18.12-.18.239l.359,1.974C1350.759,433.933,1350.879,434.053,1351,433.993Z'
          transform='translate(-1344.775 -428.533)'
        />
        <path
          id='Tracé_353'
          className={classes.cls5}
          d='M1347.219,433.593h0a.219.219,0,0,0,.239-.239l-.179-1.974c0-.12-.12-.179-.239-.179h-.06c-.12,0-.18.12-.18.239l.18,1.974C1347.039,433.533,1347.1,433.593,1347.219,433.593Z'
          transform='translate(-1343.328 -428.373)'
        />
        <path
          id='Tracé_354'
          className={classes.cls5}
          d='M1349.52,433.858h0a.266.266,0,0,0,.359-.12l.778-1.855a.219.219,0,0,0-.12-.3h-.06a.219.219,0,0,0-.3.12l-.778,1.855A.458.458,0,0,0,1349.52,433.858Z'
          transform='translate(-1344.373 -428.517)'
        />
        <path
          id='Tracé_355'
          className={classes.cls5}
          d='M1345.8,433.558h0a.266.266,0,0,0,.359-.12l.778-1.855a.219.219,0,0,0-.12-.3h-.06a.219.219,0,0,0-.3.12l-.778,1.855A.218.218,0,0,0,1345.8,433.558Z'
          transform='translate(-1342.871 -428.397)'
        />
        <path
          id='Tracé_356'
          className={classes.cls5}
          d='M1341,428.749c-.06-.718,1.735-2.273,2.692-1.2.359,1.256-.12,1.615-.658,1.735C1342.435,429.407,1341.059,429.407,1341,428.749Z'
          transform='translate(-1340.997 -426.759)'
        />
        <path
          id='Tracé_357'
          className={classes.cls3}
          d='M1344.4,429a.83.83,0,0,1,.3-.6,1.167,1.167,0,0,1-.06-.479,1.056,1.056,0,0,1,1.137-.9h.06a1.027,1.027,0,0,1,.9-.359,2.585,2.585,0,0,1,.3.06,1.01,1.01,0,0,1,.838-.239,1.11,1.11,0,0,1,.718.419,1.167,1.167,0,0,1,.479-.06.98.98,0,0,1,.9,1.017,1.027,1.027,0,0,1,.479.957,1.11,1.11,0,0,1-.419.718v.239a1.056,1.056,0,0,1-1.137.9h-.12a1.009,1.009,0,0,1-.838.239.22.22,0,0,1-.18-.06.831.831,0,0,1-.658.12.736.736,0,0,1-.6-.3.886.886,0,0,1-.419.06,1.222,1.222,0,0,1-.9-.718A.864.864,0,0,1,1344.4,429Z'
          transform='translate(-1342.36 -426.474)'
        />
        <circle
          id='Ellipse_80'
          className={classes.cls3}
          cx='0.179'
          cy='0.179'
          r='0.179'
          transform='translate(1.023 1.221) rotate(-83.869)'
        />
        <ellipse
          id='Ellipse_81'
          className={classes.cls3}
          cx='0.658'
          cy='0.239'
          rx='0.658'
          ry='0.239'
          transform='translate(1.512 1.739) rotate(-83.869)'
        />
      </g>
      <g id='Groupe_102' transform='translate(788.817 261.192)'>
        <path
          id='Tracé_358'
          className={classes.cls5}
          d='M1321.639,444.393h0a.219.219,0,0,1-.239-.239l.239-1.974c0-.12.12-.18.239-.18h.06c.12,0,.18.12.18.239l-.239,1.974A.267.267,0,0,1,1321.639,444.393Z'
          transform='translate(-1319.653 -438.754)'
        />
        <path
          id='Tracé_359'
          className={classes.cls5}
          d='M1325.539,443.682h0a.219.219,0,0,1-.239-.239l.12-1.974c0-.12.12-.239.239-.18h.06c.12,0,.239.12.179.239l-.12,1.974C1325.719,443.623,1325.659,443.682,1325.539,443.682Z'
          transform='translate(-1321.22 -438.462)'
        />
        <path
          id='Tracé_360'
          className={classes.cls5}
          d='M1322.781,444.138h0a.335.335,0,0,1-.359-.06l-.838-1.795a.219.219,0,0,1,.12-.3h.06a.218.218,0,0,1,.3.12l.838,1.795C1322.9,443.958,1322.9,444.138,1322.781,444.138Z'
          transform='translate(-1319.718 -438.738)'
        />
        <path
          id='Tracé_361'
          className={classes.cls5}
          d='M1326.481,443.638h0a.335.335,0,0,1-.359-.06l-.838-1.795a.219.219,0,0,1,.12-.3h.06a.218.218,0,0,1,.3.12l.838,1.795A.239.239,0,0,1,1326.481,443.638Z'
          transform='translate(-1321.204 -438.537)'
        />
        <path
          id='Tracé_362'
          className={classes.cls5}
          d='M1329.99,438.617c.06-.718-1.855-2.154-2.752-1.077-.3,1.256.18,1.615.778,1.675C1328.614,439.335,1329.931,439.275,1329.99,438.617Z'
          transform='translate(-1321.962 -436.806)'
        />
        <path
          id='Tracé_363'
          className={classes.cls3}
          d='M1324.535,438.983a1.14,1.14,0,0,0-.3-.6,1.317,1.317,0,0,0,.06-.479,1.027,1.027,0,0,0-1.2-.838h-.06a1.142,1.142,0,0,0-.957-.359.457.457,0,0,0-.3.12,1.072,1.072,0,0,0-.838-.239,1.191,1.191,0,0,0-.718.479,1.167,1.167,0,0,0-.479-.06.953.953,0,0,0-.838,1.077,1.082,1.082,0,0,0-.419,1.017,1.191,1.191,0,0,0,.479.718v.239a1.027,1.027,0,0,0,1.2.838c.06,0,.12,0,.12-.06a1.072,1.072,0,0,0,.838.239.22.22,0,0,0,.179-.06,1.183,1.183,0,0,0,.658.12,1.873,1.873,0,0,0,.6-.3.506.506,0,0,0,.419,0,1.021,1.021,0,0,0,.838-.778C1324.236,440.06,1324.595,439.521,1324.535,438.983Z'
          transform='translate(-1318.481 -436.574)'
        />
        <circle
          id='Ellipse_82'
          className={classes.cls3}
          cx='0.179'
          cy='0.179'
          r='0.179'
          transform='translate(6.532 0.747) rotate(-9.306)'
        />
        <ellipse
          id='Ellipse_83'
          className={classes.cls3}
          cx='0.239'
          cy='0.658'
          rx='0.239'
          ry='0.658'
          transform='translate(5.797 0.34) rotate(-9.306)'
        />
      </g>
      <g id='Groupe_103' transform='translate(765.843 274.833)'>
        <path
          id='Tracé_364'
          className={classes.cls5}
          d='M1283.339,467.093h0a.219.219,0,0,1-.239-.239l.239-1.974c0-.12.12-.18.239-.18h.06c.12,0,.179.12.179.239l-.239,1.974C1283.578,467.033,1283.459,467.093,1283.339,467.093Z'
          transform='translate(-1281.294 -461.514)'
        />
        <path
          id='Tracé_365'
          className={classes.cls5}
          d='M1287.139,466.482h0a.219.219,0,0,1-.239-.239l.12-1.974c0-.12.12-.239.239-.179h.06c.12,0,.239.12.179.239l-.12,1.974A.267.267,0,0,1,1287.139,466.482Z'
          transform='translate(-1282.82 -461.262)'
        />
        <path
          id='Tracé_366'
          className={classes.cls5}
          d='M1284.481,466.938h0a.335.335,0,0,1-.359-.06l-.838-1.795a.219.219,0,0,1,.12-.3h.06a.218.218,0,0,1,.3.12l.838,1.795A.453.453,0,0,1,1284.481,466.938Z'
          transform='translate(-1281.358 -461.538)'
        />
        <path
          id='Tracé_367'
          className={classes.cls5}
          d='M1288.181,466.438h0a.335.335,0,0,1-.359-.06l-.838-1.795a.219.219,0,0,1,.12-.3h.06a.218.218,0,0,1,.3.12l.838,1.795A.454.454,0,0,1,1288.181,466.438Z'
          transform='translate(-1282.845 -461.337)'
        />
        <path
          id='Tracé_368'
          className={classes.cls5}
          d='M1291.69,461.417c.06-.718-1.855-2.154-2.752-1.077-.3,1.256.18,1.615.778,1.675S1291.631,462.015,1291.69,461.417Z'
          transform='translate(-1283.602 -459.606)'
        />
        <path
          id='Tracé_369'
          className={classes.cls3}
          d='M1286.135,461.783a1.14,1.14,0,0,0-.3-.6,1.315,1.315,0,0,0,.06-.479,1.027,1.027,0,0,0-1.2-.838h-.06a1.142,1.142,0,0,0-.957-.359.457.457,0,0,0-.3.12,1.072,1.072,0,0,0-.838-.239,1.191,1.191,0,0,0-.718.479,1.167,1.167,0,0,0-.479-.06.954.954,0,0,0-.838,1.077,1.082,1.082,0,0,0-.419,1.017,1.437,1.437,0,0,0,.419.718v.239a1.027,1.027,0,0,0,1.2.838c.06,0,.12,0,.12-.06a1.072,1.072,0,0,0,.838.239.22.22,0,0,0,.179-.06,1.183,1.183,0,0,0,.658.12,1.871,1.871,0,0,0,.6-.3.506.506,0,0,0,.419,0,1.021,1.021,0,0,0,.838-.778A.852.852,0,0,0,1286.135,461.783Z'
          transform='translate(-1280.081 -459.374)'
        />
        <circle
          id='Ellipse_84'
          className={classes.cls3}
          cx='0.179'
          cy='0.179'
          r='0.179'
          transform='translate(6.596 0.743) rotate(-9.306)'
        />
        <ellipse
          id='Ellipse_85'
          className={classes.cls3}
          cx='0.239'
          cy='0.658'
          rx='0.239'
          ry='0.658'
          transform='translate(5.802 0.345) rotate(-9.306)'
        />
      </g>
      <path
        id='Tracé_370'
        className={classes.cls21}
        d='M1185.4,492.533c2.692-2.333,14.658-12.5,19.743-16.333,9.214,1.855,10.53,16.393,10.53,16.393Z'
        transform='translate(-476.202 -191.3)'
      />
      <path
        id='Tracé_371'
        className={classes.cls11}
        d='M1228.93,481.706h27.76s-1.257-28.478-12.743-32.606c-5.684,2.453-23.931,14.478-25.546,16.154,2.991.658,8.795,3.829,10.53,16.453'
        transform='translate(-489.459 -180.414)'
      />
      <g id='Groupe_108' transform='translate(728.941 268.686)'>
        <g id='Groupe_104' className={classes.cls25}>
          <path
            id='Tracé_372'
            className={classes.cls20}
            d='M1251.9,451.7c3.53.838,10.888,5.5,12.923,28.957'
            transform='translate(-1231.858 -450.144)'
          />
        </g>
        <g id='Groupe_105' className={classes.cls25}>
          <path
            id='Tracé_374'
            className={classes.cls20}
            d='M1242.9,456.4c2.513.838,12.2,3.709,12.744,26.145'
            transform='translate(-1228.242 -452.033)'
          />
        </g>
        <g id='Groupe_106' className={classes.cls25}>
          <path
            id='Tracé_376'
            className={classes.cls20}
            d='M1233.9,462.6c2.513,1.855,11.726,3.171,12.085,22.615'
            transform='translate(-1224.627 -454.523)'
          />
        </g>
        <g id='Groupe_107' className={classes.cls25}>
          <path
            id='Tracé_378'
            className={classes.cls20}
            d='M1226.1,470.2c2.752,1.615,9.213,3.59,10.709,17.948'
            transform='translate(-1221.493 -457.576)'
          />
        </g>
      </g>
      <line
        id='Ligne_13'
        className={classes.cls23}
        x1='14.418'
        y2='10.889'
        transform='translate(718.471 288.968)'
      />
      <line
        id='Ligne_14'
        className={classes.cls23}
        x1='9.872'
        y2='7.538'
        transform='translate(724.693 292.318)'
      />
      <line
        id='Ligne_15'
        className={classes.cls23}
        x1='4.367'
        y2='3.35'
        transform='translate(731.693 296.387)'
      />
      <line
        id='Ligne_16'
        className={classes.cls23}
        x1='16.273'
        y2='12.923'
        transform='translate(713.446 286.455)'
      />
      <g id='Groupe_109' transform='translate(251.097 268.208)'>
        <path
          id='Tracé_380'
          className={classes.cls12}
          d='M528.591,453.8l-1.615.12a.792.792,0,0,0-.778.778v2.632a.845.845,0,0,0-.6.838v2.991a.818.818,0,0,0,.838.838h1.2a.818.818,0,0,0,.838-.838v-2.991a.839.839,0,0,0-.718-.838v-1.855l.778-.06a.792.792,0,0,0,.778-.778A.727.727,0,0,0,528.591,453.8Z'
          transform='translate(-462.242 -450.509)'
        />
        <path
          id='Tracé_381'
          className={classes.cls2}
          d='M504.671,462.637h-9.154a1.048,1.048,0,0,1-1.017-1.017v-2.154a4.953,4.953,0,0,1,4.966-4.966h1.2a4.953,4.953,0,0,1,4.966,4.966v2.154A.959.959,0,0,1,504.671,462.637Z'
          transform='translate(-449.749 -450.791)'
        />
        <path
          id='Tracé_382'
          className={classes.cls13}
          d='M515.854,458.889h-8.5v-6.4a4.2,4.2,0,0,0-4.188-4.188H490.188A4.2,4.2,0,0,0,486,452.488v13.4a.818.818,0,0,0,.838.838s2.752-4.188,7.06-4.128c4.308.12,8.316,3.171,8.316,3.171l4.068,5.564c.12,0,3.649-3.769,7.478-3.769a6.368,6.368,0,0,1,5.444,2.752.818.818,0,0,0,.838-.838v-6.4A4.162,4.162,0,0,0,515.854,458.889Z'
          transform='translate(-446.334 -448.3)'
        />
        <circle
          id='Ellipse_86'
          className={classes.cls2}
          cx='7.478'
          cy='7.478'
          r='7.478'
          transform='translate(40.982 17.47)'
        />
        <circle
          id='Ellipse_87'
          className={classes.cls12}
          cx='4.068'
          cy='4.068'
          r='4.068'
          transform='translate(44.392 20.88)'
        />
        <circle
          id='Ellipse_88'
          className={classes.cls2}
          cx='5.384'
          cy='5.384'
          r='5.384'
          transform='translate(61.862 22.196)'
        />
        <circle
          id='Ellipse_89'
          className={classes.cls12}
          cx='3.47'
          cy='3.47'
          r='3.47'
          transform='translate(63.776 24.111)'
        />
        <path
          id='Tracé_383'
          className={classes.cls2}
          d='M504.971,462.637h-9.154a1.048,1.048,0,0,1-1.017-1.017v-2.154a4.953,4.953,0,0,1,4.966-4.966h1.2a4.953,4.953,0,0,1,4.966,4.966v2.154A.959.959,0,0,1,504.971,462.637Z'
          transform='translate(-449.869 -450.791)'
        />
        <path
          id='Tracé_384'
          className={classes.cls12}
          d='M437.888,481.286c3.829-3.59,8.316-7.718,14.718-1.017l3.41-7.359a3.448,3.448,0,0,0-3.41-3.41h-29.5a3.448,3.448,0,0,0-3.41,3.41l3.291,7.3C428.854,476.619,434.238,476.081,437.888,481.286Z'
          transform='translate(-419.7 -456.817)'
        />
        <circle
          id='Ellipse_90'
          className={classes.cls2}
          cx='5.384'
          cy='5.384'
          r='5.384'
          transform='translate(5.085 22.256)'
        />
        <circle
          id='Ellipse_91'
          className={classes.cls12}
          cx='3.47'
          cy='3.47'
          r='3.47'
          transform='translate(7 24.17)'
        />
        <circle
          id='Ellipse_92'
          className={classes.cls2}
          cx='5.384'
          cy='5.384'
          r='5.384'
          transform='translate(20.401 22.256)'
        />
        <circle
          id='Ellipse_93'
          className={classes.cls12}
          cx='3.47'
          cy='3.47'
          r='3.47'
          transform='translate(22.316 24.17)'
        />
        <path
          id='Tracé_385'
          className={classes.cls2}
          d='M496.069,480.292A9.013,9.013,0,0,0,483.745,477a8.633,8.633,0,0,0-2.932,2.752,11.594,11.594,0,0,0-22.854,1.137h-3.47a8.642,8.642,0,0,0-15.316-.06,8.635,8.635,0,0,0-16.273,4.008,1.017,1.017,0,1,0,2.034,0,6.581,6.581,0,0,1,13.162,0,1.048,1.048,0,0,0,1.017,1.017h0a1.048,1.048,0,0,0,1.017-1.017,6.581,6.581,0,0,1,13.162,0,1.017,1.017,0,1,0,2.034,0,12.632,12.632,0,0,0-.12-1.556H458.5a1.127,1.127,0,0,0,.479.12,1.467,1.467,0,0,0,.718-.239.467.467,0,0,0,.239-.239,1.283,1.283,0,0,0,.239-.718,9.214,9.214,0,1,1,18.427.06h3.47a6.331,6.331,0,0,1,2.812-3.171,6.684,6.684,0,0,1,9.034,2.393,1.137,1.137,0,0,0,1.615.419A1.152,1.152,0,0,0,496.069,480.292Z'
          transform='translate(-420.986 -457.258)'
        />
      </g>
      <g id='Groupe_112' transform='translate(936.184 247.986)'>
        <path
          id='Tracé_386'
          className={classes.cls26}
          d='M1576.265,429.732a2.632,2.632,0,1,0-5.265,0,2.615,2.615,0,0,0,1.316,2.273c-.06.479-.06,1.256.3,1.256.239,0,.3-.538.359-1.017a.888.888,0,0,0,.419.06c0,.359.06.718.3.718.179,0,.3-.3.3-.718.12,0,.3-.06.419-.06,0,.479,0,1.017.3,1.017s.359-.778.359-1.316A2.858,2.858,0,0,0,1576.265,429.732Z'
          transform='translate(-1567.291 -419.562)'
        />
        <path
          id='Tracé_387'
          className={classes.cls27}
          d='M1581.812,422.653a2.464,2.464,0,0,0-2.453-2.453h-9.752a2.436,2.436,0,0,0-1.436.479.456.456,0,0,0-.3-.12c-1.675,0-2.573.359-2.573,2.333v4.786a.538.538,0,1,0,1.077,0v-4.786c0-1.077.18-1.256,1.077-1.316a2.819,2.819,0,0,0-.239,1.017v8.5a1.077,1.077,0,0,0,2.154,0V428.4h6.94v2.692a1.072,1.072,0,0,0,1.077,1.077h0a1.073,1.073,0,0,0,1.077-1.077v-2.632h1.017v2.692a1.077,1.077,0,1,0,2.154,0v-4.308a3.021,3.021,0,0,0,.12-.778v-3.41Z'
          transform='translate(-1565.001 -416.79)'
        />
        <ellipse
          id='Ellipse_94'
          className={classes.cls2}
          cx='1.077'
          cy='0.479'
          rx='1.077'
          ry='0.479'
          transform='matrix(0.5, -0.866, 0.866, 0.5, 12.047, 4.176)'
        />
        <ellipse
          id='Ellipse_95'
          className={classes.cls2}
          cx='0.479'
          cy='1.077'
          rx='0.479'
          ry='1.077'
          transform='matrix(0.966, -0.259, 0.259, 0.966, 18.021, 2.389)'
        />
        <path
          id='Tracé_388'
          className={classes.cls2}
          d='M1592.107,414.5c.06.838-.419,1.376-1.316,1.795h-3.231c-.9-.419-1.376-.957-1.316-1.795a3,3,0,0,0,0,2.932,7.332,7.332,0,0,0,.239,1.2l.658,2.752h4.009l.6-2.752a5.911,5.911,0,0,0,.239-1.137A2.958,2.958,0,0,0,1592.107,414.5Z'
          transform='translate(-1573.261 -414.5)'
        />
        <path
          id='Tracé_389'
          className={classes.cls26}
          d='M1591.769,428.151h-3.29a.515.515,0,0,1-.479-.479l.179-2.094a.515.515,0,0,1,.479-.479h2.991a.515.515,0,0,1,.479.479l.179,2.094A.567.567,0,0,1,1591.769,428.151Z'
          transform='translate(-1574.12 -418.758)'
        />
        <circle
          id='Ellipse_96'
          className={classes.cls28}
          cx='0.179'
          cy='0.179'
          r='0.179'
          transform='translate(14.598 3.649)'
        />
        <circle
          id='Ellipse_97'
          className={classes.cls28}
          cx='0.179'
          cy='0.179'
          r='0.179'
          transform='translate(16.991 3.649)'
        />
        <ellipse
          id='Ellipse_98'
          className={classes.cls28}
          cx='0.359'
          cy='0.06'
          rx='0.359'
          ry='0.06'
          transform='translate(14.897 7.897)'
        />
        <ellipse
          id='Ellipse_99'
          className={classes.cls28}
          cx='0.359'
          cy='0.06'
          rx='0.359'
          ry='0.06'
          transform='translate(16.393 7.897)'
        />
        <path
          id='Tracé_390'
          className={classes.cls26}
          d='M1566.116,431.578s-.778-.479-.957.12l-.359,1.615.419-.479-.12.6.359-.479.12.6.06-.778.6.6.06-.778Z'
          transform='translate(-1564.8 -421.288)'
        />
        <g id='Groupe_111' transform='translate(2.214 3.47)'>
          <g id='Groupe_110' className={classes.cls29}>
            <path
              id='Tracé_391'
              className={classes.cls26}
              d='M1573.658,419.6c-.419.419-1.5,1.017-.957,1.855s1.137,2.034.658,2.872c-.479.778,1.795,1.915,2.932,1.436,1.137-.538,1.077-1.615,2.094-1.795a2.131,2.131,0,0,0,1.376-2.453,3.519,3.519,0,0,1-.479-1.795C1579.282,419.177,1573.658,419.6,1573.658,419.6Z'
              transform='translate(-1570.128 -419.955)'
            />
          </g>
        </g>
      </g>
      <g id='Groupe_115' transform='translate(896.279 256.601)'>
        <path
          id='Tracé_393'
          className={classes.cls26}
          d='M1509.562,443.751a2.635,2.635,0,1,0-5.265-.239,2.608,2.608,0,0,0,1.256,2.333c-.06.479-.06,1.256.239,1.256.239,0,.359-.479.359-1.017a3.82,3.82,0,0,0,.419.12c0,.359.06.718.239.718s.3-.3.359-.718c.12,0,.3-.06.419-.06-.06.479,0,1.017.239,1.017.3,0,.359-.778.419-1.316A2.269,2.269,0,0,0,1509.562,443.751Z'
          transform='translate(-1500.588 -433.759)'
        />
        <path
          id='Tracé_394'
          className={classes.cls27}
          d='M1515.432,437.072a2.453,2.453,0,0,0-2.333-2.513l-9.752-.359a2.385,2.385,0,0,0-1.436.419.458.458,0,0,0-.3-.12c-1.675-.06-2.572.3-2.632,2.214l-.179,4.786a.489.489,0,0,0,.479.539.528.528,0,0,0,.538-.479l.18-4.786c.06-1.077.239-1.256,1.137-1.256a1.966,1.966,0,0,0-.239,1.017l-.12,3.35v.3l-.179,4.846a1.079,1.079,0,1,0,2.154.12l.12-2.692h.239l6.7.239-.12,2.692a1.114,1.114,0,0,0,1.017,1.137h0a1.114,1.114,0,0,0,1.137-1.017l.12-2.692.9.06h.12l-.12,2.692a1.079,1.079,0,0,0,2.154.12l.18-4.308c.06-.239.12-.479.179-.778Z'
          transform='translate(-1498.381 -431.029)'
        />
        <ellipse
          id='Ellipse_100'
          className={classes.cls2}
          cx='1.077'
          cy='0.479'
          rx='1.077'
          ry='0.479'
          transform='translate(12.313 4.157) rotate(-57.75)'
        />
        <ellipse
          id='Ellipse_101'
          className={classes.cls2}
          cx='0.479'
          cy='1.077'
          rx='0.479'
          ry='1.077'
          transform='translate(18.347 2.59) rotate(-12.751)'
        />
        <path
          id='Tracé_395'
          className={classes.cls2}
          d='M1526.065,429.139a1.816,1.816,0,0,1-1.376,1.735c-1.077-.06-1.914-.06-3.231-.12a1.89,1.89,0,0,1-1.256-1.855,3.023,3.023,0,0,0-.12,2.932,3.709,3.709,0,0,0,.239,1.2l.6,2.752,4.008.18.718-2.692a4.214,4.214,0,0,0,.3-1.077A3.226,3.226,0,0,0,1526.065,429.139Z'
          transform='translate(-1506.8 -428.9)'
        />
        <path
          id='Tracé_396'
          className={classes.cls26}
          d='M1525.169,442.771l-3.291-.12a.515.515,0,0,1-.479-.479l.239-2.094a.515.515,0,0,1,.479-.479l2.992.12a.515.515,0,0,1,.479.479l.06,2.094A.44.44,0,0,1,1525.169,442.771Z'
          transform='translate(-1507.46 -433.198)'
        />
        <circle
          id='Ellipse_102'
          className={classes.cls28}
          cx='0.179'
          cy='0.179'
          r='0.179'
          transform='translate(14.924 4.136) rotate(-87.75)'
        />
        <circle
          id='Ellipse_103'
          className={classes.cls28}
          cx='0.179'
          cy='0.179'
          r='0.179'
          transform='translate(17.257 4.2) rotate(-87.75)'
        />
        <ellipse
          id='Ellipse_104'
          className={classes.cls28}
          cx='0.06'
          cy='0.359'
          rx='0.06'
          ry='0.359'
          transform='translate(15.026 8.15) rotate(-87.75)'
        />
        <ellipse
          id='Ellipse_105'
          className={classes.cls28}
          cx='0.06'
          cy='0.359'
          rx='0.06'
          ry='0.359'
          transform='translate(16.536 8.223) rotate(-87.75)'
        />
        <path
          id='Tracé_397'
          className={classes.cls26}
          d='M1499.476,445.268s-.778-.479-.957.06l-.419,1.556.419-.419-.12.6.359-.419.12.6.06-.778.538.658.06-.778Z'
          transform='translate(-1498.1 -435.397)'
        />
        <g id='Groupe_114' transform='translate(2.332 3.109)'>
          <g id='Groupe_113' className={classes.cls30}>
            <path
              id='Tracé_398'
              className={classes.cls26}
              d='M1507.536,433.692c-.419.359-1.5.957-1.017,1.855s1.077,2.094.6,2.872,1.675,2.034,2.872,1.556,1.137-1.556,2.154-1.675a2.05,2.05,0,0,0,1.436-2.393,3.442,3.442,0,0,1-.359-1.795C1513.16,433.573,1507.536,433.692,1507.536,433.692Z'
              transform='translate(-1503.766 -433.93)'
            />
          </g>
        </g>
      </g>
      <g id='Groupe_118' transform='translate(964.631 268.806)'>
        <path
          id='Tracé_400'
          className={classes.cls26}
          d='M1630.5,464.15a2.635,2.635,0,1,1,5.265-.239,2.608,2.608,0,0,1-1.256,2.333c.06.479.06,1.256-.239,1.256-.239,0-.359-.479-.359-1.017a3.827,3.827,0,0,1-.419.12c0,.359-.06.718-.239.718s-.3-.3-.359-.718c-.12,0-.3-.06-.419-.06.06.479,0,1.017-.239,1.017-.3,0-.359-.778-.419-1.316A2.207,2.207,0,0,1,1630.5,464.15Z'
          transform='translate(-1619.639 -454.159)'
        />
        <path
          id='Tracé_401'
          className={classes.cls27}
          d='M1617,457.472a2.453,2.453,0,0,1,2.333-2.513l9.752-.359a2.385,2.385,0,0,1,1.436.419.457.457,0,0,1,.3-.12c1.675-.06,2.573.3,2.632,2.214l.18,4.786a.489.489,0,0,1-.479.538.528.528,0,0,1-.538-.479l-.179-4.786c-.06-1.077-.239-1.256-1.137-1.256a1.965,1.965,0,0,1,.239,1.017l.12,3.35v.3l.179,4.846a1.079,1.079,0,1,1-2.154.12l-.12-2.692h-.239l-6.7.239.12,2.692a1.114,1.114,0,0,1-1.017,1.137h0a1.114,1.114,0,0,1-1.137-1.017l-.12-2.692-.9.06h-.12l.12,2.692a1.079,1.079,0,1,1-2.154.12l-.18-4.308c-.06-.239-.12-.479-.18-.778Z'
          transform='translate(-1614.215 -451.429)'
        />
        <ellipse
          id='Ellipse_106'
          className={classes.cls2}
          cx='0.479'
          cy='1.077'
          rx='0.479'
          ry='1.077'
          transform='translate(5.534 2.802) rotate(-32.25)'
        />
        <ellipse
          id='Ellipse_107'
          className={classes.cls2}
          cx='1.077'
          cy='0.479'
          rx='1.077'
          ry='0.479'
          transform='translate(0 4.483) rotate(-77.249)'
        />
        <path
          id='Tracé_402'
          className={classes.cls2}
          d='M1613.082,449.539a1.816,1.816,0,0,0,1.376,1.735c1.077-.06,1.914-.06,3.231-.12a1.891,1.891,0,0,0,1.256-1.855,3.023,3.023,0,0,1,.12,2.932,3.708,3.708,0,0,1-.239,1.2l-.6,2.752-4.009.18-.718-2.692a4.22,4.22,0,0,1-.3-1.077A3.216,3.216,0,0,1,1613.082,449.539Z'
          transform='translate(-1612.513 -449.3)'
        />
        <path
          id='Tracé_403'
          className={classes.cls26}
          d='M1615.578,463.271l3.291-.12a.515.515,0,0,0,.479-.479l-.239-2.094a.515.515,0,0,0-.479-.479l-2.991.12a.515.515,0,0,0-.479.479l-.06,2.094A.472.472,0,0,0,1615.578,463.271Z'
          transform='translate(-1613.454 -453.639)'
        />
        <circle
          id='Ellipse_108'
          className={classes.cls28}
          cx='0.179'
          cy='0.179'
          r='0.179'
          transform='translate(4.543 3.765) rotate(-2.25)'
        />
        <circle
          id='Ellipse_109'
          className={classes.cls28}
          cx='0.179'
          cy='0.179'
          r='0.179'
          transform='translate(2.151 3.885) rotate(-2.25)'
        />
        <ellipse
          id='Ellipse_110'
          className={classes.cls28}
          cx='0.359'
          cy='0.06'
          rx='0.359'
          ry='0.06'
          transform='translate(4.069 8.019) rotate(-2.25)'
        />
        <ellipse
          id='Ellipse_111'
          className={classes.cls28}
          cx='0.359'
          cy='0.06'
          rx='0.359'
          ry='0.06'
          transform='translate(2.573 8.079) rotate(-2.25)'
        />
        <path
          id='Tracé_404'
          className={classes.cls26}
          d='M1643.16,465.668s.778-.479.957.06l.419,1.556-.419-.419.12.6-.359-.419-.12.6-.06-.778-.538.658-.06-.778Z'
          transform='translate(-1624.702 -455.797)'
        />
        <g id='Groupe_117' transform='translate(2.781 3.109)'>
          <g id='Groupe_116' className={classes.cls31}>
            <path
              id='Tracé_405'
              className={classes.cls26}
              d='M1631.183,454.092c.419.359,1.5.957,1.017,1.855s-1.077,2.094-.6,2.872-1.675,2.034-2.872,1.555-1.137-1.555-2.154-1.675a2.049,2.049,0,0,1-1.436-2.393,3.442,3.442,0,0,0,.359-1.795C1625.439,453.973,1631.183,454.092,1631.183,454.092Z'
              transform='translate(-1620.233 -454.33)'
            />
          </g>
        </g>
      </g>
      <g id='Groupe_119' transform='translate(96.143 240.567)'>
        <rect
          id='Rectangle_250'
          className={classes.cls2}
          width='2.393'
          height='32.606'
          transform='translate(20.7 29.076)'
        />
        <path
          id='Tracé_407'
          className={classes.cls7}
          d='M205.69,421.963a12.154,12.154,0,0,0-12.145-12.145,6.5,6.5,0,0,0-1.077.06,9.764,9.764,0,0,0-19.324,1.974,8.032,8.032,0,0,0,.239,2.094h-.538a12.145,12.145,0,1,0,0,24.29,15.808,15.808,0,0,0,1.735-.12,9.763,9.763,0,0,0,14.179.12,12.17,12.17,0,0,0,8.5-4.667A12.219,12.219,0,0,0,205.69,421.963Z'
          transform='translate(-160.7 -402.1)'
        />
      </g>
      <g id='Groupe_120' transform='translate(191.688 243.26)'>
        <rect
          id='Rectangle_251'
          className={classes.cls2}
          width='2.034'
          height='27.999'
          transform='translate(13.76 24.948)'
        />
        <path
          id='Tracé_408'
          className={classes.cls7}
          d='M354.5,424.129a10.417,10.417,0,0,0-10.41-10.41h-.359a8.41,8.41,0,0,0-14.478-4.427,10.428,10.428,0,0,0-8.854,10.29,10.264,10.264,0,0,0,3.53,7.778,8.407,8.407,0,0,0,5.384,14.837,8.5,8.5,0,0,0,7.658-4.906,12.105,12.105,0,0,0,2.573.3,10.334,10.334,0,0,0,7.837-3.59A10.3,10.3,0,0,0,354.5,424.129Z'
          transform='translate(-320.4 -406.6)'
        />
      </g>
      <path
        id='Tracé_409'
        className={classes.cls3}
        d='M1396.971,200.56c-1.914-7.837-9.931-13.76-19.624-13.76a21.094,21.094,0,0,0-15.854,6.761,18.534,18.534,0,0,0-6.88-1.316,18.243,18.243,0,0,0-18.247,18.247c0,.6.06,1.2.12,1.795a8.919,8.919,0,0,0-5.085,8.017h0a8.937,8.937,0,0,0,8.914,8.914h67.007a9.3,9.3,0,0,0,5.624-1.974,8.879,8.879,0,0,0,3.29-6.7,14.649,14.649,0,0,0,.239-2.273C1416.535,209.834,1408.278,202.774,1396.971,200.56Z'
        transform='translate(-534.854 -75.042)'
      />
      <path
        id='Tracé_410'
        className={classes.cls3}
        d='M456.925,117.9c1.2-4.846,6.1-8.5,12.085-8.5a12.776,12.776,0,0,1,9.752,4.188,11.238,11.238,0,0,1,15.5,10.41,7.242,7.242,0,0,1-.06,1.137,5.466,5.466,0,0,1,3.111,4.966h0a5.49,5.49,0,0,1-5.5,5.5H450.524a5.556,5.556,0,0,1-5.5-5.384,11.615,11.615,0,0,1-.12-1.436C444.9,123.579,449.926,119.272,456.925,117.9Z'
        transform='translate(-178.726 -43.948)'
      />
      <path
        id='Tracé_411'
        className={classes.cls3}
        d='M344.028,234.35c-.838-9.692-12.743-17.35-27.4-17.35-11.008,0-20.521,4.367-24.888,10.649a18.15,18.15,0,0,0-27.581,12.863,12.058,12.058,0,0,0-.658,3.889h0a12.528,12.528,0,0,0,3.051,8.137,12.362,12.362,0,0,0,9.393,4.308h60.845A12.512,12.512,0,0,0,349.233,244.4h0A12.252,12.252,0,0,0,344.028,234.35Z'
        transform='translate(-105.854 -87.174)'
      />
      <path
        id='Tracé_412'
        className={classes.cls27}
        d='M994.522,659.389H513.148A18,18,0,0,1,495.2,641.44v-9.692A18,18,0,0,1,513.148,613.8H994.462a18,18,0,0,1,17.948,17.948v9.692A17.911,17.911,0,0,1,994.522,659.389Z'
        transform='translate(-198.933 -246.577)'
      />
      <path
        id='Tracé_413'
        className={classes.cls27}
        d='M964.778,636.989H287.348A18,18,0,0,1,269.4,619.04v-9.692A18,18,0,0,1,287.348,591.4H964.778a18,18,0,0,1,17.948,17.948v9.692A18,18,0,0,1,964.778,636.989Z'
        transform='translate(-108.224 -237.579)'
      />
      <path
        id='Tracé_414'
        className={classes.cls2}
        d='M1065.2,596.589H61.048A18,18,0,0,1,43.1,578.64v-9.692A18,18,0,0,1,61.048,551H1065.2a18,18,0,0,1,17.948,17.948v9.692A18,18,0,0,1,1065.2,596.589Z'
        transform='translate(-17.314 -221.349)'
      />
      <path
        id='Tracé_415'
        className={classes.cls7}
        d='M1069,548.989H17.948A18,18,0,0,1,0,531.04v-9.692A18,18,0,0,1,17.948,503.4H1069a18,18,0,0,1,17.948,17.948v9.692A18,18,0,0,1,1069,548.989Z'
        transform='translate(0 -202.227)'
      />
      <path
        id='Tracé_416'
        className={classes.cls32}
        d='M242.486,544.518H167.881a6.639,6.639,0,0,1-6.581-6.581v-1.556a6.6,6.6,0,0,1,6.581-6.581h74.605a6.639,6.639,0,0,1,6.581,6.581v1.556A6.562,6.562,0,0,1,242.486,544.518Z'
        transform='translate(-64.798 -212.833)'
      />
      <path
        id='Tracé_417'
        className={classes.cls32}
        d='M1267.386,544.518h-74.605a6.64,6.64,0,0,1-6.581-6.581v-1.556a6.64,6.64,0,0,1,6.581-6.581h74.605a6.639,6.639,0,0,1,6.581,6.581v1.556A6.6,6.6,0,0,1,1267.386,544.518Z'
        transform='translate(-476.523 -212.833)'
      />
      <path
        id='Tracé_418'
        className={classes.cls33}
        d='M780.146,665.518H705.481a6.64,6.64,0,0,1-6.581-6.581v-1.555a6.639,6.639,0,0,1,6.581-6.581h74.605a6.6,6.6,0,0,1,6.581,6.581v1.555A6.512,6.512,0,0,1,780.146,665.518Z'
        transform='translate(-280.764 -261.441)'
      />
      <path
        id='Tracé_419'
        className={classes.cls33}
        d='M928.082,665.518h-34.4a6.639,6.639,0,0,1-6.581-6.581v-1.555a6.639,6.639,0,0,1,6.581-6.581h34.4a6.6,6.6,0,0,1,6.581,6.581v1.555A6.562,6.562,0,0,1,928.082,665.518Z'
        transform='translate(-356.368 -261.441)'
      />
      <path
        id='Tracé_420'
        className={classes.cls32}
        d='M402.906,544.518H360.548c-2.333,0-4.248-3.291-4.248-7.359h0c0-4.068,1.914-7.359,4.248-7.359h42.358c2.333,0,4.248,3.291,4.248,7.359h0C407.154,541.227,405.239,544.518,402.906,544.518Z'
        transform='translate(-143.134 -212.833)'
      />
      <path
        id='Tracé_421'
        className={classes.cls9}
        d='M1061.124,575.6H244.057A15,15,0,0,1,229.1,560.647v-3.59A14.962,14.962,0,0,1,244.057,542.1h817.127a14.962,14.962,0,0,1,14.957,14.957v3.59A15.052,15.052,0,0,1,1061.124,575.6Z'
        transform='translate(-92.035 -217.774)'
      />
      <g id='Groupe_121' transform='translate(545.808 279.457)'>
        <path
          id='Tracé_422'
          className={classes.cls2}
          d='M921.3,503.22l.12.957,1.077-.12v-.239a2.341,2.341,0,0,0,.658.179c.3,0,.479-.06.718-.06.479-.06.419-.419.419-.6,0-.12-.359-.179-.6-.179a4.931,4.931,0,0,1-1.256-.06A7.04,7.04,0,0,1,921.3,503.22Z'
          transform='translate(-915.915 -481.564)'
        />
        <path
          id='Tracé_423'
          className={classes.cls2}
          d='M912.719,501.8l-.419.9.957.479.12-.239a5.036,5.036,0,0,0,.419.479c.239.12.419.179.658.3a.43.43,0,0,0,.658-.239c.06-.12-.239-.359-.419-.479a10.128,10.128,0,0,1-1.017-.658A6.641,6.641,0,0,0,912.719,501.8Z'
          transform='translate(-912.3 -481.042)'
        />
        <path
          id='Tracé_424'
          className={classes.cls34}
          d='M917.1,492.743h-.179a.8.8,0,0,1-.838-.778l-.179-4.427a.8.8,0,0,1,.778-.838h.18a.8.8,0,0,1,.838.778l.179,4.427A.847.847,0,0,1,917.1,492.743Z'
          transform='translate(-913.746 -474.976)'
        />
        <path
          id='Tracé_425'
          className={classes.cls34}
          d='M913.591,499.418l-.18-.06a.863.863,0,0,1-.359-1.077l1.795-3.47a.863.863,0,0,1,1.077-.359l.18.06a.863.863,0,0,1,.359,1.077l-1.795,3.47A.745.745,0,0,1,913.591,499.418Z'
          transform='translate(-912.574 -478.062)'
        />
        <path
          id='Tracé_426'
          className={classes.cls34}
          d='M920.126,493.042l-.179.12a.779.779,0,0,1-1.077-.239l-2.453-3.709a.779.779,0,0,1,.239-1.077l.179-.12a.779.779,0,0,1,1.077.239l2.453,3.709A.779.779,0,0,1,920.126,493.042Z'
          transform='translate(-913.904 -475.454)'
        />
        <path
          id='Tracé_427'
          className={classes.cls34}
          d='M921.756,500.044h-.179a.8.8,0,0,1-.838-.718l-.239-3.889a.8.8,0,0,1,.718-.838h.179a.8.8,0,0,1,.838.718l.239,3.889A.727.727,0,0,1,921.756,500.044Z'
          transform='translate(-915.594 -478.149)'
        />
        <circle
          id='Ellipse_112'
          className={classes.cls10}
          cx='2.094'
          cy='2.094'
          r='2.094'
          transform='translate(1.137 1.314)'
        />
        <path
          id='Tracé_428'
          className={classes.cls2}
          d='M917.894,468.537l-.419.06a16.444,16.444,0,0,0-.9-1.436c-.359-.3-1.556.658-2.872.6a3.778,3.778,0,0,0-.359,1.615l-.479.06a.247.247,0,1,0,.12.479l5.025-.957a.229.229,0,0,0,.18-.3C918.193,468.6,918.074,468.537,917.894,468.537Z'
          transform='translate(-912.45 -467.103)'
        />
        <path
          id='Tracé_429'
          className={classes.cls35}
          d='M916.573,485.277h-.479A2.12,2.12,0,0,1,914,483.183v-3.889a2.12,2.12,0,0,1,2.094-2.094h.479a2.12,2.12,0,0,1,2.094,2.094v3.889A2.119,2.119,0,0,1,916.573,485.277Z'
          transform='translate(-912.983 -471.159)'
        />
        <path
          id='Tracé_430'
          className={classes.cls10}
          d='M917.684,489.083a.733.733,0,0,1-.9.538c-.419-.12.06-.3.18-.718s-.18-.838.179-.718A.848.848,0,0,1,917.684,489.083Z'
          transform='translate(-914.035 -475.564)'
        />
        <path
          id='Tracé_431'
          className={classes.cls34}
          d='M917.418,484.761h-.12a.6.6,0,0,1-.6-.6V478.9a.6.6,0,0,1,.6-.6h.12a.6.6,0,0,1,.6.6v5.265A.6.6,0,0,1,917.418,484.761Z'
          transform='translate(-914.068 -471.601)'
        />
      </g>
      <g id='Groupe_122' transform='translate(877.901 279.237)'>
        <path
          id='Tracé_432'
          className={classes.cls2}
          d='M1472.937,498.274h-.419a.727.727,0,0,1-.718-.718v-7.538a.727.727,0,0,1,.718-.718h.419a.727.727,0,0,1,.718.718v7.538A.77.77,0,0,1,1472.937,498.274Z'
          transform='translate(-1469.156 -475.8)'
        />
        <path
          id='Tracé_433'
          className={classes.cls2}
          d='M1475.031,491.733H1472.7a.92.92,0,0,1-.9-.9V490.3a.92.92,0,0,1,.9-.9h2.333a.92.92,0,0,1,.9.9v.538A.92.92,0,0,1,1475.031,491.733Z'
          transform='translate(-1469.156 -475.84)'
        />
        <path
          id='Tracé_434'
          className={classes.cls2}
          d='M1477.749,498.249l-.419.06a.72.72,0,0,1-.778-.6l-.957-7.478a.721.721,0,0,1,.6-.778l.419-.06a.72.72,0,0,1,.778.6l.957,7.478A.775.775,0,0,1,1477.749,498.249Z'
          transform='translate(-1470.677 -475.835)'
        />
        <circle
          id='Ellipse_113'
          className={classes.cls10}
          cx='2.094'
          cy='2.094'
          r='2.094'
          transform='translate(2.405 1.176)'
        />
        <path
          id='Tracé_435'
          className={classes.cls1}
          d='M1474.788,484.917h-3.051a1.136,1.136,0,0,1-1.137-1.137v-5.743a1.136,1.136,0,0,1,1.137-1.137h3.051a1.136,1.136,0,0,1,1.137,1.137v5.743A1.136,1.136,0,0,1,1474.788,484.917Z'
          transform='translate(-1468.674 -470.819)'
        />
        <path
          id='Tracé_436'
          className={classes.cls12}
          d='M1472.872,467.6c.778,0,2.692.479,2.513,2.154s-.658,2.393-.239,2.872c-.838-.3-.12-1.675-.957-2.333-1.316-.957-1.017-1.675-1.615-1.675-1.436,0-1.077.419-1.316,1.2-.239.718,0,2.154-.957,2.752C1470.958,471.489,1469.223,467.72,1472.872,467.6Z'
          transform='translate(-1468.554 -467.083)'
        />
        <path
          id='Tracé_437'
          className={classes.cls10}
          d='M1485.012,467.791a.764.764,0,0,1-1.017.419c-.419-.18.12-.359.3-.718.179-.419-.12-.9.3-.718A.747.747,0,0,1,1485.012,467.791Z'
          transform='translate(-1473.993 -466.735)'
        />
        <path
          id='Tracé_438'
          className={classes.cls10}
          d='M1467.495,490.953a.8.8,0,0,1,.9-.658c.419.06-.06.359-.12.778s.3.838-.12.778A.77.77,0,0,1,1467.495,490.953Z'
          transform='translate(-1467.423 -476.197)'
        />
        <path
          id='Tracé_439'
          className={classes.cls1}
          d='M1477.849,474.773l-.3-.18a.567.567,0,0,1-.12-.778l3.41-4.966a.567.567,0,0,1,.778-.12l.3.179a.567.567,0,0,1,.12.778l-3.41,4.966A.567.567,0,0,1,1477.849,474.773Z'
          transform='translate(-1471.376 -467.495)'
        />
        <path
          id='Tracé_440'
          className={classes.cls1}
          d='M1468.17,485.548l-.359-.06a.551.551,0,0,1-.419-.658l1.735-7.419a.551.551,0,0,1,.658-.419l.359.06a.551.551,0,0,1,.419.658l-1.735,7.419C1468.828,485.428,1468.469,485.607,1468.17,485.548Z'
          transform='translate(-1467.381 -470.851)'
        />
        <path
          id='Tracé_441'
          className={classes.cls12}
          d='M1473.655,503.778H1472.1a.778.778,0,1,1,1.555,0Z'
          transform='translate(-1469.276 -481.304)'
        />
        <path
          id='Tracé_442'
          className={classes.cls12}
          d='M1479.05,503.518c-.479.06-.9.12-1.555.179a.8.8,0,0,1,.658-.9A.818.818,0,0,1,1479.05,503.518Z'
          transform='translate(-1471.441 -481.223)'
        />
      </g>
      <g id='Groupe_123' transform='translate(554.304 240.735)'>
        <path
          id='Tracé_443'
          className={classes.cls5}
          d='M949.086,426.76c1.316,0,1.974-.06,2.333-.06.419,0,.9.179.9,1.077s.06,1.2.06,1.735-.479.359-.718.359-.419-.838-.479-1.077v-.9a17.174,17.174,0,0,1-1.795.479C948.547,428.495,949.086,426.76,949.086,426.76Z'
          transform='translate(-935.505 -412.15)'
        />
        <circle
          id='Ellipse_114'
          className={classes.cls10}
          cx='1.795'
          cy='1.795'
          r='1.795'
          transform='translate(1.898 0.221) rotate(-0.881)'
        />
        <circle
          id='Ellipse_115'
          className={classes.cls10}
          cx='1.316'
          cy='1.316'
          r='1.316'
          transform='translate(10.939 8.53) rotate(-0.881)'
        />
        <path
          id='Tracé_444'
          className={classes.cls10}
          d='M952.438,423.052a.524.524,0,0,1-.6.3c-.239-.12.06-.18.18-.479s-.06-.538.179-.479A.5.5,0,0,1,952.438,423.052Z'
          transform='translate(-936.643 -410.417)'
        />
        <path
          id='Tracé_445'
          className={classes.cls10}
          d='M939.113,416.595a.686.686,0,0,1,.6.778c-.06.419-.3-.06-.658-.06-.359-.06-.718.3-.658-.06A.616.616,0,0,1,939.113,416.595Z'
          transform='translate(-931.276 -408.088)'
        />
        <path
          id='Tracé_446'
          className={classes.cls2}
          d='M927.8,433v.778h.838V433.6a1.443,1.443,0,0,0,.479.179h.6c.419,0,.359-.3.359-.419s-.3-.179-.479-.179a5.506,5.506,0,0,1-1.017-.12A5.307,5.307,0,0,1,927.8,433Z'
          transform='translate(-927.022 -414.681)'
        />
        <path
          id='Tracé_447'
          className={classes.cls2}
          d='M932.7,432.8v.778h.9V433.4a1.443,1.443,0,0,0,.479.179h.6c.419,0,.419-.3.359-.419,0-.12-.3-.179-.479-.179a5.507,5.507,0,0,1-1.017-.12A7.5,7.5,0,0,0,932.7,432.8Z'
          transform='translate(-928.99 -414.601)'
        />
        <path
          id='Tracé_448'
          className={classes.cls24}
          d='M928.24,417.333l-.359-.06a1.5,1.5,0,0,1-1.2-1.735l1.077-5.325a1.482,1.482,0,0,1,1.735-1.137l.359.06a1.482,1.482,0,0,1,1.137,1.735l-1.077,5.325A1.367,1.367,0,0,1,928.24,417.333Z'
          transform='translate(-926.565 -405.057)'
        />
        <path
          id='Tracé_449'
          className={classes.cls24}
          d='M932.137,411s2.752,2.094,3.291,2.513a1.142,1.142,0,0,1,.419,1.556c-.359.479-.9.419-1.615-.12s-2.932-2.154-2.932-2.154Z'
          transform='translate(-928.428 -405.843)'
        />
        <path
          id='Tracé_450'
          className={classes.cls36}
          d='M947.435,426.126l-.3.06a1.093,1.093,0,0,1-1.316-.778l-.838-3.291c-.12-.6.179-.9.778-1.017l.6.12a1.093,1.093,0,0,1,1.316.778l.778,2.513C947.5,424.809,947.974,426.006,947.435,426.126Z'
          transform='translate(-933.914 -409.901)'
        />
        <path
          id='Tracé_451'
          className={classes.cls36}
          d='M946.487,421.569a.564.564,0,0,1,.9.06c.359.359,1.2,1.316,1.2,1.316a4.564,4.564,0,0,0,.538-.419.514.514,0,0,1,.778.06c.179.3.3.6,0,.778a11.452,11.452,0,0,1-1.256.838c-.239.12-.419-.06-.9-.479s-1.137-1.2-1.316-1.316S946.307,421.749,946.487,421.569Z'
          transform='translate(-934.462 -410.011)'
        />
        <path
          id='Tracé_452'
          className={classes.cls6}
          d='M945.8,424.878l2.214-.06V424.1l-2.154.419-3.829-5.085-.06-2.034-1.974.06v.3l1.735-.06v1.675l.06,5.624Z'
          transform='translate(-931.923 -408.414)'
        />
        <circle
          id='Ellipse_116'
          className={classes.cls1}
          cx='1.496'
          cy='1.496'
          r='1.496'
          transform='translate(9.262 16.012) rotate(-0.881)'
        />
        <circle
          id='Ellipse_117'
          className={classes.cls12}
          cx='1.077'
          cy='1.077'
          r='1.077'
          transform='translate(9.687 16.424) rotate(-0.881)'
        />
        <circle
          id='Ellipse_118'
          className={classes.cls1}
          cx='1.496'
          cy='1.496'
          r='1.496'
          transform='translate(13.509 15.892) rotate(-0.881)'
        />
        <circle
          id='Ellipse_119'
          className={classes.cls12}
          cx='1.077'
          cy='1.077'
          r='1.077'
          transform='translate(13.934 16.305) rotate(-0.881)'
        />
        <path
          id='Tracé_453'
          className={classes.cls2}
          d='M931.7,403.469c-.06.419-1.017.539-1.735.6-.718,0-.6.957-1.2,1.5s-1.795.718-1.915,1.974a.674.674,0,0,1-.239-.419l-.12.419a3.252,3.252,0,0,1,.239-1.017c.179-.3,1.5-.778,1.5-1.316s-.538-2.692,1.615-2.812C932.124,402.273,931.765,403.11,931.7,403.469Z'
          transform='translate(-926.5 -402.381)'
        />
        <path
          id='Tracé_454'
          className={classes.cls12}
          d='M947.514,416.293c-.06-.3.06-.718.359-.718-.479-.12-.838.06-.778.419S947.1,416.233,947.514,416.293Z'
          transform='translate(-934.771 -407.666)'
        />
        <path
          id='Tracé_455'
          className={classes.cls5}
          d='M926.778,419.9c-.239.957.9,2.573.9,3.111s-.359,3.41-.419,4.188.3.9.718,1.017c.419.06.778-.3.9-.838s.539-3.47.658-3.949c.3.359.538.658.538.658v2.872c0,.479.239,1.077.778,1.077a.9.9,0,0,0,.9-.957c.06-.538.179-2.692.179-3.111a11.562,11.562,0,0,0-1.855-3.41A3.222,3.222,0,0,1,926.778,419.9Z'
          transform='translate(-926.598 -409.418)'
        />
      </g>
      <g id='Groupe_124' transform='translate(767.582 280.173)'>
        <path
          id='Tracé_456'
          className={classes.cls36}
          d='M1288.677,500.653h-.239a.818.818,0,0,1-.838-.838v-7.778a.818.818,0,0,1,.838-.838h.239a.818.818,0,0,1,.838.838v7.778A.9.9,0,0,1,1288.677,500.653Z'
          transform='translate(-1284.84 -477.499)'
        />
        <path
          id='Tracé_457'
          className={classes.cls36}
          d='M1290.791,493.553h-2.214a1.072,1.072,0,0,1-1.077-1.077v-.3a1.072,1.072,0,0,1,1.077-1.077h2.214a1.072,1.072,0,0,1,1.077,1.077v.3A1.072,1.072,0,0,1,1290.791,493.553Z'
          transform='translate(-1284.8 -477.459)'
        />
        <path
          id='Tracé_458'
          className={classes.cls36}
          d='M1293.708,500.488l-.24.06a.827.827,0,0,1-.957-.718l-1.017-7.718a.828.828,0,0,1,.718-.957l.24-.06a.827.827,0,0,1,.957.718l1.017,7.718A.871.871,0,0,1,1293.708,500.488Z'
          transform='translate(-1286.402 -477.454)'
        />
        <circle
          id='Ellipse_120'
          className={classes.cls10}
          cx='2.273'
          cy='2.273'
          r='2.273'
          transform='translate(2.401 0.479)'
        />
        <path
          id='Tracé_459'
          className={classes.cls14}
          d='M1290.448,486.255h-2.812a1.456,1.456,0,0,1-1.436-1.436v-5.684a1.456,1.456,0,0,1,1.436-1.436h2.812a1.456,1.456,0,0,1,1.436,1.436v5.684A1.382,1.382,0,0,1,1290.448,486.255Z'
          transform='translate(-1284.278 -472.076)'
        />
        <path
          id='Tracé_460'
          className={classes.cls10}
          d='M1282.995,492.813a.828.828,0,0,1,.957-.718c.479.06-.06.359-.12.838s.359.9-.12.838A.827.827,0,0,1,1282.995,492.813Z'
          transform='translate(-1282.987 -477.856)'
        />
        <path
          id='Tracé_461'
          className={classes.cls14}
          d='M1283.737,486.973l-.179-.06a.656.656,0,0,1-.479-.838l1.854-7.718a.656.656,0,0,1,.838-.479l.179.06a.656.656,0,0,1,.479.838l-1.855,7.718A.8.8,0,0,1,1283.737,486.973Z'
          transform='translate(-1283.012 -472.136)'
        />
        <path
          id='Tracé_462'
          className={classes.cls10}
          d='M1298.325,492.813a.828.828,0,0,0-.957-.718c-.479.06.06.359.12.838s-.359.9.12.838A.828.828,0,0,0,1298.325,492.813Z'
          transform='translate(-1288.685 -477.856)'
        />
        <path
          id='Tracé_463'
          className={classes.cls14}
          d='M1296.071,486.973l.18-.06a.656.656,0,0,0,.479-.838l-1.854-7.718a.656.656,0,0,0-.838-.479l-.18.06a.656.656,0,0,0-.479.838l1.855,7.718A.8.8,0,0,0,1296.071,486.973Z'
          transform='translate(-1287.149 -472.136)'
        />
        <path
          id='Tracé_464'
          className={classes.cls2}
          d='M1289.475,506.438H1287.8a.838.838,0,0,1,1.675,0Z'
          transform='translate(-1284.921 -483.284)'
        />
        <path
          id='Tracé_465'
          className={classes.cls2}
          d='M1295.31,506.113c-.479.06-.957.12-1.615.18a.773.773,0,0,1,.718-.9A.812.812,0,0,1,1295.31,506.113Z'
          transform='translate(-1287.286 -483.199)'
        />
        <path
          id='Tracé_466'
          className={classes.cls2}
          d='M1287.039,471.949l-.239-1.376.239.06-.06-.778.239.419.06-1.137.179.479.06-.658.239.479.12-.538.06-.18.239-.12.18.06.12-.239.06.359.239-.419.06.3.18-.239.239.239.12-.359.239.3.359-.3-.06.538.359-.3.06.479.3-.359.06.658.3-.359-.06.658h.3l.06.3.179.3.179.419v.658l-.359.239v-.12l-.239.359v-1.256a.764.764,0,0,0-.838-.718c-.778.06-1.137.538-2.094.239-.359.12-.778,1.137-.658,2.034C1287.458,472.249,1287.039,471.949,1287.039,471.949Z'
          transform='translate(-1284.519 -468.3)'
        />
      </g>
      <path
        id='Tracé_467'
        className={classes.cls37}
        d='M1512.551,559.2H1370.281a6.6,6.6,0,0,0-6.581,6.581v1.556a6.639,6.639,0,0,0,6.581,6.581h142.271a6.6,6.6,0,0,0,6.581-6.581v-1.556A6.6,6.6,0,0,0,1512.551,559.2Z'
        transform='translate(-547.829 -224.643)'
      />
    </g>
  </svg>
)

export default injectSheet(styles)(FondAccueil)
