import React, { Fragment } from 'react'
import { Grid } from 'react-bootstrap'
import injectSheet from 'react-jss'
import TopBar from '../../../components/top_bar/TopBar'
import Titre1 from '../../../components/font/Titre1'
import P from '../../../components/font/P'
import Titre2 from '../../../components/font/Titre2'
import Titre3 from '../../../components/font/Titre3'
import Footer from '../../../components/footer/Footer'
import { Helmet } from 'react-helmet'

const styles = theme => ({
  wrapper: {
    backgroundColor: theme.backgroundColor,
    minHeight: theme.minHeight,
    paddingBottom: 15
  },
  grid: {
    backgroundColor: 'white',
    margin: '15px auto 0'
  },
  rubrique: {
    color: theme.titre1
  },
  sousRubrique: {
    color: theme.titre1,
    float: 'left',
    margin: '2px 10px 0px 0px'
  }
})

const CGU = ({ classes }) => (
  <Fragment>
    <Helmet>
      <title>Conditions Générales d'Utilisation</title>
    </Helmet>
    <div className={classes.wrapper}>
      <TopBar />
      <Grid className={classes.grid}>
        <Titre1>Conditions Générales d'Utilisation</Titre1>
        <P>
          « Acheter à la source » est un site internet destiné à mettre en
          relation directe particuliers et professionnels, en permettant aux
          professionnels de créer en ligne des profils personnalisables pour y
          présenter et commercialiser leurs produits. « Acheter à la source »
          offre ainsi la possibilité d’accéder aux produits des professionnels
          les plus proches.
        </P>

        {/* I */}
        <Titre2 className={classes.rubrique}>
          I. Acceptation des Conditions Générales d’Utilisation :
        </Titre2>

        <Titre3 className={classes.sousRubrique}>I.1</Titre3>
        <P>
          L’utilisation d’ « Acheter à la source » vaut acceptation, des
          présentes CGU, que l’utilisateur soit un membre d’ « Acheter à la
          source » ou un visiteur du site.
        </P>
        <Titre3 className={classes.sousRubrique}>I.2</Titre3>
        <P>
          « Acheter à la source » se réserve le droit de modifier à tout moment
          les présentes CGU et invite l’utilisateur à les consulter
          régulièrement.
        </P>
        <Titre3 className={classes.sousRubrique}>I.3</Titre3>
        <P>
          Si l’une des clauses des présentes CGU venait à être déclarée nulle
          par une décision de justice, cette nullité ne saurait emporter la
          nullité de l’ensemble des autres clauses, qui continueraient à
          produire leur effet et resteraient valables.
        </P>

        {/* II */}
        <Titre2 className={classes.rubrique}>
          II. Exclusion de responsabilité d’« Acheter à la source » :
        </Titre2>

        <Titre3 className={classes.sousRubrique}>II.1</Titre3>
        <P>
          « Acheter à la source » ne pourra en aucun cas être tenu responsable
          des contenus déposés par l’utilisateur. L’utilisateur en sera le seul
          responsable. « Acheter à la source » ne garantit pas la véracité, la
          probité ou l’honnêteté des informations données par l’utilisateur, il
          ne pourra en aucun cas être tenu pour responsable des fausses
          déclarations faites par un utilisateur et se dégage de toute
          responsabilité suite à l’utilisation d’ « Acheter à la source ». En
          publiant un contenu sur « Acheter à la source » l’utilisateur autorise
          « Acheter à la source » à diffuser, modifier, supprimer et/ou
          reproduire ledit contenu sur son site.
        </P>
        <Titre3 className={classes.sousRubrique}>II.2</Titre3>
        <P>
          Si les données contenues dans le compte producteur venaient à
          disparaître notamment à la suite d’un cas fortuit, d’une panne
          technique ou d’un cas de force majeure, la responsabilité d’ « Acheter
          à la source » ne pourrait en aucun cas être engagée.
        </P>
        <Titre3 className={classes.sousRubrique}>II.3</Titre3>
        <P>
          En cas d’impossibilité d’accès à « Acheter à la source », en raison de
          problèmes de toute nature, l’utilisateur ne pourra se prévaloir
          d’aucun dommage et ne pourra prétendre à aucune indemnité de la part
          d’ « Acheter à la source ».
        </P>
        <Titre3 className={classes.sousRubrique}>II.4</Titre3>
        <P>
          Conformément à l’article L. 3342-1 du Code de la Santé Publique
          prévoyant que la vente d’alcool aux mineurs est interdite, la création
          de compte sur « Acheter à la source » est réservée aux personnes
          morales et aux majeurs de 18 ans et plus. L’utilisateur devra
          strictement appliquer cette réglementation et s’interdit de vendre de
          l’alcool aux mineurs. « Acheter à la source » ne pourra en aucun cas
          être poursuivie de ce fait.
        </P>
        <Titre3 className={classes.sousRubrique}>II.5</Titre3>
        <P>
          Les liens hypertextes présents sur « Acheter à la source » peuvent
          renvoyer sur d’autres sites Internet, la responsabilité d’ « Acheter à
          la source » ne pourra alors en aucun cas être engagée si le contenu de
          ces sites contrevenait aux législations en vigueur. De même, la
          responsabilité d’ « Acheter à la source » ne pourra en aucun cas être
          engagée si la visite, par l’utilisateur, de l’un de ces sites, lui
          causait un préjudice.
        </P>

        {/* III */}
        <Titre2 className={classes.rubrique}>
          III. Le compte producteur :
        </Titre2>

        <Titre3 className={classes.sousRubrique}>III.1</Titre3>
        <P>
          Seules les personnes morales ou majeures peuvent s’inscrire sur «
          Acheter à la source ». En créant un compte producteur, l’utilisateur
          garantit l’exactitude des informations transmises, qu’il est capable
          (au sens juridique) et que son utilisation des services d’ « Acheter à
          la source » n’enfreint aucune loi en vigueur. Tout profil ne
          respectant pas les conditions ci-dessus pourra être supprimé sans
          notification de la part d’ « Acheter à la source ». Certaines
          informations et leur collecte sont indispensables pour que le
          producteur utilise « Acheter à la source » et que le compte producteur
          soit créé. Le refus par un membre de fournir lesdites informations
          aura pour effet d’empêcher la création du compte producteur.
        </P>
        <Titre3 className={classes.sousRubrique}>III.2</Titre3>
        <P>
          L’utilisateur est le seul responsable de l’utilisation de son compte.
          Il avertira sans délai « Acheter à la source » dans le cas d’une
          utilisation frauduleuse de celui-ci. L’utilisateur est seul
          responsable du maintien de la confidentialité de son mot de passe,
          indispensable pour accéder aux comptes producteurs. L’utilisateur
          accepte de ne pas divulguer son nom d’utilisateur ou son mot de passe
          à des tiers.
        </P>
        <Titre3 className={classes.sousRubrique}>III.3</Titre3>
        <P>
          « Acheter à la source » pourra exclure un profil ou interrompre
          l’accès de l’utilisateur s’il représente une menace pour « Acheter à
          la source », ses contributeurs, et utilisateurs. En outre, « Acheter à
          la source » pourra entreprendre des poursuites d’ordre judiciaire à
          l’encontre du membre.
        </P>
        <Titre3 className={classes.sousRubrique}>III.4</Titre3>
        <P>
          « Acheter à la source » pourra renommer l’URL du compte producteur
          s’il le juge nécessaire.
        </P>
        <Titre3 className={classes.sousRubrique}>III.5</Titre3>
        <P>
          Les liens hypertextes indiqués par l’utilisateur renvoyant sur
          d’autres sites Internet, la responsabilité d’ « Acheter à la source »
          ne pourra être en aucun cas engagée si le contenu de ces sites
          contrevenait aux législations en vigueur, comme il est dit à l’article
          II.5
        </P>

        {/* IV */}
        <Titre2 className={classes.rubrique}>
          IV. Responsabilités de l’utilisateur :
        </Titre2>

        <Titre3 className={classes.sousRubrique}>IV.1</Titre3>
        <P>
          L’utilisateur garantit et atteste qu’il ne viole aucune loi notamment
          concernant le droit au respect de la vie privée dont le droit à
          l’image, et les droits d’auteurs. « Acheter à la source » ne pourra en
          aucun cas être poursuivi de ce chef et l’utilisateur le garantit. A
          cet effet, « Acheter à la source » se réserve le droit de retirer du
          site les contenus qu’il jugera contrevenante avec ou sans
          avertissement préalable.
        </P>
        <Titre3 className={classes.sousRubrique}>IV.2</Titre3>
        <P>
          Les contenus et utilisations suivants, sans toutefois s’y limiter,
          sont notamment interdits : Incitation à la haine raciale et à
          l’intolérance contre tout individu ou groupe d’individus ; contenus à
          caractère pornographique ; contenus présentant de la violence gratuite
          ; diffusion et sollicitation de données personnelles pouvant créer un
          risque sur la confidentialité ou de sécurité, notamment par
          l’utilisation des identités de l’équipe du site ; contenus
          diffamatoires, mensongers ou encourageant à l’illégalité ou à
          l’obscénité ; incitation aux chaines de courrier électronique ;
          redirection forcée vers des sites externes ; activités commerciales
          non autorisées telles que concours, tirages au sort ; violation des
          droits à l’image, droit d’auteur et droits à la marque ; envoi de
          fichiers nuisibles et virus ; activités impliquant l’utilisation de
          virus, robots, vers ou programmes informatiques visant à nuire au
          site, à exercer une charge excessive sur « Acheter à la source », ou à
          récolter les données du site et adresses électroniques ; copie,
          téléchargement et transmission de tout ou d’une partie du site ;
          violation de toute loi en vigueur. L’utilisateur est seul responsable
          des contenus ci-dessus.
        </P>
        <Titre3 className={classes.sousRubrique}>IV.3</Titre3>
        <P>
          Les propositions commerciales ou les sollicitations non autorisées
          seront supprimés sans notification et pourront aboutir à la fermeture
          des comptes concernés. La collecte de noms, de données personnelles ou
          de navigation par des utilisateurs autre que l’équipe d’ « Acheter à
          la source » est strictement interdite.
        </P>
        <Titre3 className={classes.sousRubrique}>IV.4</Titre3>
        <P>
          {' '}
          L’utilisateur s’engage à respecter la règlementation concernant les
          prix.
        </P>

        {/* V */}
        <Titre2 className={classes.rubrique}>V. Données personnelles :</Titre2>

        <Titre3 className={classes.sousRubrique}>V.1</Titre3>
        <P>
          Afin de permettre à tous les utilisateurs une navigation efficace sur
          « Acheter à la source » ainsi qu’un meilleur fonctionnement des
          différentes interfaces et applications, « Acheter à la source » pourra
          procéder à l’implantation de cookies sur le poste informatique de
          l’utilisateur. Ces cookies permettent de stocker des informations
          relatives à la navigation sur « Acheter à la source », et
          d’éventuelles données saisies par les utilisateurs au cours de leur
          visite. Ces cookies ont vocation à être conservés sur le poste
          informatique de l’utilisateur pour une durée variable, et pourront
          être lus et utilisés lors d’une visite ultérieur sur « Acheter à la
          source ». En outre, « Acheter à la source » se réserve le droit de
          collecter l’adresse IP (Internet Protocol) de tous utilisateurs
          naviguant sur « Acheter à la source ». La collecte de cette adresse IP
          sera effectuée de façon anonyme, elle sera conservée pour une durée
          variable.
        </P>

        {/* VI */}
        <Titre2 className={classes.rubrique}>
          VI. Propriété intellectuelle du site :
        </Titre2>

        <Titre3 className={classes.sousRubrique}>VI.1</Titre3>
        <P>
          La structure générale d’« Acheter à la source », notamment son nom,
          son logo, sont la propriété exclusive d’ « Acheter à la source ».
          Toute représentation et/ou reproduction et/ou exploitation partielle
          ou totale des contenus et services proposés par « Acheter à la source
          », par quelque procédé que ce soit actuel ou futur, sans son
          autorisation préalable est strictement interdite et serait susceptible
          de constituer une contrefaçon au sens des articles L 335-2 et suivants
          du Code de la propriété intellectuelle.
        </P>

        {/* VII */}
        <Titre2 className={classes.rubrique}>
          VII. Responsabilités de l’utilisateur :
        </Titre2>

        <Titre3 className={classes.sousRubrique}>VII.1</Titre3>
        <P>
          Les présentes CGU, ainsi que les actes qui en seront la conséquence,
          sont soumises au droit français. L’utilisation du service par les
          membres se fera sous leur seule et entière responsabilité.
        </P>
        <Titre3 className={classes.sousRubrique}>VII.2</Titre3>
        <P>
          En cas de litige découlant de l’interprétation ou de l’exécution des
          présentes CGU, une solution à l’amiable sera recherchée avant toute
          procédure judiciaire.
        </P>
      </Grid>
    </div>
    <Footer />
  </Fragment>
)

export default injectSheet(styles)(CGU)
