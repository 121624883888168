import React, { Component } from 'react'
import ForgottenPassword from './ForgottenPassword'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { FORM_ERROR } from 'final-form'

const mutation = gql`
  mutation($mail: String!) {
    recoverPassword(mail: $mail)
  }
`

class ForgottenPasswordContainer extends Component {
  recoverPassword = mail =>
    this.props
      .mutate({
        variables: { mail }
      })
      .then(({ data: { recoverPassword } }) => {
        if (recoverPassword) {
          return { [FORM_ERROR]: recoverPassword }
        }
      })

  render() {
    return <ForgottenPassword recoverPassword={this.recoverPassword} />
  }
}

export default graphql(mutation)(ForgottenPasswordContainer)
