import React from 'react'
import injectSheet from 'react-jss'

const styles = theme => ({
  h1: {
    fontWeight: theme.extraBold,
    color: theme.titre1,
    fontSize: theme.tailleMoyenTexte
  }
})

const Titre = ({
  classes,
  className,
  children,
  component: Component = 'h1',
  ...props
}) => (
  <Component {...props} className={`${classes.h1} ${className || ''}`}>
    {children}
  </Component>
)

export default injectSheet(styles)(Titre)
