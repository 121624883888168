import React, { Fragment } from 'react'
import { Grid } from 'react-bootstrap'
import injectSheet from 'react-jss'
import TopBar from '../../../components/top_bar/TopBar'
import Titre1 from '../../../components/font/Titre1'
import P from '../../../components/font/P'
import { Link } from 'react-router-dom'
import routes from '../../../routing/routes'
import Footer from '../../../components/footer/Footer'
import { Helmet } from 'react-helmet'

const styles = theme => ({
  wrapper: {
    backgroundColor: theme.backgroundColor,
    minHeight: theme.minHeight,
    paddingBottom: 15
  },
  grid: {
    backgroundColor: 'white',
    textAlign: 'justify',
    margin: '15px auto 0'
  }
})

const About = ({ classes }) => (
  <Fragment>
    <Helmet>
      <title>A propos d'acheteralasource</title>
    </Helmet>
    <div className={classes.wrapper}>
      <TopBar />
      <Grid className={classes.grid}>
        <Titre1>À propos d'acheter à la source</Titre1>
        <P>
          Nous sommes une équipe d'informaticiens qui développons des
          applications web sur-mesure. Nous proposons nos compétences
          principalement aux PME qui ont des besoins de logiciels ou de sites
          internet. Acheteralasource.com ne rentre pas dans le cadre de cette
          activité. Nous avons eu cette idée il y a quelques années et l’avons
          réalisée en essayant modestement d'utiliser un peu de temps et de
          compétences pour créer un service utile à la société : mettre
          gratuitement en relation directe les producteurs et les consommateurs.
          C'est pourquoi il n'existe pas de support téléphonique. Toutefois,
          nous tâchons de répondre par <Link to={routes.contact}>e-mail</Link>{' '}
          aussi rapidement que possible. Le projet se veut communautaire : nous
          avons de l’énergie et des idées mais toute votre énergie et vos bonnes
          idées sont les bienvenues pour progresser ensemble. Nous serions
          heureux de recevoir vos contributions : détection d'erreurs, de bugs,
          idées de fonctionnalités, encouragements! Nous ne sommes “que” des
          informaticiens. En conséquence, nous ne sommes pas des producteurs :
          c’est-à-dire que nous ne connaissons pas toutes leurs contraintes et
          tous leurs besoins. Vos retours peuvent nous ouvrir les yeux sur des
          choses encore inconnues pour nous et nous aider à améliorer acheter à
          la source de manière utile. La critique constructive ne fait de mal à
          personne, alors allez-y et construisons ensemble !
          <br />
          <Link to={routes.contact}>Contact</Link>
          <br />
          La première contributrice est{' '}
          <a href='http://www.kitchenette-graphisme.com/' target='_blank'>
            Nina
          </a>
          , qui a réalisé (gratuitement s’il faut le préciser) le design
          d’acheter à la source. Merci et bienvenue aux suivants :-)
        </P>
      </Grid>
    </div>
    <Footer />
  </Fragment>
)

export default injectSheet(styles)(About)
