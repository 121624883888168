import React from 'react'
import injectSheet from 'react-jss'

const styles = () => ({
  cls1: {
    fontSize: 20,
    fontFamily: 'NunitoSans-Bold, Nunito Sans',
    fontWeight: 700,
    fill: '#fff'
  },
  cls2: {
    fill: '#fff'
  }
})

const SvgCompteActifAFBlanc = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='925 48.199 150 36.801'
  >
    <text className={classes.cls1} transform='translate(960 78)'>
      <tspan x='0' y='0'>
        Mon compte
      </tspan>
    </text>
    <g transform='translate(699 38.009)'>
      <g transform='translate(230.019 14.802)'>
        <path
          className={classes.cls2}
          d='M22.932,33.7H4.6V31.866A9.165,9.165,0,0,1,13.766,22.7h0a9.165,9.165,0,0,1,9.166,9.166Z'
          transform='translate(-4.6 -7.511)'
        />
        <circle
          className={classes.cls2}
          cx='6.809'
          cy='6.809'
          r='6.809'
          transform='translate(2.357)'
        />
      </g>
      <path
        className={classes.cls2}
        d='M19.292,4.541a3.581,3.581,0,0,0,.087-.96C19.117,1.224,15.538-.347,11.435.089,7.245.526,4.1,2.8,4.364,5.153a1.76,1.76,0,0,0,.262.96C1.745,6.811-.088,7.684,0,8.47c.175,1.309,5.674,1.746,12.4.96,6.722-.7,12.047-2.27,11.872-3.579C24.18,5.065,22.26,4.629,19.292,4.541Z'
        transform='translate(226.003 10.174)'
      />
      <g transform='translate(241.1 20.738)'>
        <path
          className={classes.cls2}
          d='M28.114,13.086a1.5,1.5,0,0,1-.524.524,1.211,1.211,0,0,1,0-.786.787.787,0,0,1,.524-.524A1.211,1.211,0,0,1,28.114,13.086Z'
          transform='translate(-18.594 -12.125)'
        />
        <path
          className={classes.cls2}
          d='M29.5,12.8a1.078,1.078,0,0,1-.7.349.991.991,0,0,1,.262-.7.907.907,0,0,1,.7-.349A1.667,1.667,0,0,1,29.5,12.8Z'
          transform='translate(-18.756 -12.1)'
        />
        <path
          className={classes.cls2}
          d='M28.6,15.162a.991.991,0,0,0-.7-.262,1.078,1.078,0,0,0,.349.7.991.991,0,0,0,.7.262A1.315,1.315,0,0,0,28.6,15.162Z'
          transform='translate(-18.641 -12.456)'
        />
        <path
          className={classes.cls2}
          d='M26.814,13.386a1.5,1.5,0,0,1-.524.524,1.211,1.211,0,0,1,0-.786,1.019,1.019,0,0,1,.524-.524A.816.816,0,0,1,26.814,13.386Z'
          transform='translate(-18.429 -12.164)'
        />
        <path
          className={classes.cls2}
          d='M27.3,15.462a.991.991,0,0,0-.7-.262,1.078,1.078,0,0,0,.349.7.991.991,0,0,0,.7.262C27.56,15.811,27.473,15.549,27.3,15.462Z'
          transform='translate(-18.476 -12.494)'
        />
        <path
          className={classes.cls2}
          d='M29.886,14.79a1.2,1.2,0,0,0-.786,0,1.019,1.019,0,0,0,.524.524,1.2,1.2,0,0,0,.786,0A.787.787,0,0,0,29.886,14.79Z'
          transform='translate(-18.794 -12.433)'
        />
        <path
          className={classes.cls2}
          d='M30.386,13.9a.849.849,0,0,1-.786-.087.923.923,0,0,1,.611-.524.849.849,0,0,1,.786.087A1.636,1.636,0,0,1,30.386,13.9Z'
          transform='translate(-18.857 -12.244)'
        />
        <rect
          className={classes.cls2}
          width='10.65'
          height='0.436'
          transform='translate(0 3.806) rotate(-12.838)'
        />
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(SvgCompteActifAFBlanc)
