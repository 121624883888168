import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: '#0091a3'
  }
}

const CompteActifVert = ({ taille, classes, ...props }) => (
  <svg width={taille} {...props} viewBox='780 2101 21.06 23.81'>
    <g
      id='Symbole_8_5'
      data-name='Symbole 8 – 5'
      transform='translate(298 487)'
    >
      <path
        className={classes.cls1}
        d='M11.687,22.7A7.085,7.085,0,0,0,4.6,29.786V31.2H18.773V29.786A7.085,7.085,0,0,0,11.687,22.7Z'
        transform='translate(480.507 1606.607)'
      />
      <path
        className={classes.cls1}
        d='M4.926,8.846a5.271,5.271,0,0,0,5.264,5.264,5.2,5.2,0,0,0,4.927-3.442l4.657-1.08-.067-.337-4.455,1.012a5.136,5.136,0,0,0,.2-1.417,4.991,4.991,0,0,0-.607-2.429c2.43-.607,3.982-1.282,3.915-1.889s-1.62-.945-3.915-1.012a2.768,2.768,0,0,0,.067-.742c-.2-1.822-2.97-3.037-6.142-2.7s-5.6,2.092-5.4,3.914a1.36,1.36,0,0,0,.2.742C1.349,5.269-.069,5.944,0,6.551c.067.675,2.092,1.08,5.062,1.012A9.22,9.22,0,0,0,4.926,8.846Z'
        transform='translate(482.003 1613.983)'
      />
      <path
        className={classes.cls1}
        d='M27.576,12.7a.93.93,0,0,0,0,.607.788.788,0,0,0,.4-.4.641.641,0,0,0,0-.607A.608.608,0,0,0,27.576,12.7Z'
        transform='translate(473.055 1609.989)'
      />
      <path
        className={classes.cls1}
        d='M29.542,12a.833.833,0,0,0-.54.27.766.766,0,0,0-.2.54.833.833,0,0,0,.54-.27A.814.814,0,0,0,29.542,12Z'
        transform='translate(472.64 1610.087)'
      />
      <path
        className={classes.cls1}
        d='M27.8,14.9a.833.833,0,0,0,.27.54.766.766,0,0,0,.54.2.833.833,0,0,0-.27-.54C28.272,14.967,28,14.9,27.8,14.9Z'
        transform='translate(472.965 1609.144)'
      />
      <path
        className={classes.cls1}
        d='M26.276,13a.93.93,0,0,0,0,.607.788.788,0,0,0,.4-.4.641.641,0,0,0,0-.607A1.146,1.146,0,0,0,26.276,13Z'
        transform='translate(473.477 1609.891)'
      />
      <path
        className={classes.cls1}
        d='M26.5,15.2a.833.833,0,0,0,.27.54.766.766,0,0,0,.54.2.833.833,0,0,0-.27-.54A.766.766,0,0,0,26.5,15.2Z'
        transform='translate(473.388 1609.046)'
      />
      <path
        className={classes.cls1}
        d='M29.707,14.776a.931.931,0,0,0-.607,0,.788.788,0,0,0,.4.4.931.931,0,0,0,.607,0A.608.608,0,0,0,29.707,14.776Z'
        transform='translate(472.543 1609.201)'
      />
      <path
        className={classes.cls1}
        d='M30.072,13.379a.907.907,0,0,0-.472.4,1.869,1.869,0,0,0,.607.067.907.907,0,0,0,.472-.4A.681.681,0,0,0,30.072,13.379Z'
        transform='translate(472.38 1609.653)'
      />
    </g>
  </svg>
)

export default injectSheet(styles)(CompteActifVert)
