import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  },
  cls2: {
    fill: '#fca',
    stroke: '#1d1d1b',
    strokeMiterlimit: '10',
    strokeWidth: '0.73px'
  }
}

const Mouton = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-3236.136 1232.485 97.745 97.745'
  >
    <g transform='translate(-3914.5 1075.543)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(678.863 157.442)'
      />
      <g transform='translate(686.167 169.933)'>
        <g>
          <path
            className={classes.cls2}
            d='M1039,266.4'
            transform='translate(-980.589 -245.281)'
          />
          <g transform='translate(41.642 70.71)'>
            <path
              fill='#fca'
              d='M1015.6,335.6'
              transform='translate(-1015.6 -335.6)'
            />
          </g>
          <path
            fill='#fca'
            d='M1006.194,310.07s-.645,3.726,6.736,3.3c20.5-2.437,17.128-38.626,11.179-51.956l-18.2-7.6.788-.215-19.134,8.1c-7.668,13.9-8.385,50.666,12.971,51.382,6.45.717,5.661-3.01,5.661-3.01'
            transform='translate(-964.623 -241.654)'
          />
          <path
            fill='#fff'
            d='M981.576,263.553l-1.72-3.153c-12.469,1.863-22.646,15.551-22.359,23.219,0,0,13.4,3.87,17.916-2.365Z'
            transform='translate(-957.492 -243.581)'
          />
          <path
            fill='#fff'
            d='M1039.9,265.081l1.648-3.081c12.4,2.365,22,16.411,21.427,24.079,0,0-13.759,3.225-18.059-3.153Z'
            transform='translate(-980.844 -244.034)'
          />
          <g transform='translate(17.75)'>
            <path
              fill='#fff'
              d='M1016.974,241.208c4.586-3.225,8.815-1.577,8.528.932,5.3,0,3.87,4.586,3.153,4.945,4.443,2.58,1.218,9.316-2.078,8.815,1,3.368-3.3,6.736-6.235,4.228-4.013,2.293-6.593-1.72-7.095-4.013-1.29,2.15-7.668,3.8-8.958.932-.358,2.58-7.74,5.948-11.9,1.577-5.876,1.075-8.743-2.58-7.955-5.8-2.723-.072-3.44-6.808,1.075-7.31-.43-4.013,6.88-7.811,11.036-5.016.5-3.153,6.306-5.088,9.03-2.007,2.651-2.652,10.176-1.792,11.394,2.723Z'
              transform='translate(-982.26 -236.93)'
            />
          </g>
          <g transform='translate(34.092 55.876)'>
            <path
              fill='#4c2f21'
              d='M1005.448,317.265a4.144,4.144,0,0,1-.358-1,1.2,1.2,0,0,1,.287-1.075,1.452,1.452,0,0,1,1.075-.287,2.875,2.875,0,0,1,1.075.215,6.211,6.211,0,0,1,1.935,1,4.105,4.105,0,0,1,.788.788,2.031,2.031,0,0,1,.43,1.147h0a6.925,6.925,0,0,1-.072,1.72,4.41,4.41,0,0,1-.5,1.648h-.072a12.649,12.649,0,0,0,0-1.648c0-.573-.072-1.075-.072-1.648h0a2.321,2.321,0,0,0-.932-1.5,5.174,5.174,0,0,0-1.72-1,2.207,2.207,0,0,0-.932-.215,1.433,1.433,0,0,0-.932.143.863.863,0,0,0-.287.86c.072.215.215.573.287.86Z'
              transform='translate(-1005.064 -314.9)'
            />
          </g>
          <g transform='translate(43.404 55.732)'>
            <path
              fill='#4c2f21'
              d='M1023.32,317.208a5.275,5.275,0,0,0,.287-1,1.142,1.142,0,0,0-.287-.86,1.3,1.3,0,0,0-.932-.143c-.358.072-.645.143-.932.215a5.171,5.171,0,0,0-1.72,1,2.652,2.652,0,0,0-.932,1.5h0a12.148,12.148,0,0,0-.072,1.648v1.648h-.072a11.079,11.079,0,0,1-.5-1.648,6.922,6.922,0,0,1-.072-1.72h0a2.031,2.031,0,0,1,.43-1.147,4.106,4.106,0,0,1,.788-.788,6.213,6.213,0,0,1,1.935-1,5.715,5.715,0,0,1,1.075-.215,1.866,1.866,0,0,1,1.075.287,1.2,1.2,0,0,1,.287,1.075,1.683,1.683,0,0,1-.358,1.147Z'
              transform='translate(-1018.059 -314.7)'
            />
          </g>
        </g>
        <circle
          fill='#4c2f21'
          cx='3.44'
          cy='3.44'
          r='3.44'
          transform='translate(29.674 34.735)'
        />
        <circle
          fill='#4c2f21'
          cx='3.44'
          cy='3.44'
          r='3.44'
          transform='translate(46.802 34.735)'
        />
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(Mouton)
