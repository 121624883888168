import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  },
  cls2: {
    fill: '#f9dc05',
    stroke: '#fff',
    strokeMiterlimit: '10',
    strokeWidth: '3px'
  }
}

const Miel = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-3399.027 1075.543 97.745 97.745'
  >
    <g transform='translate(-3914.5 1075.543)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(515.973 0.5)'
      />
      <g transform='translate(534.964 18.487)'>
        <path
          className={classes.cls2}
          d='M788.557,82.1H776.876L771,92.276l5.876,10.1h11.681l5.8-10.1Z'
          transform='translate(-753.443 -41.61)'
        />
        <path
          className={classes.cls2}
          d='M788.557,53.9H776.876L771,64l5.876,10.1h11.681l5.8-10.1Z'
          transform='translate(-753.443 -33.619)'
        />
        <path
          className={classes.cls2}
          d='M788.557,25.6H776.876L771,35.7l5.876,10.176h11.681l5.8-10.176Z'
          transform='translate(-753.443 -25.6)'
        />
        <path
          className={classes.cls2}
          d='M813.357,39.6H801.676L795.8,49.7l5.876,10.1h11.681l5.876-10.1Z'
          transform='translate(-760.47 -29.567)'
        />
        <path
          className={classes.cls2}
          d='M813.357,67.8H801.676L795.8,77.976l5.876,10.1h11.681l5.876-10.1Z'
          transform='translate(-760.47 -37.558)'
        />
        <path
          className={classes.cls2}
          d='M763.986,39.6H752.3l-5.8,10.1,5.8,10.1h11.681l5.876-10.1Z'
          transform='translate(-746.5 -29.567)'
        />
        <path
          className={classes.cls2}
          d='M763.986,67.8H752.3l-5.8,10.176,5.8,10.1h11.681l5.876-10.1Z'
          transform='translate(-746.5 -37.558)'
        />
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(Miel)
