import React, { Component } from 'react'
import injectSheet from 'react-jss'
import routes from '../../routing/routes'
import { icon } from 'src/config/dataConf'

const styles = {
  mapclass: {
    width: props => props.width,
    height: props => props.height
  }
}

let LeafletMap, TileLayer, Marker, Popup
class Map extends Component {
  constructor(props) {
    super(props)
    if (typeof window !== 'undefined') {
      LeafletMap = require('react-leaflet').Map
      TileLayer = require('react-leaflet').TileLayer
      Marker = require('react-leaflet').Marker
      Popup = require('react-leaflet').Popup
    }
  }

  render() {
    const {
      classes,
      lat,
      lng,
      zoom = 13,
      companyName,
      address,
      noPopup
    } = this.props
    const position = { lng, lat }
    return typeof window !== 'undefined' ? (
      <LeafletMap
        center={position}
        zoom={zoom}
        className={classes.mapclass}
        ref='map'
        minZoom={6}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
          url={routes.urlTiles}
        />
        <Marker position={position} icon={icon}>
          {!noPopup && (
            <Popup>
              <span>
                {companyName} <br />
                {address.address} <br />
                {`${address.city} ${address.postalCode}`}
              </span>
            </Popup>
          )}
        </Marker>
      </LeafletMap>
    ) : (
      <span />
    )
  }
}

export default injectSheet(styles)(Map)
