import React from 'react'

const RS = ({ ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='-1183 371 20.442 20.442'
  >
    <path
      fill='#35629d'
      d='M10.221,0A10.221,10.221,0,1,0,20.442,10.221,10.224,10.224,0,0,0,10.221,0ZM12.4,10.221H10.966v5.111H8.837V10.221H7.826V8.411H8.837V7.24a1.958,1.958,0,0,1,2.129-2.129h1.6V6.867H11.446a.432.432,0,0,0-.426.479V8.411h1.6Z'
      transform='translate(-1183 371)'
    />
  </svg>
)

export default RS
