import React, { Fragment } from 'react'
import { Grid } from 'react-bootstrap'
import injectSheet from 'react-jss'
import TopBar from 'src/components/top_bar/TopBar'
import Titre1 from 'src/components/font/Titre1'
import Footer from 'src/components/footer/Footer'
import TopBarSearch from 'src/components/top_bar/TopBarSearch'
import { Link } from 'react-router-dom'
import routes from 'src/routing/routes'
import { withRouter } from 'react-router'
import styles from './AnnuaireVille.styles'
import { Helmet } from 'react-helmet'
import responsiveComponent from 'src/hoc/responsiveComponent'
import Spinner from '../../../../../components/Spinner'
import Titre2 from '../../../../../components/font/Titre2'
import PubContainer from '../../../../../components/PubContainer'
import { responsiveAd, smBannerAd } from '../../../../../config/dataConf'

const AnnuaireVille = ({ classes, cities, loading }) => {
  let adsInserted = 0

  const insertPub = index => {
    if ((index + 1) % 10 === 0) {
      return (
        <Fragment key={`pub-${index}`}>
          <PubContainer scriptPubCore={smBannerAd} className={classes.ads} />
          <hr className={classes.hrSeparator} />
        </Fragment>
      )
    }
    return null
  }

  return (
    <div className={classes.wrapperAll}>
      <Helmet>
        <title>
          Producteurs locaux par ville en France - Vente à la ferme - Acheter à
          la source
        </title>
        <meta
          name='description'
          content='Vente directe producteur sur Acheter à la Source. Découvrez tous les producteurs proches de chez vous classés par ville et profitez de leurs produits de qualité !'
        />
      </Helmet>

      <Grid fluid className={classes.wrapperWithoutFooter}>
        <div className='visible-xs-block'>
          <TopBar />
        </div>
        <div className='hidden-xs'>
          <TopBarSearch bleu />
        </div>
        <Grid className={classes.cityContainer}>
          <PubContainer scriptPubCore={smBannerAd} />

          <Titre1>Producteurs locaux en vente directe par ville</Titre1>
          <hr className={classes.hrTitle} />

          {!loading ? (
            cities.map((city, i) => (
              <div key={i}>
                {insertPub(i)}
                <Titre2 component='p'>
                  <Link
                    to={routes.mapVille
                      .replace(':category', 'all')
                      .replace(':idVille', city.id)}
                  >
                    Producteurs locaux {city.name}
                  </Link>
                </Titre2>
                <hr className={classes.hrSeparator} />
              </div>
            ))
          ) : (
            <Spinner />
          )}
        </Grid>
      </Grid>
      <Footer />
    </div>
  )
}

export default responsiveComponent(
  withRouter(injectSheet(styles)(AnnuaireVille))
)
