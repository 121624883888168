import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  }
}

const Horticulture = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-3893.573 1232.485 97.745 97.745'
  >
    <g transform='translate(-3895.223 1232.985)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(2.15)'
      />
      <g transform='translate(14.393 18.576)'>
        <g>
          <path
            fill='#006c59'
            d='M79.168,278l21,38.555-3.583,1.29L75.8,281.153Z'
            transform='translate(-55.15 -254.653)'
          />
          <path
            fill='#fedc60'
            d='M71.146,268.983c7.166-4.013-5.733-18.919-1.218-20.567l-3.3-.072c-1.5,7.6-14.118,10.821-16.268,7.381-1.29-4.873,7.883-8.026,10.248-6.736-.43,3.153-6.091,3.368-6.091,3.368l1.075,3.511c7.166-1.863,10.319-7.238,6.235-10.1-9.03-2.293-20.567,7.453-11.609,13.258l8.241,12.613c3.225,3.01,9.03.143,9.03.143'
            transform='translate(-46.984 -245.422)'
          />
          <path
            fill='#fedc60'
            d='M80.358,277.1c-.788,1.29-4.658,3.8-4.658,3.8'
            transform='translate(-55.122 -254.398)'
          />
        </g>
        <g transform='translate(18.859 2.421)'>
          <path
            fill='#009245'
            d='M100.819,289.437c-.215.358-.358.645-.573,1-4.945,8.17-3.3,21-3.3,22.932,1.362-8.17,3.941-16.052,17.414-18.346,1.648,11.824-15.049,32.463-18.7,32.105H86.773l-4.586-5.876c-8.17-8.17-8.886-32.463-8.886-32.463,5.661,1.29,16.053,10.176,17.056,18.991A35.671,35.671,0,0,1,94.871,287'
            transform='translate(-73.3 -259.625)'
          />
          <path
            fill='#ff4637'
            d='M121.308,262.883c9.89-1,8.1-9.818,8.1-9.818a8.627,8.627,0,0,0-10.606,2.723Z'
            transform='translate(-86.193 -249.769)'
          />
          <path
            fill='#ff9483'
            d='M111.7,282.349c7.381,2.365,20.424.5,24.58-18.991-7.525-3.8-16.984,1.5-16.984,1.5Z'
            transform='translate(-84.181 -252.537)'
          />
          <path
            fill='#ff9483'
            d='M107.624,278.612c8.1-14.691,11.681-21.141,1.075-29.812-10.821,7.238-15.264,17.271-7.381,27.16,1.935,1.5,6.306,2.652,6.306,2.652'
            transform='translate(-80.105 -248.8)'
          />
        </g>
        <g transform='translate(22.943 39.543)'>
          <path
            fill='#006c59'
            d='M79,300.6a9.8,9.8,0,0,1,1.362,1.577,15.251,15.251,0,0,1,1.147,1.792c.717,1.218,1.362,2.437,2.007,3.655,1.29,2.508,2.437,5.016,3.583,7.6,1.075,2.58,2.15,5.16,3.082,7.811.5,1.29.932,2.652,1.362,3.941.215.645.358,1.362.573,2.007.072.358.143.717.215,1,0,.358.143.717-.072,1,.215-.287.072-.717.072-1-.072-.358-.143-.717-.215-1-.143-.645-.358-1.362-.573-2.007-.43-1.362-.86-2.652-1.362-3.941-1-2.58-2.078-5.16-3.153-7.74s-2.222-5.088-3.44-7.6c-.645-1.218-1.29-2.508-1.935-3.726-.358-.573-.717-1.218-1.075-1.792C79.932,301.6,79.5,301.03,79,300.6Z'
            transform='translate(-79 -300.6)'
          />
        </g>
        <g transform='translate(40.429 43.258)'>
          <path
            fill='#006c59'
            d='M103.4,332.526c1.075-2.437,2.007-4.873,3.082-7.31,1-2.437,2.078-4.873,3.153-7.238a73.519,73.519,0,0,1,3.726-7.023,26.156,26.156,0,0,1,2.293-3.225,12.877,12.877,0,0,1,1.433-1.362,2.881,2.881,0,0,1,1.863-.573,2.419,2.419,0,0,0-1.792.645,12.875,12.875,0,0,0-1.433,1.362,19.833,19.833,0,0,0-2.222,3.225c-1.362,2.293-2.508,4.658-3.655,7.023s-2.15,4.8-3.225,7.238C105.622,327.725,104.547,330.161,103.4,332.526Z'
            transform='translate(-103.4 -305.785)'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(Horticulture)
