import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  }
}

const Porc = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-3071.742 1232.485 97.745 97.745'
  >
    <g transform='translate(-3914.5 1075.543)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(843.258 157.442)'
      />
      <g transform='translate(860.926 173.208)'>
        <path
          fill='#f5aebd'
          d='M1218.3,250.315c-2.221-6.163-10.964-4.945-12.469-8.815-5.876,3.941-7.381,14.691,4.3,23.864'
          transform='translate(-1201.354 -241.5)'
        />
        <path
          fill='#f5aebd'
          d='M1263.3,250.315c2.222-6.163,10.964-4.945,12.469-8.815,5.876,3.941,7.381,14.691-4.3,23.864'
          transform='translate(-1218.907 -241.5)'
        />
        <path
          fill='#f5cbd4'
          d='M1254.993,266.994a21.941,21.941,0,0,0-43.714.072,27.938,27.938,0,1,0,49.662,17.486A27.251,27.251,0,0,0,1254.993,266.994Z'
          transform='translate(-1202.359 -243.059)'
        />
        <circle
          fill='#f5aebd'
          cx='13.974'
          cy='13.974'
          r='13.974'
          transform='translate(16.731 34.47)'
        />
        <circle
          fill='#4c2f21'
          cx='3.082'
          cy='3.082'
          r='3.082'
          transform='translate(20.027 25.942)'
        />
        <circle
          fill='#4c2f21'
          cx='3.082'
          cy='3.082'
          r='3.082'
          transform='translate(35.22 25.942)'
        />
        <ellipse
          fill='#e36479'
          cx='2.652'
          cy='3.511'
          rx='2.652'
          ry='3.511'
          transform='translate(23.037 42.568)'
        />
        <ellipse
          fill='#e36479'
          cx='2.652'
          cy='3.511'
          rx='2.652'
          ry='3.511'
          transform='translate(32.998 42.568)'
        />
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(Porc)
