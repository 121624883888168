import React from 'react'
import injectSheet from 'react-jss'

const styles = () => ({
  cls1: {
    fontSize: 20,
    fontFamily: 'NunitoSans-Bold, Nunito Sans',
    fontWeight: 700,
    fill: '#0091a3'
  },

  cls2: {
    fill: '#0091a3'
  }
})

const SvgCompteActifAFVert = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='2256 48.199 150 36.801'
  >
    <g id='Groupe_336' transform='translate(1331)'>
      <text
        id='Mon_compte'
        className={classes.cls1}
        transform='translate(960 78)'
      >
        <tspan x='0' y='0'>
          Mon compte
        </tspan>
      </text>
      <g id='Groupe_303'>
        <g id='Groupe_109' transform='translate(929.019 52.811)'>
          <path
            id='Tracé_223'
            className={classes.cls2}
            d='M22.932,33.7H4.6V31.866A9.165,9.165,0,0,1,13.766,22.7h0a9.165,9.165,0,0,1,9.166,9.166Z'
            transform='translate(-4.6 -7.511)'
          />
          <path
            id='Tracé_699'
            className={classes.cls2}
            d='M6.809,0A6.809,6.809,0,1,1,0,6.809,6.809,6.809,0,0,1,6.809,0Z'
            transform='translate(2.357)'
          />
        </g>
        <path
          id='Tracé_224'
          className={classes.cls2}
          d='M19.292,4.541a3.581,3.581,0,0,0,.087-.96C19.117,1.224,15.538-.347,11.435.089,7.245.526,4.1,2.8,4.364,5.153a1.76,1.76,0,0,0,.262.96C1.745,6.811-.088,7.684,0,8.47c.175,1.309,5.674,1.746,12.4.96,6.722-.7,12.047-2.27,11.872-3.579C24.18,5.065,22.26,4.629,19.292,4.541Z'
          transform='translate(925.003 48.183)'
        />
        <path
          id='Tracé_228'
          className={classes.cls2}
          d='M26.814,13.386a1.5,1.5,0,0,1-.524.524,1.211,1.211,0,0,1,0-.786,1.019,1.019,0,0,1,.524-.524A.816.816,0,0,1,26.814,13.386Z'
          transform='translate(921.671 46.584)'
        />
        <path
          id='Tracé_229'
          className={classes.cls2}
          d='M27.3,15.462a.991.991,0,0,0-.7-.262,1.078,1.078,0,0,0,.349.7.991.991,0,0,0,.7.262C27.56,15.811,27.473,15.549,27.3,15.462Z'
          transform='translate(921.624 46.254)'
        />
        <rect
          id='Rectangle_81'
          className={classes.cls2}
          width='10.65'
          height='0.436'
          transform='translate(940.1 62.553) rotate(-12.838)'
        />
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(SvgCompteActifAFVert)
