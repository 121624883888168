import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import injectSheet from 'react-jss'
import Titre1 from '../../../../../components/font/Titre1'
import Map from '../../../../../components/map/Map'
import MapIcon from '../../../../../components/svg/MapIcon'

const styles = () => ({
  divMapIcon: {
    position: 'absolute',
    bottom: -21,
    right: 15,
    height: 42,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  titre: {
    textAlign: 'center'
  }
})

class ModalMap extends Component {
  state = {
    showModal: false
  }

  closeModalMap = () => {
    this.setState({ showModal: false })
  }

  openModalMap = () => {
    this.setState({ showModal: true })
  }

  render() {
    let {
      classes,
      ficheProducer: { companyName, address }
    } = this.props

    return (
      <div className={classes.divMapIcon}>
        <MapIcon taille={42} onClick={this.openModalMap} />
        <Modal show={this.state.showModal} onHide={this.closeModalMap}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Titre1 component='p' className={classes.titre}>
                {'Emplacement du producteur'.toUpperCase()}
              </Titre1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {address.coordinates && (
              <Map
                lat={address.coordinates.y}
                lng={address.coordinates.x}
                width={'100%'}
                height={300}
                address={address}
                companyName={companyName}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default injectSheet(styles)(ModalMap)
