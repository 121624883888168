import React, { Fragment } from 'react'
import Footer from 'src/components/footer/Footer'
import { Grid, Row, Col } from 'react-bootstrap'
import injectSheet from 'react-jss'
import SearchAccueil from './components/SearchAccueil'
import routes from 'src/routing/routes'
import Facebook from 'src/components/svg/Facebook'
import Twitter from 'src/components/svg/Twitter'
import { Link } from 'react-router-dom'
import TopBar from 'src/components/top_bar/TopBar'
import Bovins from 'src/components/svg/categories/Bovins'
import ProduitsFermiers from 'src/components/svg/categories/ProduitsFermiers'
import Legumes from 'src/components/svg/categories/Legumes'
import Fruits from 'src/components/svg/categories/Fruits'
import Miel from 'src/components/svg/categories/Miel'
import Oeufs from 'src/components/svg/categories/Oeufs'
import Fromage from 'src/components/svg/categories/Fromage'
import Vin from 'src/components/svg/categories/Vin'
import Horticulture from 'src/components/svg/categories/Horticulture'
import Poissons from 'src/components/svg/categories/Poissons'
import Volailles from 'src/components/svg/categories/Volailles'
import Mouton from 'src/components/svg/categories/Mouton'
import Porc from 'src/components/svg/categories/Porc'
import Gibiers from 'src/components/svg/categories/Gibiers'
import ButtonLink from 'src/components/button/ButtonLink'
import ListeProdAccueil from './components/ListeProdAccueil/ListeProdAccueil.container'
import FondAccueil from 'src/components/svg/FondAccueil'
import {
  categoriesLinkValues,
  smBannerAd,
  responsiveAd,
  lgBannerAd
} from 'src/config/dataConf'
import { withCookies } from 'react-cookie'
import TopBarAccueil from './components/TopBarAccueil'
import { Helmet } from 'react-helmet'
import Titre1 from 'src/components/font/Titre1'
import styles from './Accueil.styles'
import responsiveComponent from 'src/hoc/responsiveComponent'
import PubContainer from '../../../components/PubContainer'
import useWindowWidth from '../../../hooks/useWindowWidth'
import { FaInfoCircle as InfoIcon } from 'react-icons/fa'

const Accueil = ({ classes, cookies, isMobile }) => {
  const token = cookies.get('token')
  const width = useWindowWidth()

  return (
    <div className={classes.pageWrapper}>
      <Helmet>
        <meta
          name='description'
          content='Retrouvez la majorité des producteurs et cultivateurs français sur Acheter à la Source, et profitez de leurs produits de qualité en vente directe !'
        />
      </Helmet>

      {isMobile ? <TopBar /> : <TopBarAccueil />}

      <Grid fluid className={classes.grid}>
        <Row>
          <Col xs={12} className={classes.titreAccueil}>
            <Titre1>
              Des produits de qualité en vente directe, du producteur au
              consommateur !
            </Titre1>
          </Col>
          <Col xs={12} className={classes.colSearchAccueilAF}>
            <SearchAccueil />
          </Col>
        </Row>

        {isMobile ? (
          <Row className={classes.liens}>
            <Col xs={5}>
              <ul className='list-inline'>
                <li>
                  <a
                    href='https://www.facebook.com/acheteralasource/'
                    target='_blank'
                  >
                    <Facebook className={classes.reseaux_sociaux} />
                  </a>
                </li>
                <li>
                  <a href='https://twitter.com/acheteralasourc' target='_blank'>
                    <Twitter className={classes.reseaux_sociaux} />
                  </a>
                </li>
              </ul>
            </Col>
            {!token && (
              <Col xs={7} className={classes.colProd}>
                <p className={classes.lienProd}>
                  <Link to={routes.inscription}>Vous êtes producteur ?</Link>
                </p>
              </Col>
            )}
          </Row>
        ) : (
          <Fragment>
            <Row className={classes.liensAutreFormat}>
              <Col sm={5} className={classes.liensSociaux}>
                <ul className='list-inline'>
                  <li>
                    <a
                      href='https://www.facebook.com/acheteralasource/'
                      target='_blank'
                    >
                      <Facebook className={classes.reseaux_sociaux} />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://twitter.com/acheteralasourc'
                      target='_blank'
                    >
                      <Twitter className={classes.reseaux_sociaux} />
                    </a>
                  </li>
                </ul>
              </Col>
              {!token && (
                <Col sm={7} className={classes.colProd}>
                  <p className={classes.lienProdAutreFormat}>
                    <Link to={routes.inscription}>Vous êtes producteur ?</Link>
                  </p>
                </Col>
              )}
            </Row>

            <div className={classes.enSavoirPlus}>
              <div className={classes.divFondAccueil}>
                <FondAccueil width={'100%'} />
              </div>
              <p>
                Retrouvez la majorité des producteurs et cultivateurs français
                sur Acheter à la Source, et profitez de leurs produits de
                qualité en vente directe !
              </p>
              <ButtonLink to={routes.about}>En savoir plus</ButtonLink>
            </div>
          </Fragment>
        )}

        <div className={classes.secuAlimentation}>
          <div className={classes.floatingInformation}>
            <InfoIcon className={classes.infoIcon} size={33} color='#abe0e0' />

            <p>
              Acheteralasource.com soutient la création d'une sécurité sociale
              de l'alimentation
            </p>

            <iframe
              width={isMobile ? 300 : 420}
              height={isMobile ? 280 : 345}
              src='https://www.youtube.com/embed/pRjwrJZS-Ro'
            />

            <a target='_blank' href='https://securite-sociale-alimentation.org'>
              En savoir plus
            </a>
          </div>
        </div>
      </Grid>

      {isMobile ? (
        <PubContainer scriptPubCore={responsiveAd} className={classes.ads} />
      ) : width > 1000 ? (
        <PubContainer
          scriptPubCore={lgBannerAd}
          className={classes.adsDesktop}
        />
      ) : (
        <PubContainer
          scriptPubCore={smBannerAd}
          className={classes.adsDesktop}
        />
      )}

      <Grid fluid className={classes.categorie}>
        <h2 className={classes.rechercheTitle}>Je recherche...</h2>
        <div className={classes.container}>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[0].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <ProduitsFermiers />
            Produits fermiers
          </Link>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[1].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <Legumes />
            Légumes
          </Link>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[2].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <Fruits />
            Fruits
          </Link>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[3].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <Miel />
            Miel
          </Link>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[4].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <Oeufs />
            Œufs
          </Link>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[5].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <Fromage />
            Fromage
          </Link>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[6].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <Vin />
            Vins
          </Link>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[7].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <Horticulture />
            Horticulture
          </Link>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[8].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <Poissons />
            Poissons
          </Link>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[9].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <Volailles />
            Volailles, lapins
          </Link>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[10].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <Bovins />
            Bovins
          </Link>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[11].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <Mouton />
            Moutons, Chèvres
          </Link>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[12].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <Porc />
            Porc
          </Link>
          <Link
            to={routes.siteMap.replace(
              ':category',
              categoriesLinkValues[13].urlValue
            )}
            className={isMobile ? classes.itemMobile : classes.itemDesktop}
          >
            <Gibiers />
            Gibiers
          </Link>
        </div>

        {isMobile ? (
          <PubContainer scriptPubCore={responsiveAd} className={classes.ads} />
        ) : width > 1000 ? (
          <PubContainer scriptPubCore={lgBannerAd} className={classes.ads} />
        ) : (
          <PubContainer scriptPubCore={smBannerAd} className={classes.ads} />
        )}
      </Grid>

      <Grid fluid className={classes.gridArticle}>
        <h2 className={isMobile ? classes.zoomMobile : classes.zoomAutreFormat}>
          ZOOM SUR...
        </h2>
        <Row className={classes.rowZoom}>
          <ListeProdAccueil />
        </Row>
      </Grid>
      <Footer />
    </div>
  )
}

const styled = injectSheet(styles)(Accueil)

export default responsiveComponent(withCookies(styled))
