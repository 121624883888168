import React from 'react'
import injectSheet from 'react-jss'

const styles = theme => ({
  h3: {
    fontWeight: theme.semiBold,
    color: theme.titre3,
    fontSize: theme.tailleMoyenTexte
  }
})

const Titre = ({
  classes,
  className,
  children,
  component: Component = 'h3',
  ...props
}) => (
  <Component className={`${classes.h3} ${className}`} {...props}>
    {children}
  </Component>
)

export default injectSheet(styles)(Titre)
