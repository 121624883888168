import React from 'react'
import PropTypes from 'prop-types'

const Oeil = ({ taille, jaune, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    x='0px'
    y='0px'
    viewBox='0 0 18.9 10.5'
    style={{ enableBackground: 'new 0 0 18.9 10.5' }}
  >
    <g transform='translate(-1561.4 2802.3)'>
      <path
        fill={jaune ? '#F8AF08' : '#0091A3'}
        d='M1570.9-2791.8c-3.7,0-7.2-3-9-4.8l-0.5-0.5l0.5-0.5c1.8-1.8,5.3-4.8,9-4.8c2.9,0,5.8,1.5,9,4.8l0.5,0.5
      l-0.5,0.5C1576.7-2793.4,1573.6-2791.8,1570.9-2791.8z M1563.3-2797.1c1.7,1.6,4.7,3.9,7.6,3.9c2.3,0,4.8-1.3,7.5-3.9
      c-2.7-2.7-5.2-3.9-7.6-3.9C1568-2801,1565-2798.7,1563.3-2797.1z'
      />
      <ellipse
        fill={jaune ? '#F8AF08' : '#0091A3'}
        cx='1570.9'
        cy='-2797.1'
        rx='3'
        ry='3'
      />
    </g>
  </svg>
)

Oeil.propTypes = {
  jaune: PropTypes.bool,
  taille: PropTypes.number
}

export default Oeil
