export const colors = {
  bleu: '#ABE0E0',
  vert: '#0091A3',
  vertClair: '#00a7b9',
  vertUltraClair: '#00cee1',
  jaune: '#F8AF08',
  noir: '#000000',
  gris: '#95989A',
  grisClair: '#eeeeee',
  marron: '#663306',
  jauneFonce: '#af7b08',
  jauneClair: '#ffb508',
  bleuFonce: '#23527c'
}

// const coeffPtPx = 1.33; //Pour passer de pt en px et garder la même taille

// taille en px
const grandTexte = 20
const moyenTexte = 15
const petitTexte = 13
const texteSaisieAuto = 11
const glyph = 10

export const jour = {
  cookies: {
    backgroundColor: colors.marron,
    textColor: 'white',
    fontSize: moyenTexte,
    buttonColor: colors.jaune,
    buttonHoverColor: colors.jauneFonce
  },
  minHeight: 'calc(100vh - 239px)',
  fontGlobal: 'nunito sans',
  backgroundColor: colors.bleu,
  topBarColor: colors.bleu,
  topBarColorSearch: 'white',
  titre1: colors.vert,
  titre2: colors.jaune,
  titre3: colors.jaune,
  titre4: colors.gris,
  titre5J: colors.jaune,
  titre5V: colors.vert,
  link: colors.vert,
  linkProducer: colors.jaune,
  linkHoverProducer: colors.jauneFonce,
  texteCategorie: colors.vert,
  prix: colors.vert,
  texte: colors.noir,
  lien: colors.vert,
  tailleGrandTexte: grandTexte + 'px',
  tailleMoyenTexte: moyenTexte + 'px',
  taillePetitTexte: petitTexte + 'px',
  tailleSaisieAuto: texteSaisieAuto + 'px',
  tailleGlyph: glyph + 'px',
  colorBackgroundSaisieAuto: colors.bleu,
  regular: 400,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  paginationBorderColor: colors.vert,
  paginationBackgroundColor: colors.vert,
  paginationBackgroundHoverColor: colors.bleu,
  paginationActiveBackgroundHoverColor: colors.bleu,
  paginationDisabledColor: colors.gris,
  borderColorInput: '#aaa',
  borderInput: '1px solid ' + colors.gris,
  colorInput: '#aaa',
  borderColorImage: colors.vert,
  textColorLegendImage: colors.vert,
  accueil: {
    backgroundColorZoom: colors.jaune,
    titreSearch: colors.vert,
    lienProd: colors.vert,
    lienCategory: colors.vert,
    backgroundCategorie: '#FFEFD1',
    titreCategorie: colors.marron,
    marron: colors.marron
  },
  footer: {
    backgroundColor: '#663306',
    borderTop: '30px solid #8B582C',
    texte: 'white'
  },
  ficheProducteur: {
    hrColor: colors.vert
  },
  ficheProduit: {
    categorieColorMobile: colors.jaune,
    categorieHoverColorMobile: colors.jauneFonce,
    producerColorMobile: colors.jaune,
    hrColor: colors.vert
  },
  listeProducteur: {
    hrColor: colors.gris,
    hoverColor: colors.grisClair
  },
  button: {
    color: 'white',
    backgroundColor: colors.vert,
    backgroundColorHover: colors.vertClair,
    backgroundColorActive: colors.vertUltraClair,
    borderColor: colors.vert,
    borderHoverColor: colors.vertClair,
    borderActiveColor: colors.vertUltraClair,
    backgroundHoverFonce: colors.bleuFonce,
    borderHoverColorFonce: colors.bleuFonce,
    backgroundColorJ: colors.jaune,
    backgroundColorHoverJ: colors.jauneFonce,
    backgroundColorActiveJ: colors.jauneClair,
    borderColorJ: colors.jaune,
    borderHoverColorJ: colors.jauneFonce,
    borderActiveColorJ: colors.jauneClair
  },
  producteur: {
    hrColor: colors.vert
  },
  produit: {
    hrColor: colors.vert
  },
  connexion: {
    titre: colors.vert,
    label: colors.vert
  },
  account: {
    menuBackgroundColor: colors.vert,
    menuElementSelectedBackgroundColor: 'white',
    menuElementHoverBackgroundColor: colors.bleuFonce,
    affichageBackgroundColor: 'white',
    backLink: 'white',
    menuTextColor: 'white',
    menuTextSelectedColor: colors.vert,
    burger: {
      menuElementSelectedBackgroundColor: colors.bleuFonce,
      menuElementHoverBackgroundColor: colors.bleuFonce,
      menuTextColor: colors.vert,
      menuTextSelectedColor: 'white'
    },

    dashboard: {
      hrColor: colors.vert,
      linkColor: colors.vert
    }
  },
  Plan: {
    regionColor: colors.vert,
    departmentColor: colors.jaune,
    hrColor: colors.vert,
    colorButtonSelected: {
      backgroundColor: colors.bleu,
      borderColor: colors.bleu,
      textColor: colors.vert
    }
  },
  breadcrumb: {
    color: colors.vert,
    colorHover: colors.bleuFonce,
    colorActive: colors.gris
  },
  spinnerContainer: {
    position: 'relative',
    height: '70px'
  },
  spinner: {
    position: 'absolute',
    top: 15,
    left: 'calc(50% - 20px)'
  }
}
