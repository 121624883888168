import React, { Fragment } from 'react'
import { Grid } from 'react-bootstrap'
import injectSheet from 'react-jss'
import TopBar from '../../../components/top_bar/TopBar'
import Titre1 from '../../../components/font/Titre1'
import P from '../../../components/font/P'
import Titre3 from '../../../components/font/Titre3'
import Footer from '../../../components/footer/Footer'
import { Helmet } from 'react-helmet'
import { lienRGPD } from '../../../config/dataConf'

const styles = theme => ({
  wrapper: {
    backgroundColor: theme.backgroundColor,
    minHeight: theme.minHeight,
    paddingBottom: 15
  },
  grid: {
    backgroundColor: 'white',
    margin: '15px auto 0'
  },
  rubrique: {
    color: theme.titre1
  },
  sousRubrique: {
    color: theme.titre1,
    margin: '10px 10px 0px 0px'
  }
})

const MentionsLegales = ({ classes }) => (
  <Fragment>
    <Helmet>
      <title>Mentions Légales</title>
    </Helmet>
    <div className={classes.wrapper}>
      <TopBar />
      <Grid className={classes.grid}>
        <Titre1>Mentions Légales</Titre1>
        <P>
          « Acheter à la source » est un site publié par l’entreprise Web
          Alternatif inscrite au RCS de Grenoble.
        </P>

        <Titre3 className={classes.sousRubrique}>Responsable du site :</Titre3>
        <P>
          Le gérant du site, Web Alternatif, a son siège situé au 5, Rue Colonel
          Denfert Rochereau, 38000 Grenoble.
        </P>

        <Titre3 className={classes.sousRubrique}>E-mail :</Titre3>
        <P>contact@webalternatif.com</P>

        <Titre3 className={classes.sousRubrique}>N° SIRET :</Titre3>
        <P>51739893900028</P>

        <Titre3 className={classes.sousRubrique}>Code NAF / APE :</Titre3>
        <P>7490B</P>

        <P>
          Conformément à la LOI n°2004-801 du 6 août 2004 relative à la
          protection des personnes physiques à l’égard des traitements de
          données à caractère personnel modifiant la loi n°78-17 du 6 janvier
          1978, ce site a été déclaré auprès de la CNIL. Déclaration CNIL
          N°1694223
        </P>

        <Titre3 className={classes.sousRubrique}>
          Politique d’utilisation des données personnelles du site :{' '}
          <a href={lienRGPD} download target='_blank'>
            Cliquez ici
          </a>
        </Titre3>

        <Titre3 className={classes.sousRubrique}>Hébergeur :</Titre3>
        <P>OVH - 2 rue Kellermann 59100 Roubaix - France</P>

        <Titre3 className={classes.sousRubrique}>Design par :</Titre3>
        <P>Kitchenette</P>
      </Grid>
    </div>
    <Footer />
  </Fragment>
)

export default injectSheet(styles)(MentionsLegales)
