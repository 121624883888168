import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import injectSheet from 'react-jss'
import gql from 'graphql-tag'
import routes from '../../routing/routes'
import ButtonStyle from '../button/ButtonStyle'
import { Link } from 'react-router-dom'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router'
import { Field, Form } from 'react-final-form'
import { colors } from '../../config/themes'
import { FORM_ERROR } from 'final-form'
import { withCookies } from 'react-cookie'
import { NODE_ENV } from '../../config/env'

const styles = theme => ({
  modal: {
    width: 400,
    maxWidth: '90%',
    margin: '50px auto 0',
    '& .modal-content': {
      borderRadius: '0px'
    }
  },
  space: {
    marginBottom: '10px',
    '& input': {
      borderRadius: 0
    }
  },
  title: {
    textAlign: 'center',
    color: theme.connexion.titre
  },
  label: {
    color: theme.connexion.label
  },
  input: {
    width: '100%',
    padding: 8,
    border: theme.borderInput
  },
  lien: {
    fontWeight: theme.semiBold,
    fontSize: theme.tailleMoyenTexte,
    display: 'block',
    color: theme.lien,
    marginTop: 5,
    '&:hover': {
      color: theme.lien
    }
  },
  divButton: {
    position: 'relative'
  },
  error: {
    color: 'red'
  },
  disabled: {
    backgroundColor: colors.gris,
    borderColor: colors.gris,
    '&:hover': {
      borderColor: colors.gris
    }
  }
})

class ModalConnexion extends Component {
  handleSubmit = values => {
    return this.props.client
      .query({
        query: gql`
      { 
        login(mail: "${values.email}", password: "${values.password}") {
          token
          error
        } 
          
      }`
      })
      .then(data => {
        if (!data.data.login.error) {
          const date = new Date()
          date.setDate(date.getDate() + 365)
          this.props.cookies.set('token', data.data.login.token, {
            path: '/',
            expires: date,
            secure: NODE_ENV !== 'dev',
            sameSite: 'lax'
          })
          this.props.history.push(routes.accueil)
          return null
        }

        return { [FORM_ERROR]: data.data.login.error }
      })
  }

  render() {
    const { show, onHide, classes } = this.props

    return (
      <Modal show={show} onHide={onHide} dialogClassName={classes.modal}>
        <Modal.Header closeButton>
          <Modal.Title className={classes.title}>Connexion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={this.handleSubmit}
            render={({ handleSubmit, submitting, submitError }) => (
              <form onSubmit={handleSubmit}>
                <Field name='email'>
                  {({ input, meta }) => (
                    <div className={classes.space}>
                      <input
                        className={classes.input}
                        type='email'
                        {...input}
                        placeholder='Email'
                        required
                      />
                      {meta.touched && (meta.error || meta.submitError) && (
                        <span className={classes.error}>
                          {meta.error}
                          {meta.submitError}
                        </span>
                      )}
                    </div>
                  )}
                </Field>

                <Field name='password'>
                  {({ input, meta }) => (
                    <div className={classes.space}>
                      <input
                        className={classes.input}
                        type='password'
                        {...input}
                        placeholder='Mot de passe'
                        required
                      />
                      {meta.touched && (meta.error || meta.submitError) && (
                        <span className={classes.error}>
                          {meta.error}
                          {meta.submitError}
                        </span>
                      )}
                    </div>
                  )}
                </Field>

                {submitError && <p className={classes.error}>{submitError}</p>}

                <div className={classes.divButton}>
                  {submitting ? (
                    <ButtonStyle
                      className={classes.disabled}
                      disabled={submitting}
                      type='submit'
                    >
                      Connexion
                    </ButtonStyle>
                  ) : (
                    <ButtonStyle type='submit'>Connexion</ButtonStyle>
                  )}
                  <Link to={routes.forgottenPassword} className={classes.lien}>
                    Mot de passe oublié
                  </Link>
                  <Link to={routes.inscription} className={classes.lien}>
                    Inscription
                  </Link>
                </div>
              </form>
            )}
          />
        </Modal.Body>
      </Modal>
    )
  }
}

const withStyle = injectSheet(styles)(ModalConnexion)
const apollo = withApollo(withStyle)
const router = withRouter(apollo)

export default withCookies(router)
