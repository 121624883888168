import React from 'react'
import injectSheet from 'react-jss'

const styles = {
  cls1: {
    fill: 'none',
    stroke: '#0091a3',
    strokeMiterlimit: '10'
  }
}

const Gibiers = ({ taille, classes, ...props }) => (
  <svg
    width={taille}
    height={taille}
    {...props}
    viewBox='-2907.418 1232.485 97.745 97.745'
  >
    <g transform='translate(-3914.5 1075.543)'>
      <circle
        className={`${classes.cls1} category-circle`}
        cx='48.373'
        cy='48.373'
        r='48.373'
        transform='translate(1007.582 157.442)'
      />
      <g transform='translate(1029.752 171.775)'>
        <path
          fill='#603813'
          d='M1452.461,250.536c-3.01-10.176-14.118-11.036-14.118-11.036-2.651,9.818-2.078,23,6.306,23.649Z'
          transform='translate(-1436.937 -239.5)'
        />
        <path
          fill='#603813'
          d='M1491,250.536c3.01-10.176,14.118-11.036,14.118-11.036,2.58,9.818,2.078,23-6.307,23.649Z'
          transform='translate(-1452.257 -239.5)'
        />
        <path
          fill='#4c2f21'
          d='M1465.026,246.6c7.74,1.218,21,11.323,19.134,25.512,6.88,7.6,11.968,36.333-20.711,41.708h-.072c-32.392-6.951-25.942-35.4-18.7-42.711-1.075-14.189,12.613-23.649,20.352-24.509Z'
          transform='translate(-1437.462 -241.512)'
        />
        <path
          fill='#cb916f'
          d='M1455.9,323.395c-2.651,2.437-13.9,5.231-18.2-7.095,2.437,2.293,10.678,8.6,18.2,2.007Z'
          transform='translate(-1437.153 -261.263)'
        />
        <path
          fill='#cb916f'
          d='M1487.4,323.968c1.648,1.863,12.469,4.658,16.769-7.668-2.437,2.293-10.248,8.6-16.769,2.58Z'
          transform='translate(-1451.237 -261.263)'
        />
        <path
          fill='#603813'
          d='M1470.974,329.724c4.586-1.362,9.675-5.16,9.675-9.1,0-3.87-4.371-7.023-9.675-7.023-5.375,0-9.675,3.153-9.675,7.023C1461.3,324.564,1466.316,328.506,1470.974,329.724Z'
          transform='translate(-1443.841 -260.498)'
        />
        <circle
          fill='#4c2f21'
          cx='2.437'
          cy='2.437'
          r='2.437'
          transform='translate(20.111 58.764)'
        />
        <circle
          fill='#211106'
          cx='3.296'
          cy='3.296'
          r='3.296'
          transform='translate(16.098 29.883)'
        />
        <circle
          fill='#211106'
          cx='3.296'
          cy='3.296'
          r='3.296'
          transform='translate(31.505 29.883)'
        />
        <circle
          fill='#4c2f21'
          cx='2.437'
          cy='2.437'
          r='2.437'
          transform='translate(29.355 58.764)'
        />
      </g>
    </g>
  </svg>
)

export default injectSheet(styles)(Gibiers)
