import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'

const styles = theme => ({
  bouton: {
    padding: 7
  },
  vert: {
    backgroundColor: theme.button.backgroundColor,
    color: theme.button.color,
    fontWeight: theme.regular,
    border: `3px solid ${theme.button.borderColor}`,
    '&:hover, &:focus': {
      color: theme.button.color,
      borderColor: theme.button.borderHoverColor,
      backgroundColor: theme.button.backgroundColorHover
    },
    '&:active': {
      backgroundColor: theme.button.backgroundColorActive,
      borderColor: theme.button.borderActiveColor
    }
  },
  jaune: {
    backgroundColor: theme.button.backgroundColorJ,
    fontWeight: theme.regular,
    color: theme.button.color,
    border: `3px solid ${theme.button.borderColorJ}`,
    '&:hover, &:focus': {
      color: theme.button.color,
      borderColor: theme.button.borderHoverColorJ,
      backgroundColor: theme.button.backgroundColorHoverJ
    },
    '&:active': {
      backgroundColor: theme.button.backgroundColorActiveJ,
      borderColor: theme.button.borderActiveColorJ
    }
  },
  barrioFont: {
    fontFamily: 'barrio',
    fontSize: '22px'
  }
  // colorHover: {
  //   '&:hover, &:focus': {
  //     color: theme.button.color,
  //     borderColor: props => props.colorHover,
  //     backgroundColor: props => props.colorHover
  //   }
  // }
})

const ButtonStyle = ({
  children,
  classes,
  className,
  jaune,
  defaultFont,
  colorHover,
  ...props
}) => (
  <button
    {...props}
    className={
      classes.bouton +
      (className ? ` ${className} ` : ' ') +
      (jaune ? classes.jaune : classes.vert) +
      (jaune && !defaultFont ? ` ${classes.barrioFont}` : '')
    }
  >
    {children}
  </button>
)

ButtonStyle.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  colorHover: PropTypes.string,
  defaultFont: PropTypes.bool,
  jaune: PropTypes.bool
}

export default injectSheet(styles)(ButtonStyle)
