import React, { Component } from 'react'

export default WrappedComponent =>
  class extends Component {
    state = {
      isMobile: false
    }

    componentDidMount() {
      this.updatePredicate()
      window.addEventListener('resize', this.updatePredicate)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updatePredicate)
    }

    isForMobile = () => typeof window !== 'undefined' && window.innerWidth < 768

    updatePredicate = () => {
      this.setState({ isMobile: this.isForMobile() })
    }

    render() {
      return <WrappedComponent isMobile={this.state.isMobile} {...this.props} />
    }
  }
