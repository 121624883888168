import React, { Component, Fragment } from 'react'
import withStyles from 'react-jss'
import TopBarWithoutConnexion from 'src/components/top_bar/TopBarWithoutConnexion'
import Titre5 from 'src/components/font/Titre5'
import Titre1 from 'src/components/font/Titre1'
import { Grid } from 'react-bootstrap'
import FondAccueil from 'src/components/svg/FondAccueil'
import { Field, Form } from 'react-final-form'
import ButtonStyle from 'src/components/button/ButtonStyle'
import Footer from 'src/components/footer/Footer'
import { colors } from 'src/config/themes'
import { Helmet } from 'react-helmet'

const styles = theme => ({
  grid: {
    backgroundColor: theme.backgroundColor
  },
  title: {
    marginTop: 5
  },
  hr: {
    marginTop: 0
  },
  container: {
    backgroundColor: 'white',
    width: 430,
    padding: 15,
    margin: '0 auto'
  },
  error: {
    color: 'red'
  },
  succeeded: {
    color: 'green'
  },
  disabled: {
    backgroundColor: colors.gris,
    borderColor: colors.gris,
    '&:hover': {
      borderColor: colors.gris
    }
  },
  input: {
    width: '100%',
    height: 30,
    outline: 'none',
    border: theme.borderInput,
    color: 'black',
    padding: 8
  },
  buttonSend: {
    marginTop: 15
  }
})

class ForgottenPassword extends Component {
  onSubmit = values => this.props.recoverPassword(values.mail)

  validate = values => {
    const errors = {}

    if (!values.mail) {
      errors.mail = 'Email requis'
    }

    return errors
  }

  render() {
    const { classes } = this.props

    return (
      <Fragment>
        <Helmet>
          <title>Mot de passe oublié</title>
        </Helmet>
        <TopBarWithoutConnexion />
        <Grid fluid className={classes.grid}>
          <div className={classes.container}>
            <Titre1 className={classes.title}>MOT DE PASSE OUBLIÉ</Titre1>
            <hr className={classes.hr} />
            <Titre5 component='p'>
              Tapez votre adresse email pour retrouver votre mot de passe
            </Titre5>
            <Form
              onSubmit={this.onSubmit}
              validate={this.validate}
              render={({
                handleSubmit,
                submitting,
                submitError,
                submitSucceeded
              }) => (
                <form onSubmit={handleSubmit}>
                  <Field name='mail'>
                    {({ input, meta }) => (
                      <div>
                        <input
                          className={classes.input}
                          type='email'
                          placeholder='Email'
                          {...input}
                          required
                        />
                        {meta.touched && (meta.error || meta.submitError) && (
                          <span className={classes.error}>
                            {meta.error}
                            {meta.submitError}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>

                  <div className={classes.buttonSend}>
                    {submitting ? (
                      <ButtonStyle
                        className={classes.disabled}
                        disabled={submitting}
                      >
                        Envoyer
                      </ButtonStyle>
                    ) : (
                      <ButtonStyle type='submit'>Envoyer</ButtonStyle>
                    )}
                  </div>
                  {submitError && (
                    <div className={classes.error}>{submitError}</div>
                  )}
                  {submitSucceeded && (
                    <div className={classes.succeeded}>
                      Email de réinitialisation de mot de passe envoyé
                    </div>
                  )}
                </form>
              )}
            />
          </div>
          <div className={classes.divFondAccueil}>
            <FondAccueil width={'100%'} />
          </div>
        </Grid>
        <Footer />
      </Fragment>
    )
  }
}

export default withStyles(styles)(ForgottenPassword)
