import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import routes from './routes'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

const query = gql`
  query GetCoordinatesFromApi($keyword: String!) {
    getCoordinatesFromApi(keyword: $keyword)
  }
`

class RenderSearchWithAddress extends Component {
  render() {
    const { from, category, page, address } = this.props
    return (
      <Query query={query} variables={{ keyword: address }}>
        {({ data, error, loading }) => {
          if (!loading && !error && data && data.getCoordinatesFromApi) {
            return (
              <Switch>
                <Redirect
                  from={from}
                  to={`${routes.recherche}?category=${category}&coordinates=${
                    data.getCoordinatesFromApi
                  }${page ? `&page=${page}` : ''}`}
                />
              </Switch>
            )
          } else {
            return null
          }
        }}
      </Query>
    )
  }
}

RenderSearchWithAddress.propTypes = {
  address: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired
}

export default RenderSearchWithAddress
