import React, { Fragment } from 'react'
import { Grid } from 'react-bootstrap'
import injectSheet from 'react-jss'
import TopBar from '../../../components/top_bar/TopBar'
import Titre1 from '../../../components/font/Titre1'
import P from '../../../components/font/P'
import Titre2 from '../../../components/font/Titre2'
import Footer from '../../../components/footer/Footer'
import { Helmet } from 'react-helmet'
import routes from '../../../routing/routes'
import { Link } from 'react-router-dom'

const styles = theme => ({
  wrapper: {
    backgroundColor: theme.backgroundColor,
    minHeight: theme.minHeight,
    paddingBottom: 15
  },
  grid: {
    backgroundColor: 'white',
    margin: '15px auto 0'
  },
  rubrique: {
    color: theme.titre1
  }
})

const FAQ = ({ classes }) => (
  <Fragment>
    <Helmet>
      <title>Foire aux questions - Aide</title>
    </Helmet>
    <div className={classes.wrapper}>
      <TopBar />
      <Grid className={classes.grid}>
        <Titre1>Foire aux questions - Aide</Titre1>

        <Titre2 className={classes.rubrique}>
          Comment contacter un producteur sur Acheter à la Source ?
        </Titre2>
        <P>
          En bas de chaque page producteur se trouve un formulaire de contact
          dans l'onglet intitulé "Poser une question" qui permet de contacter le
          producteur. Vous trouverez également un onglet permettant de situer le
          producteur sur une carte. Néanmoins, il est possible que certaines
          fois il n'y ai pas de formulaire de contact au bas de la page, cela
          signifie que nous ne connaissons pas l'email du producteur, et que la
          seule façon de le contacter sera de lui écrire un courrier ou de lui
          rendre visite.
        </P>

        <Titre2 className={classes.rubrique}>
          Comment s'inscrire sur Acheter à la Source ?
        </Titre2>
        <P>
          Pour vous inscrire sur Acheter à la Source, rendez-vous sur la page
          d'inscription ou sur votre page producteur pré-inscrite puis cliquez
          sur le lien "Vous êtes ce producteur ?" Une fois votre inscription
          enregistrée, nous validons manuellement les inscriptions afin d'éviter
          les abus. Pour que votre inscription soit validée, veillez à bien
          décrire votre entreprise, et n'hésitez pas à ajouter des photos et des
          produits, cela donnera beaucoup plus de visibilité à votre page.
        </P>

        <Titre2 className={classes.rubrique}>
          J'ai oublié mon mot de passe, que faire ?
        </Titre2>
        <P>
          Si vous oubliez le mot de passe utilisé sur acheteralasource, vous
          pouvez le réinitialiser en cliquant sur le lien "Mot de passe oublié
          ?" situé dans le menu lorsque l'on clique sur le bouton en haut à
          droite. Vous pouvez également vous rendre directement sur la page en
          cliquant{` `}
          <Link to={routes.forgottenPassword}>ici</Link>.
        </P>

        <Titre2 className={classes.rubrique}>
          Combien de produits puis-je ajouter sur mon compte producteur ?
        </Titre2>
        <P>Il n'y a aucune limite de produits sur Acheter à la Source.</P>
      </Grid>
    </div>
    <Footer />
  </Fragment>
)

export default injectSheet(styles)(FAQ)
