import React from 'react'
import SwitchRouter from 'src/routing/SwitchRoutes'
import { ThemeProvider } from 'react-jss'
import { jour } from './config/themes'

const App = () => (
  <ThemeProvider theme={jour}>
    <SwitchRouter />
  </ThemeProvider>
)

export default App
