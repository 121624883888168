import React, { Component, Fragment } from 'react'
import TopBarWithoutConnexion from 'src/components/top_bar/TopBarWithoutConnexion'
import { Grid } from 'react-bootstrap'
import Titre1 from 'src/components/font/Titre1'
import Titre5 from 'src/components/font/Titre5'
import { Field, Form } from 'react-final-form'
import ButtonStyle from 'src/components/button/ButtonStyle'
import FondAccueil from 'src/components/svg/FondAccueil'
import Footer from 'src/components/footer/Footer'
import withStyles from 'react-jss'
import { colors } from 'src/config/themes'
import { Helmet } from 'react-helmet'
import Spinner from '../../../../../components/Spinner'

const styles = theme => ({
  grid: {
    backgroundColor: theme.backgroundColor
  },
  title: {
    marginTop: 5
  },
  messageNotFound: {
    marginTop: 5,
    textAlign: 'center'
  },
  hr: {
    marginTop: 0
  },
  container: {
    backgroundColor: 'white',
    width: 430,
    padding: 15,
    margin: '0 auto'
  },
  error: {
    color: 'red'
  },
  succeeded: {
    color: 'green'
  },
  disabled: {
    backgroundColor: colors.gris,
    borderColor: colors.gris,
    '&:hover': {
      borderColor: colors.gris
    }
  },
  input: {
    width: '100%',
    height: 30,
    outline: 'none',
    border: theme.borderInput,
    color: 'black',
    padding: 8
  },
  buttonSend: {
    marginTop: 15
  },
  spaceField: {
    marginTop: 5
  }
})

class NewPassword extends Component {
  onSubmit = values => this.props.changePassword(values)

  validate = values => {
    const errors = {}

    if (!values.password) {
      errors.password = 'Champ requis'
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'Champ requis'
    } else if (values.password && values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Le mot de passe est mal confirmé'
    }

    return errors
  }

  render() {
    const { classes, loading, verified } = this.props

    return (
      <Fragment>
        <Helmet>
          <title>Réinitialiser son mot de passe</title>
        </Helmet>
        <TopBarWithoutConnexion />
        <Grid fluid className={classes.grid}>
          {loading ? (
            <div className={classes.container}>
              <Spinner />
            </div>
          ) : verified ? (
            <div className={classes.container}>
              <Titre1 className={classes.title}>NOUVEAU MOT DE PASSE</Titre1>
              <hr className={classes.hr} />
              <Titre5 component='p'>
                Saisissez un nouveau mot de passe et confirmez le :
              </Titre5>
              <Form
                onSubmit={this.onSubmit}
                validate={this.validate}
                render={({
                  handleSubmit,
                  submitting,
                  submitError,
                  submitSucceeded
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Field name='password'>
                      {({ input, meta }) => (
                        <div>
                          <input
                            className={classes.input}
                            type='password'
                            placeholder='Mot de passe'
                            {...input}
                            required
                          />
                          {meta.touched && (meta.error || meta.submitError) && (
                            <span className={classes.error}>
                              {meta.error}
                              {meta.submitError}
                            </span>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name='confirmPassword'>
                      {({ input, meta }) => (
                        <div className={classes.spaceField}>
                          <input
                            className={classes.input}
                            type='password'
                            placeholder='Confirmez le mot de passe'
                            {...input}
                            required
                          />
                          {meta.touched && (meta.error || meta.submitError) && (
                            <span className={classes.error}>
                              {meta.error}
                              {meta.submitError}
                            </span>
                          )}
                        </div>
                      )}
                    </Field>

                    <div className={classes.buttonSend}>
                      {submitting ? (
                        <ButtonStyle
                          className={classes.disabled}
                          disabled={submitting}
                        >
                          Enregistrer
                        </ButtonStyle>
                      ) : (
                        <ButtonStyle type='submit'>Enregistrer</ButtonStyle>
                      )}
                    </div>

                    {submitError && (
                      <div className={classes.error}>{submitError}</div>
                    )}
                    {submitSucceeded && (
                      <div className={classes.succeeded}>
                        Mot de passe changé !
                      </div>
                    )}
                  </form>
                )}
              />
            </div>
          ) : (
            <div className={classes.container}>
              <Titre1 className={classes.messageNotFound} component='p'>
                Le lien a expiré ou n'est pas valide
              </Titre1>
            </div>
          )}
          <div className={classes.divFondAccueil}>
            <FondAccueil width={'100%'} />
          </div>
        </Grid>
        <Footer />
      </Fragment>
    )
  }
}

export default withStyles(styles)(NewPassword)
