import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import ListeProdAccueil from './ListeProdAccueil'
import React from 'react'

const ListeProdAccueilContainer = ({ data: { homeProducers, loading } }) => (
  <ListeProdAccueil articles={loading ? [] : homeProducers} />
)

const query = gql`
  query {
    homeProducers {
      id
      companyName
      presentation
      photosProducer {
        id
        cloudPath
      }
      address {
        id
        postalCode
        city
        address
        coordinates {
          x
          y
        }
      }
    }
  }
`

export default graphql(query)(ListeProdAccueilContainer)
