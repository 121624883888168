import React, { Component } from 'react'
import injectSheet from 'react-jss'
import TopBar from 'src/components/top_bar/TopBar'
import Titre1 from 'src/components/font/Titre1'
import Titre2 from 'src/components/font/Titre2'
import { Col, Grid, Row } from 'react-bootstrap'
import ButtonStyle from 'src/components/button/ButtonStyle'
import { Field, Form } from 'react-final-form'
import { colors } from 'src/config/themes'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { FORM_ERROR } from 'final-form'
import Select from 'react-select'
import Async from 'react-select/async'
import TopBarSearch from 'src/components/top_bar/TopBarSearch'
import { civiliteOptions } from 'src/config/dataConf'
import Footer from 'src/components/footer'
import { Helmet } from 'react-helmet'
import responsiveComponent from 'src/hoc/responsiveComponent'
import { fetchAddressAutocomplete } from 'src/utils'
import { lienRGPD } from '../../../../../config/dataConf'

const styles = theme => ({
  pageWrapper: {
    backgroundColor: theme.backgroundColor,
    minHeight: theme.minHeight,
    paddingBottom: ({ isMobile }) => (isMobile ? 0 : 25)
  },
  topBarContainer: {
    margin: '0px 15px'
  },
  formContainer: {
    backgroundColor: 'white'
  },
  input: {
    width: '100%',
    height: 30,
    outline: 'none',
    border: theme.borderInput,
    color: 'black',
    padding: 8
  },
  space: {
    marginBottom: 10
  },
  spaceFlex: {
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  labelRight: {
    marginLeft: 5
  },
  textareaPresentation: {
    resize: 'none',
    width: '100%',
    outline: 'none',
    height: 150,
    border: theme.borderInput,
    color: 'black',
    padding: 8,
    '&::placeholder': {
      color: theme.colorInput
    }
  },
  civility: {
    '& .civility__control': {
      border: theme.borderInput,
      borderRadius: 0
    }
  },
  divButtonValidation: {
    marginBottom: 30,
    textAlign: 'center'
  },
  error: {
    color: 'red'
  },
  globalError: {
    marginTop: 5
  },
  succeeded: {
    color: 'green'
  },
  disabled: {
    backgroundColor: colors.gris,
    borderColor: colors.gris,
    '&:hover': {
      borderColor: colors.gris
    }
  },
  autocomplete: {
    '& .address__control': {
      border: theme.borderInput,
      borderRadius: 0
    }
  },
  center: {
    textAlign: 'center',
    margin: '15px 0'
  }
})

class FormInscription extends Component {
  state = {
    rgpdChecked: false
  }

  getOptions = input => {
    return fetchAddressAutocomplete([], input)
  }

  validate = values => {
    const errors = {}

    if (!values.companyName) {
      errors.companyName = 'Champ requis'
    }

    if (!values.siren) {
      errors.siren = 'Champ requis'
    } else if (
      values.siren.replace(/\s/g, '').length > 0 &&
      values.siren.replace(/\s/g, '').length !== 9
    ) {
      errors.siren = 'Siren incorrect'
    }

    if (!values.city) {
      errors.city = 'Champ requis'
    }

    if (!values.postalCode) {
      errors.postalCode = 'Champ requis'
    }

    if (!values.address) {
      errors.address = 'Champ requis'
    }

    if (!values.civility || values.civility.value.length === 0) {
      errors.civility = 'Champ requis'
    }

    if (!values.nameContact) {
      errors.nameContact = 'Champ requis'
    }

    if (!values.firstNameContact) {
      errors.firstNameContact = 'Champ requis'
    }

    if (!values.tel) {
      errors.tel = 'Champ requis'
    }

    if (!values.presentation) {
      errors.presentation = 'Champ requis'
    }

    if (!values.mail) {
      errors.mail = 'Champ requis'
    }

    if (!values.password) {
      errors.password = 'Champ requis'
    } else if (values.password.length < 8) {
      errors.password = 'Le mot de passe doit contenir au minimum 8 caractères'
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'Champ requis'
    }
    return errors
  }

  onSubmit = values => {
    if (!this.state.rgpdChecked) {
      return {
        [FORM_ERROR]:
          "Vous devez accepter la politique d'utilisation des données personnelles avant de vous inscrire"
      }
    }

    if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      return { confirmPassword: 'Le mot de passe ne correspond pas au premier' }
    }

    const { address, civility, ...autre } = values
    const newValues = {
      rgpd: this.state.rgpdChecked,
      address: address.label,
      coordinates: address.value,
      civility: civility.value,
      ...autre
    }

    return this.props
      .mutate({
        variables: {
          user: newValues
        }
      })
      .then(({ data: { createUser } }) => {
        const error = JSON.parse(createUser.error)

        if (error !== []) {
          const tab = {}

          for (const value in error) {
            switch (value) {
              case 'all':
                tab[FORM_ERROR] = error[value]
                break
              case 'phone1':
                tab.tel = error.phone1
                break
              default:
                tab[value] = error[value]
            }
          }

          return tab
        }
      })
  }

  toggleRgpdChecked = () => {
    this.setState({ rgpdChecked: !this.state.rgpdChecked })
  }

  render() {
    const { classes, isMobile } = this.props

    return (
      <>
        <Helmet>
          <title>Inscription sur acheteralasource.com</title>
        </Helmet>

        <div className={classes.pageWrapper}>
          {isMobile ? (
            <TopBar />
          ) : (
            <div className={classes.topBarContainer}>
              <TopBarSearch bleu />
            </div>
          )}

          <Grid className={classes.formContainer}>
            <Form
              onSubmit={this.onSubmit}
              validate={this.validate}
              render={({
                handleSubmit,
                submitting,
                submitError,
                submitSucceeded
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row className={classes.space}>
                    <Col xs={12} sm={6} smOffset={3}>
                      <Titre1>DEMANDE D'INSCRIPTION</Titre1>
                      <Titre2 component='p'>Votre entreprise</Titre2>
                    </Col>
                  </Row>
                  <Field name='companyName'>
                    {({ input, meta }) => (
                      <Row className={classes.space}>
                        <Col xs={12} sm={6} smOffset={3}>
                          <input
                            className={classes.input}
                            type='text'
                            placeholder='Nom de votre entreprise'
                            {...input}
                          />
                          {meta.touched && (meta.error || meta.submitError) && (
                            <span className={classes.error}>
                              {meta.error}
                              {meta.submitError}
                            </span>
                          )}
                        </Col>
                      </Row>
                    )}
                  </Field>
                  <Field name='siren'>
                    {({ input, meta }) => (
                      <Row className={classes.space}>
                        <Col xs={12} sm={6} smOffset={3}>
                          <input
                            className={classes.input}
                            type='text'
                            placeholder='Siren'
                            {...input}
                          />
                          {meta.touched && (meta.error || meta.submitError) && (
                            <span className={classes.error}>
                              {meta.error}
                              {meta.submitError}
                            </span>
                          )}
                        </Col>
                      </Row>
                    )}
                  </Field>
                  <Field name='city'>
                    {({ input, meta }) => (
                      <Row className={classes.space}>
                        <Col xs={12} sm={6} smOffset={3}>
                          <input
                            className={classes.input}
                            type='text'
                            placeholder='Ville'
                            {...input}
                          />
                          {meta.touched && (meta.error || meta.submitError) && (
                            <span className={classes.error}>
                              {meta.error}
                              {meta.submitError}
                            </span>
                          )}
                        </Col>
                      </Row>
                    )}
                  </Field>
                  <Row className={classes.space}>
                    <Col xs={12} sm={6} smOffset={3}>
                      <Field name='postalCode'>
                        {({ input, meta }) => (
                          <div>
                            <input
                              className={classes.input}
                              type='text'
                              placeholder='Code Postal'
                              {...input}
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row className={classes.space}>
                    <Col xs={12} sm={6} smOffset={3}>
                      <Field name='address'>
                        {({ input, meta }) => (
                          <div>
                            <Async
                              instanceId='address-1'
                              name={input.name}
                              onChange={input.onChange}
                              value={input.value}
                              loadOptions={this.getOptions}
                              placeholder={
                                <span style={{ color: '#95989A' }}>
                                  Adresse
                                </span>
                              }
                              isClearable={false}
                              isMulti={false}
                              components={{
                                DropdownIndicator: () => <div />,
                                NoOptionsMessage: () => (
                                  <p className={classes.center}>
                                    Tapez une adresse pour la sélectionner
                                  </p>
                                ),
                                IndicatorSeparator: () => <div />
                              }}
                              className={classes.autocomplete}
                              classNamePrefix='address'
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row className={classes.space}>
                    <Col xs={12} sm={6} smOffset={3}>
                      <Field name='civility'>
                        {({ input, meta }) => (
                          <div>
                            <Select
                              instanceId='civility-1'
                              name='civility'
                              className={classes.civility}
                              classNamePrefix='civility'
                              placeholder={'Sélectionnez votre civilité'}
                              defaultValue={civiliteOptions[0]}
                              value={input.value}
                              onChange={input.onChange}
                              options={civiliteOptions}
                              isClearable={false}
                              isSearchable={false}
                              components={{ IndicatorSeparator: () => <div /> }}
                            />
                            {meta.submitError && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={3} smOffset={3} className={classes.space}>
                      <Field name='nameContact'>
                        {({ input, meta }) => (
                          <div>
                            <input
                              className={classes.input}
                              type='text'
                              placeholder='Nom du contact'
                              {...input}
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                    <Col xs={12} sm={3} className={classes.space}>
                      <Field name='firstNameContact'>
                        {({ input, meta }) => (
                          <div>
                            <input
                              className={classes.input}
                              type='text'
                              placeholder='Prénom du contact'
                              {...input}
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row className={classes.space}>
                    <Col xs={12} sm={6} smOffset={3}>
                      <Field name='tel'>
                        {({ input, meta }) => (
                          <div>
                            <input
                              className={classes.input}
                              type='text'
                              placeholder='Téléphone'
                              {...input}
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row className={classes.space}>
                    <Col xs={12} sm={6} smOffset={3}>
                      <Field name='presentation'>
                        {({ input, meta }) => (
                          <div>
                            <textarea
                              className={classes.textareaPresentation}
                              placeholder='Présentation de votre entreprise'
                              {...input}
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>

                  <Row className={classes.space}>
                    <Col xs={12} sm={6} smOffset={3}>
                      <Titre2 component='p'>Votre compte</Titre2>
                      <Field name='mail'>
                        {({ input, meta }) => (
                          <div>
                            <input
                              className={classes.input}
                              type='email'
                              placeholder='Email'
                              {...input}
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row className={classes.space}>
                    <Col xs={12} sm={6} smOffset={3}>
                      <Field name='password'>
                        {({ input, meta }) => (
                          <div>
                            <input
                              className={classes.input}
                              type='password'
                              placeholder='Mot de passe'
                              {...input}
                              onCopy={e => e.preventDefault()}
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row className={classes.space}>
                    <Col xs={12} sm={6} smOffset={3}>
                      <Field name='confirmPassword'>
                        {({ input, meta }) => (
                          <div>
                            <input
                              className={classes.input}
                              type='password'
                              placeholder='Retapez le mot de passe'
                              {...input}
                            />
                            {meta.touched && (meta.error || meta.submitError) && (
                              <span className={classes.error}>
                                {meta.error}
                                {meta.submitError}
                              </span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>

                  <div className={classes.spaceFlex}>
                    <input
                      name='rgpd'
                      type='checkbox'
                      checked={this.state.rgpdChecked}
                      onChange={this.toggleRgpdChecked}
                    />
                    <label className={classes.labelRight} htmlFor='rgpd'>
                      <span onClick={this.toggleRgpdChecked}>
                        Vous acceptez la politique d'utilisation des données
                        personnelles d'Acheter à la Source (consultable{' '}
                      </span>
                      <a href={lienRGPD} download target='_blank'>
                        ici
                      </a>
                      )
                    </label>
                  </div>

                  <Row className={classes.divButtonValidation}>
                    <Col xs={12}>
                      {submitting ? (
                        <ButtonStyle
                          className={classes.disabled}
                          disabled={submitting}
                        >
                          S'inscrire
                        </ButtonStyle>
                      ) : (
                        <ButtonStyle type='submit'>S'inscrire</ButtonStyle>
                      )}
                      {submitError && (
                        <div
                          className={`${classes.error} ${classes.globalError}`}
                        >
                          {submitError}
                        </div>
                      )}
                      {submitSucceeded && (
                        <div className={classes.succeeded}>
                          Inscription réussie !<br />
                          Votre profil est en attente d'être accepté. <br />
                          Vous pouvez continuer de le remplir dans la section
                          "Mon compte" une fois connecté.
                        </div>
                      )}
                    </Col>
                  </Row>
                </form>
              )}
            />
          </Grid>
        </div>
        <Footer />
      </>
    )
  }
}
const withStyles = responsiveComponent(injectSheet(styles)(FormInscription))

export default graphql(gql`
  mutation($user: NewUserInput) {
    createUser(newUser: $user) {
      error
    }
  }
`)(withStyles)
