// eslint-disable-next-line
import { preg_quote } from 'locutus/php/pcre'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Query } from 'react-apollo'
import routes from './routes'
import {
  boundsFranceStringified,
  categoriesLinkValues,
  categoriesOptions
} from '../config/dataConf'
import gql from 'graphql-tag'
import RenderSearchWithAddress from './RenderSearchWithAddress'
import ErrorPage from '../scenes/front/ErrorPage'

const RedirectSearchWithStatus = ({ noStrict, from, status }) => (
  <Route
    strict={!noStrict}
    exact
    path={from}
    render={({ match: { params }, staticContext }) => {
      // there is no `staticContext` on the client, so
      // we need to guard against that here
      if (staticContext) staticContext.status = status

      let category = null
      if (params.category) {
        switch (params.category.toLowerCase()) {
          case 'produits+fermiers':
            category = categoriesLinkValues[0].urlValue
            break
          case 'legumes':
            category = categoriesLinkValues[1].urlValue
            break
          case 'fruits':
            category = categoriesLinkValues[2].urlValue
            break
          case 'apiculture':
            category = categoriesLinkValues[3].urlValue
            break
          case 'oeufs':
            category = categoriesLinkValues[4].urlValue
            break
          case 'fromages':
            category = categoriesLinkValues[5].urlValue
            break
          case 'vin':
            category = categoriesLinkValues[6].urlValue
            break
          case 'horticulture':
            category = categoriesLinkValues[7].urlValue
            break
          case 'poissons':
            category = categoriesLinkValues[8].urlValue
            break
          case 'volailles-lapins':
            category = categoriesLinkValues[9].urlValue
            break
          case 'bovins':
            category = categoriesLinkValues[10].urlValue
            break
          case 'moutons-chevres':
            category = categoriesLinkValues[11].urlValue
            break
          case 'porc':
            category = categoriesLinkValues[12].urlValue
            break
          case 'gibier':
            category = categoriesLinkValues[13].urlValue
            break
          default:
            category = categoriesOptions[0].value
        }
      }

      if (params.adresse) {
        return (
          <RenderSearchWithAddress
            from={from}
            category={category}
            address={params.adresse}
            page={params.page}
          />
        )
      } else if (params.codeDep) {
        return (
          <Switch>
            <Redirect
              from={from}
              to={routes.mapDepartment
                .replace(':category', category || categoriesOptions[0].value)
                .replace(':inseeDepartment', params.codeDep)
                .replace(':page', params.page || '1')}
            />
          </Switch>
        )
      } else if (params.region) {
        return (
          <Query query={queryRegion} variables={{ regionName: params.region }}>
            {({ loading, data }) => {
              if (!loading && data.transformRegionToId) {
                return (
                  <Switch>
                    <Redirect
                      from={from}
                      to={routes.mapRegion
                        .replace(
                          ':category',
                          category || categoriesOptions[0].value
                        )
                        .replace(':inseeRegion', data.transformRegionToId)
                        .replace(':page', params.page || '1')}
                    />
                  </Switch>
                )
              } else {
                return (
                  <Switch>
                    <Route component={ErrorPage} />
                  </Switch>
                )
              }
            }}
          </Query>
        )
      } else if (params.idVille) {
        return (
          <Query query={queryVille} variables={{ oldCityId: params.idVille }}>
            {({ loading, data }) => {
              if (!loading && data && data.transformOldCityIdToNew) {
                return (
                  <Switch>
                    <Redirect
                      from={from}
                      to={routes.mapVille
                        .replace(':idVille', data.transformOldCityIdToNew)
                        .replace(':category', category)}
                    />
                  </Switch>
                )
              } else {
                return (
                  <Switch>
                    <Route component={ErrorPage} />
                  </Switch>
                )
              }
            }}
          </Query>
        )
      } else if (
        category &&
        category !== categoriesOptions[0].value &&
        !params.adresse &&
        !params.idVille &&
        !params.region
      ) {
        return (
          <Switch>
            <Redirect
              from={from}
              to={routes.mapProduit.replace(':category', category)}
            />
          </Switch>
        )
      } else if (!category) {
        return (
          <Switch>
            <Redirect
              from={from}
              to={routes.siteMap.replace(
                ':category',
                categoriesOptions[0].value
              )}
            />
          </Switch>
        )
      }

      return (
        <Switch>
          <Redirect
            from={from}
            to={`${routes.recherche}?category=${category}&bounds=${preg_quote(
              boundsFranceStringified
            )}&page=${params.page || '1'}`}
          />
        </Switch>
      )
    }}
  />
)

const queryRegion = gql`
  query TransformRegionToId($regionName: String!) {
    transformRegionToId(regionName: $regionName)
  }
`

const queryVille = gql`
  query TransformOldCityIdToNew($oldCityId: ID!) {
    transformOldCityIdToNew(oldCityId: $oldCityId)
  }
`

export default RedirectSearchWithStatus
