import React, { Fragment } from 'react'
import ButtonStyle from '../../../components/button/ButtonStyle'
import gql from 'graphql-tag'
import injectSheet from 'react-jss'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router'
import TopBarWithoutConnexion from '../../../components/top_bar/TopBarWithoutConnexion'
import { Field, Form } from 'react-final-form'
import { colors } from '../../../config/themes'
import { FORM_ERROR } from 'final-form'
import { withCookies } from 'react-cookie'
import { Helmet } from 'react-helmet'
import { NODE_ENV } from '../../../config/env'

const styles = theme => ({
  '@global body': {
    backgroundColor: theme.backgroundColor
  },
  topBar: {
    marginTop: 15
  },
  error: {
    color: 'red'
  },
  center: {
    textAlign: 'center'
  },
  space: {
    marginBottom: 10
  },
  divButtonConnexion: {
    marginBottom: 30,
    textAlign: 'center'
  },
  message: {
    marginTop: 15,
    fontSize: 20,
    color: theme.titre1
  },
  input: {
    width: '30%',
    outline: 'none',
    border: theme.borderInput,
    color: 'black',
    padding: 8
  },
  disabled: {
    backgroundColor: colors.gris,
    borderColor: colors.gris,
    '&:hover': {
      borderColor: colors.gris
    }
  }
})

class ConnexionRedirected extends React.Component {
  handleSubmit = values => {
    return this.props.client
      .query({
        query: gql`
      { 
        login(mail: "${values.email}", password: "${values.password}") {
          token
          error
        } 
          
      }`
      })
      .then(data => {
        if (!data.data.login.error) {
          const date = new Date()
          date.setDate(date.getDate() + 365)

          this.props.cookies.set('token', data.data.login.token, {
            path: '/',
            expires: date,
            secure: NODE_ENV !== 'development',
            sameSite: 'lax'
          })
          this.props.history.goBack()
          this.props.history.replace(this.props.history.location.pathname)
          return null
        }

        return { [FORM_ERROR]: data.data.login.error }
      })
  }

  render() {
    const { classes } = this.props

    return (
      <Fragment>
        <Helmet>
          <title>
            {typeof window !== 'undefined'
              ? window.location.href
              : 'Acheter à la source'}
          </title>
        </Helmet>
        <TopBarWithoutConnexion className={classes.topBar} />
        <div className={classes.center}>
          <p className={classes.message}>
            Connectez vous pour accéder à cette page
          </p>
          <Form
            onSubmit={this.handleSubmit}
            render={({ handleSubmit, submitting, submitError }) => (
              <form onSubmit={handleSubmit}>
                <Field name='email'>
                  {({ input, meta }) => (
                    <div className={classes.space}>
                      <input
                        className={classes.input}
                        type='email'
                        {...input}
                        required
                      />
                      {meta.touched && (meta.error || meta.submitError) && (
                        <span className={classes.error}>
                          {meta.error}
                          {meta.submitError}
                        </span>
                      )}
                    </div>
                  )}
                </Field>

                <Field name='password'>
                  {({ input, meta }) => (
                    <div className={classes.space}>
                      <input
                        className={classes.input}
                        type='password'
                        {...input}
                        required
                      />
                      {meta.touched && (meta.error || meta.submitError) && (
                        <span className={classes.error}>
                          {meta.error}
                          {meta.submitError}
                        </span>
                      )}
                    </div>
                  )}
                </Field>

                <div className={classes.divButtonConnexion}>
                  {submitting ? (
                    <ButtonStyle
                      className={classes.disabled}
                      disabled={submitting}
                      type='submit'
                    >
                      Connexion
                    </ButtonStyle>
                  ) : (
                    <ButtonStyle type='submit'>Connexion</ButtonStyle>
                  )}
                </div>

                {submitError && <p className={classes.error}>{submitError}</p>}
              </form>
            )}
          />
        </div>
      </Fragment>
    )
  }
}

const styleSheet = injectSheet(styles)(ConnexionRedirected)
const withApo = withApollo(styleSheet)
const withRout = withRouter(withApo)

export default withCookies(withRout)
