const styles = theme => ({
  grid: {
    backgroundColor: theme.backgroundColor,
    position: 'relative'
  },
  gridArticle: {
    backgroundColor: theme.accueil.backgroundColorZoom,
    paddingBottom: '30px'
  },
  titreAccueil: {
    textAlign: 'center',
    '& h1': {
      fontSize: theme.tailleGrandTexte
    }
  },
  liens: {
    marginTop: '10px',
    padding: '0px 20px'
  },
  liensAutreFormat: {
    margin: '0px auto',
    width: '665px',
    marginTop: '10px',
    '& div': {
      padding: 0
    }
  },
  reseaux_sociaux: {
    width: 30
  },
  secuAlimentation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  infoIcon: {
    top: 20,
    left: 20,
    position: 'absolute'
  },
  floatingInformation: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    marginBottom: 50,
    '& iframe': {
      marginBottom: 30
    },
    '& a': {
      padding: 7,
      marginBottom: 20,
      backgroundColor: theme.button.backgroundColor,
      color: 'white',
      textDecoration: 'none'
    },
    '& p': {
      fontWeight: theme.semiBold,
      color: theme.button.backgroundColor,
      fontSize: theme.tailleGrandTexte,
      width: '67%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 30,
      marginTop: 20
    }
  },
  colProd: {
    textAlign: 'right',
    zIndex: 2
  },
  liensSociaux: {
    zIndex: 2
  },
  lienProd: {
    fontWeight: theme.bold,
    fontSize: theme.tailleMoyenTexte,
    '& a': {
      color: theme.lien
    }
  },
  lienProdAutreFormat: {
    fontWeight: theme.bold,
    fontSize: theme.tailleMoyenTexte,
    display: 'inline-block',
    '& a': {
      color: theme.lien
    }
  },
  enSavoirPlus: {
    textAlign: 'center',
    marginBottom: '70px',
    '& p': {
      fontWeight: theme.semiBold,
      color: theme.accueil.marron,
      fontSize: theme.tailleGrandTexte,
      width: '67%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '40px'
    }
  },
  divFondAccueil: {
    position: 'relative',
    top: -90,
    left: 0,
    width: '100%',
    zIndex: 1
  },
  rechercheTitle: {
    fontWeight: theme.regular,
    fontFamily: 'Barrio',
    fontSize: '40px',
    textAlign: 'center',
    color: theme.accueil.titreCategorie
  },
  zoomMobile: {
    fontWeight: theme.regular,
    fontFamily: 'Barrio',
    color: 'white',
    fontSize: '30px',
    textAlign: 'center',
    margin: '30px 0'
  },
  zoomAutreFormat: {
    fontWeight: theme.regular,
    fontFamily: 'Barrio',
    fontSize: '40px',
    textAlign: 'center',
    color: 'white',
    margin: '30px 0'
  },
  categorie: {
    backgroundColor: theme.accueil.backgroundCategorie
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  itemMobile: {
    margin: 15,
    width: '150px',
    color: theme.lien,
    textAlign: 'center',
    fontWeight: theme.bold,
    fontSize: theme.taillePetitTexte,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  itemDesktop: {
    margin: '15px 3%',
    width: '8%',
    color: theme.lien,
    textAlign: 'center',
    fontWeight: theme.bold,
    fontSize: theme.taillePetitTexte,
    '&:hover': {
      textDecoration: 'none',
      color: theme.accueil.lienCategory,
      '& .category-circle': {
        fill: theme.accueil.lienCategory
      }
    }
  },
  rowZoom: {
    padding: '0 10%'
  },
  colZoom: {
    padding: '0 10px'
  },
  pageWrapper: {
    backgroundColor: theme.backgroundColor
  },
  colSearchAccueilAF: {
    zIndex: 3
  },
  ads: {
    textAlign: 'center'
  },
  adsDesktop: {
    textAlign: 'center',
    backgroundColor: theme.backgroundColor
  }
})

export default styles
