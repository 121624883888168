import React, { Component } from 'react'
import Accueil from '../scenes/front/Accueil'
import CGU from '../scenes/front/CGU'
import MentionsLegales from '../scenes/front/MentionsLegales'
import About from '../scenes/front/About'
import FAQ from '../scenes/front/FAQ'
import Contact from '../scenes/front/Contact'
import routes from './routes'
import injectSheet from 'react-jss'
import ScrollToTop from '../ScrollToTop'
import Inscription from '../scenes/front/Inscription'
import ForgottenPassword from '../scenes/front/ForgottenPassword'
import { Helmet } from 'react-helmet'
import ErrorPage from '../scenes/front/ErrorPage/ErrorPage'
import ConnexionRedirected from '../scenes/front/ConnexionRedirected/ConnexionRedirected'
import RedirectProducerWithStatus from './RedirectProducerWithStatus'
import RedirectSearchWithStatus from './RedirectSearchWithStatus'
import RedirectWithStatus from './RedirectWithStatus'
import { Route, Switch } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import loadable from '@loadable/component'
import Annuaire from '../scenes/front/Annuaire/Annuaire'

// Lazy loading de différentes parties de l'application avec parfois prefetch
// pour charger la partie quand le navigateur est idle
const Recherche = loadable(() =>
  import(/* webpackPrefetch: true */ '../scenes/front/Recherche')
)
const FicheProducteur = loadable(() =>
  import(/* webpackPrefetch: true */ '../scenes/front/FicheProducteur')
)
const DisableProducteur = loadable(() =>
  import(/* webpackPrefetch: true */ '../scenes/front/FicheProducteur/FicheProducteur.disable')
)
const FicheProduit = loadable(() =>
  import(/* webpackPrefetch: true */ '../scenes/front/FicheProduit')
)
const PageProduit = loadable(() =>
  import(/* webpackPrefetch: true */ '../scenes/front/Plan/scenes/PageProduit')
)
const Plan = loadable(() =>
  import(/* webpackPrefetch: true */ '../scenes/front/Plan')
)
const Account = loadable(() => import('../scenes/back/Account'))

const styles = theme => ({
  '@global body': {
    margin: 0,
    fontFamily: theme.fontGlobal
  },
  cookieContainer: {
    backgroundColor: theme.cookies.backgroundColor,
    alignItems: 'baseline',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    left: '0',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    padding: 10
  },
  spaceRight: {
    marginRight: 15
  },
  cookieContent: {
    fontSize: theme.cookies.fontSize,
    color: theme.cookies.textColor
  },
  cookieButton: {
    display: 'inline-block',
    backgroundColor: theme.cookies.buttonColor,
    border: theme.cookies.buttonColor,
    '&:hover': {
      backgroundColor: theme.cookies.buttonHoverColor,
      border: theme.cookies.buttonHoverColor
    }
  }
})

class SwitchRoutes extends Component {
  render() {
    return (
      <ScrollToTop>
        <Helmet>
          <script
            async
            src='https://www.googletagmanager.com/gtag/js?id=G-TPT05EVJLB'
          ></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-TPT05EVJLB');`}
          </script>

          <title>Vente en direct producteur : Acheter à la source</title>
        </Helmet>

        <Switch>
          <Route path={routes.recherche} component={Recherche} />
          <Route exact path={routes.accueil} render={() => <Accueil />} />
          <Route path={routes.account} render={() => <Account />} />
          <Route
            strict
            exact
            path={routes.ficheProducteur}
            component={FicheProducteur}
          />
          <Route
            strict
            exact
            path={routes.ficheProducteur + '/disable'}
            component={DisableProducteur}
          />
          <Route
            strict
            exact
            path={routes.ficheProduit}
            component={FicheProduit}
          />
          <Route exact path={routes.cgu} component={CGU} />
          <Route
            exact
            path={routes.mentionsLegales}
            component={MentionsLegales}
          />
          <Route exact path={routes.about} component={About} />
          <Route exact path={routes.faq} component={FAQ} />
          <Route exact path={routes.contact} component={Contact} />
          <Route path={routes.inscription} render={() => <Inscription />} />
          <Route
            path={routes.forgottenPassword}
            render={() => <ForgottenPassword />}
          />
          <Route path={`${routes.annuaire}*`} component={Annuaire} />
          <Route path={routes.siteMap} component={Plan} />
          <Route exact path={routes.mapProduit} component={PageProduit} />
          <Route
            exact
            path={routes.connexion}
            render={() => <ConnexionRedirected />}
          />

          {/* Anciennes routes */}
          {/* L'ORDRE EST TRÈS IMPORTANT */}
          <RedirectSearchWithStatus
            status={301}
            from='/vente-directe-producteur/:region/:dep-:codeDep(\d+)-:page(\d+)'
          />
          <RedirectSearchWithStatus
            status={301}
            from='/vente-directe-producteur/:region/:dep-:codeDep(\d+)/:category(produits\+fermiers|legumes|fruits|apiculture|oeufs|fromages|vin|horticulture|poissons|volailles-lapins|bovins|moutons-chevres|gibier|porc)-:page(\d+)'
          />
          <RedirectProducerWithStatus
            status={301}
            from='/vente-directe-producteur/:region/:dep-:codeDep(\d+)/:nomProducteur'
          />
          <RedirectSearchWithStatus
            status={301}
            from='/vente-directe-producteur/region-:region-:page(\d+)'
          />
          <RedirectSearchWithStatus
            status={301}
            from='/vente-directe-producteur/region-:region/:category(produits\+fermiers|legumes|fruits|apiculture|oeufs|fromages|vin|horticulture|poissons|volailles-lapins|bovins|moutons-chevres|gibier|porc)-:page(\d+)'
          />
          <RedirectSearchWithStatus
            status={301}
            from='/vente-directe-producteur/liste-:category(produits\+fermiers|legumes|fruits|apiculture|oeufs|fromages|vin|horticulture|poissons|volailles-lapins|bovins|moutons-chevres|gibier|porc)/:ville-:idVille(\d+)'
          />
          <RedirectSearchWithStatus
            status={301}
            from='/vente-directe-producteur/:category(produits\+fermiers|legumes|fruits|apiculture|oeufs|fromages|vin|horticulture|poissons|volailles-lapins|bovins|moutons-chevres|gibier|porc)'
          />
          {/* <RedirectProducerWithStatus status={301} from='/vente-directe-producteur/demandeinfo-:nomProducteur' /> */}
          <RedirectSearchWithStatus
            status={301}
            from='/vente-directe-producteur'
          />
          <RedirectSearchWithStatus
            status={301}
            from='/trouver/:category/proche-:adresse/:page(\d+)'
          />
          <RedirectSearchWithStatus
            status={301}
            from='/trouver/:category/proche-:adresse'
          />
          <RedirectProducerWithStatus
            status={301}
            from='/producteur/fiche-:nomProducteur'
          />
          <RedirectProducerWithStatus
            status={301}
            from='/producteur/demandeinfo-:nomProducteur'
          />
          <RedirectProducerWithStatus
            status={301}
            from='/producteur/:nomProducteur'
          />
          <RedirectWithStatus
            status={301}
            from='/produit/:nameProduct-:idProduct(\d+)'
            to={routes.ficheProduit}
          />

          <Route component={ErrorPage} />
        </Switch>
      </ScrollToTop>
    )
  }
}

export default injectSheet(styles)(withCookies(SwitchRoutes))
